import ArtworkList from './ArtworkList'
import ArtworkListTablet from './ArtworkListTablet'
import AuctionInfo from './AuctionInfo'
import AuctionInfoModal from './AuctionInfoModal'
import AuctionNavBar from './AuctionNavBar'
import BidderButton from './BidderButton'
import CurrentLotBar from './CurrentLotBar'
import EigenModal from './EigenModal'
import ErrorModal from './ErrorModal'
import LiveLot from './LiveLot'
import LotEventList from 'components/LotEventList'
import LotInfo from './LotInfo'
import LotInfoTablet from './LotInfoTablet'
import OnHoldNotification from 'components/OnHoldNotification'
import PropTypes from 'prop-types'
import React from 'react'
import SelectedLot from './SelectedLot'
import block from 'bem-cn'
import { Theme } from '@artsy/palette'
import './BidderLayout.styl'

export default function BidderLayout({ LotInfoModal }, context) {
  const {
    currentSaleArtwork,
    deviceMode,
    isDesktop,
    isTabletOrMobile,
    isToggledAuctionInfoModal,
    isToggledLotsModal,
    isToggledSaleOnHold,
    selectedSaleArtwork,
  } = context

  const noModalsPresent = !isToggledLotsModal && !isToggledAuctionInfoModal

  let leftElement
  if (isTabletOrMobile) {
    leftElement = <AuctionNavBar />
  } else {
    leftElement = (
      <aside>
        <CurrentLotBar />
      </aside>
    )
  }

  let mainElement
  if (isTabletOrMobile) {
    if (isToggledLotsModal) {
      mainElement = <ArtworkListTablet />
    } else if (isToggledAuctionInfoModal) {
      mainElement = <AuctionInfoModal />
    } else if (LotInfoModal) {
      mainElement = (
        <div>
          {LotInfoModal}
          <SelectedLot />
        </div>
      )
    } else {
      mainElement = (
        <div>
          <SelectedLot />
          <LotInfoTablet />
        </div>
      )
    }
  } else if (LotInfoModal) {
    mainElement = (
      <div>
        {LotInfoModal}
        <SelectedLot />
      </div>
    )
  } else {
    mainElement = <SelectedLot />
  }

  const b = block('bidder-layout')
  const innerLayout = b('inner')

  return (
    <Theme>
      <div
        className={b({
          'device-mode': deviceMode,
          'lots-modal': isToggledLotsModal || isToggledAuctionInfoModal,
        }).toString()}
      >
        {leftElement}

        {isTabletOrMobile &&
          selectedSaleArtwork !== currentSaleArtwork &&
          noModalsPresent && <LiveLot />}

        <div className={innerLayout({ 'device-mode': deviceMode }).toString()}>
          {isDesktop && (
            <aside className={innerLayout('left-column').toString()}>
              <AuctionInfo />
              <ArtworkList />
            </aside>
          )}

          <main
            className={innerLayout('main-column', {
              'device-mode': deviceMode,
            }).toString()}
          >
            {isToggledSaleOnHold && <OnHoldNotification />}

            {mainElement}
          </main>

          {isDesktop && (
            <aside className={innerLayout('right-column').toString()}>
              <LotInfo />
              <BidderButton />
              <LotEventList />
            </aside>
          )}
        </div>

        <ErrorModal />
        <EigenModal />
      </div>
    </Theme>
  )
}

BidderLayout.contextTypes = {
  currentSaleArtwork: PropTypes.object.isRequired,
  deviceMode: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isTabletOrMobile: PropTypes.bool.isRequired,
  isToggledAuctionInfoModal: PropTypes.bool.isRequired,
  isToggledLotsModal: PropTypes.bool.isRequired,
  isToggledSaleOnHold: PropTypes.bool.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
}
