import RavenClient from 'raven-js'
import initializeAnalytics from 'middleware/analyticsMiddleware'
import invariant from 'invariant'
import createRavenMiddleware from 'raven-for-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { get } from 'lodash'

import { isClient, isDevelopment } from 'utils/environment'
import {
  breadcrumbDataFromAction,
  createLogFromState,
} from 'utils/ravenHelpers'

let store

/**
 * Export store for use in route deep-linking lifecycle hooks
 * @return {Object}
 */
export function getStore() {
  invariant(
    store,
    '(utils/configureStore.js) ' + 'Error return store: `store` is undefined.'
  )

  return store
}

export default function configureStore(rootReducer, initialState = {}) {
  const middleware = []

  let composeEnhancers = compose

  if (isClient) {
    middleware.push(initializeAnalytics())

    middleware.push(
      createRavenMiddleware(RavenClient, {
        breadcrumbDataFromAction: breadcrumbDataFromAction,
        stateTransformer: createLogFromState,
      })
    )

    /**
     * Connect to Chrome DevTools extension if available.
     * See: http://zalmoxisus.github.io/redux-devtools-extension/
     */
    composeEnhancers = get(
      window,
      '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__',
      compose
    )
  }

  if (isDevelopment) {
    middleware.push(
      createLogger({
        // Must come last in middleware chain
        collapsed: true,
      })
    )
  }
  store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  )

  const _module = module as any

  if (_module.hot) {
    _module.hot.accept('reducers', () => {
      const nextRootReducer = require('reducers/index')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
