import 'scroll-behaviour/polyfill'
import RavenClient from 'raven-js'
import React from 'react'
import ReactDOM from 'react-dom'
import ErrorBoundary from './components/ErrorBoundary'
import configureStore from 'utils/configureStore'
import rootReducer from 'reducers'
import { makeRoutes } from 'apps/routes'
import { Provider } from 'react-redux'
import { Router, browserHistory as history } from 'react-router'
import { isDevelopment } from 'utils/environment'
import { data as sd } from 'sharify'

if (sd.SENTRY_PUBLIC_DSN) {
  RavenClient.config(sd.SENTRY_PUBLIC_DSN).install()
}

const store = configureStore(rootReducer, window.__BOOTSTRAP__)

const App = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Router history={history} routes={makeRoutes(store)} />
      </ErrorBoundary>
    </Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

if (window.analytics) {
  window.analytics.page()
}

if (isDevelopment) {
  if (module.hot) {
    module.hot.accept()
  }
}
