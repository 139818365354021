import createLogger from 'utils/logger'
import { isClient } from 'utils/environment'
import { get } from 'lodash'

const logger = createLogger('utils/tracking')

export function track(event, data) {
  if (isClient) {
    const windowTrack = get(window, 'analytics.track', x => x)

    // Defined via webpack
    // @ts-ignore
    if (__VERBOSE__) {
      logger.log('Tracking:', event, data)
    }
    windowTrack(event, data)
  }
}
