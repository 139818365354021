import React, { MouseEventHandler } from 'react'
import { Sans } from '@artsy/palette'
import { Button } from 'apps/operator/elements/Buttons'

interface Props {
  toggleChangeHoldMessage: MouseEventHandler<HTMLButtonElement>
  saleOnHoldMessage: string
  sale: {
    internalID: string // TODO: Re-use metaphysics types if possible
  }
}

export const SaleOnHold: React.FC<Props> = ({
  toggleChangeHoldMessage,
  saleOnHoldMessage,
}) => (
  <>
    <Sans size="3" color="black60">
      Message to bidders:
    </Sans>
    <Sans size="5t" color="black100">
      {saleOnHoldMessage}
    </Sans>
    <Button
      bg="black10"
      borderColor="black10"
      width="100%"
      mt={2}
      onClick={toggleChangeHoldMessage}
    >
      <Sans size="3t" weight="medium" color="black100">
        Change hold message
      </Sans>
    </Button>
  </>
)
