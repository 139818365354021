import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import { data as sd } from 'sharify'
import './EigenModal.styl'

export default function EigenModal(_, context) {
  const { eigenModalOpen, onCloseEigenModal } = context

  if (!eigenModalOpen) {
    return null
  }

  const b = block('eigen-modal')

  return (
    <div>
      <div className={b()}>
        <h1 className={b('header')}>
          For the best bidding experience, please update to the latest version
          of Artsy.
        </h1>
        <div
          className={b('img')}
          style={{
            backgroundImage:
              'url("http://files.artsy.net/images/prediction-eigen-cta.jpg")',
          }}
        />
        <a
          className={b('btn', 'avant-garde-button-black')}
          href={sd.EIGEN_LINK}
        >
          Update now
        </a>
        <a className={b('close')} onClick={() => onCloseEigenModal()}>
          No thanks
        </a>
      </div>
    </div>
  )
}

EigenModal.contextTypes = {
  eigenModalOpen: PropTypes.bool.isRequired,
  onCloseEigenModal: PropTypes.func.isRequired,
}
