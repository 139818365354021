import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import './CurrentLotBar.styl'

export default function CurrentLotBar(_, context) {
  const { currentSaleArtwork, sale, deviceMode } = context

  const currentSaleArtworkIndex =
    sale.saleArtworks.findIndex(lot => lot === currentSaleArtwork) + 1

  const currentSaleArtworkPercentage =
    (currentSaleArtworkIndex / sale.saleArtworks.length) * 100

  const b = block('current-lot-bar')

  return (
    <div className={b()}>
      <div className={b({ 'device-mode': deviceMode })}>
        {/* Invisible spacer to keep indicator on-page for early lots. */}
        <div className={b('spacer')}>
          {currentSaleArtworkIndex}/{sale.saleArtworks.length}
        </div>
        <div
          className={b('inner')}
          style={{ width: `${currentSaleArtworkPercentage}%` }}
        >
          {deviceMode === 'tablet' || deviceMode === 'mobile' ? null : (
            <div className={b('caption')}>
              <span className={b('caption-index')}>
                {currentSaleArtworkIndex}
              </span>
              <span className={b('caption-length')}>
                /{sale.saleArtworks.length}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

CurrentLotBar.contextTypes = {
  currentSaleArtwork: PropTypes.object.isRequired,
  sale: PropTypes.object.isRequired,
  deviceMode: PropTypes.string.isRequired,
}
