import React from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Autoscroll } from 'components/Autoscroll'
import { connect } from 'react-redux'
import { currentLot } from 'selectors/lotSelectors'
import { formatMoneyWithoutCents } from 'utils/formatHelper'
import {
  isStepBiddable,
  isExternalOperator,
} from 'selectors/operatorUISelectors'

interface Props {
  currentLot: AdminLot
  isStepBiddable: (stepIndex: number, isExternalOperator: boolean) => boolean
  isExternalOperator: boolean
}

export const _Footing: React.FunctionComponent<Props> = ({
  currentLot,
  isStepBiddable,
  isExternalOperator,
}) => {
  const {
    canArtsyDoubleBid,
    footingData,
    maxBidAmountCents: maxBidAmount,
    sellingPriceCents: minBidAmount,
  } = currentLot.derivedLotState
  const {
    amountsCents,
    askingStep,
    onlineCompetitiveStep,
    onlineMaxStep,
  } = footingData

  // OnlineCompetitiveStep may be null (no MIN label would display)
  // But even if it isn't we only want to show it if there are > 2
  // online bidders competing above the asking price.
  // FIXME: Using the test below the MIN label  will disappear when it equals
  // the asking price. If there are 2 online bidders competitive at this
  // price we would NOT want it to disappear until the asking price has
  // passed the underbidder.
  const shownCompetitiveStep =
    onlineCompetitiveStep !== null && onlineCompetitiveStep > askingStep
      ? onlineCompetitiveStep
      : null

  // Don't show MAX when
  // 1. it has the same value as min
  // 2. third party operator is logged in and max is not enabled
  const renderMax =
    (!isExternalOperator || canArtsyDoubleBid) && minBidAmount !== maxBidAmount

  return (
    <Container>
      <Content>
        {/* This exists outside of the list for animation purposes. */}
        <Arrows askingStep={askingStep}>
          <Arrow column="left" direction="right" />
          <Arrow column="left" direction="left" />
          <Arrow column="right" direction="right" />
          <Arrow column="right" direction="left" />
        </Arrows>
        <MinMaxLabel index={shownCompetitiveStep} color="yellow80">
          Min
        </MinMaxLabel>
        {renderMax && (
          <MinMaxLabel index={onlineMaxStep} color="red100">
            Max
          </MinMaxLabel>
        )}
        <Autoscroll
          focusIndex={askingStep}
          nodeSelector={(rootNode, index) => rootNode.children[index]}
          scrollIntoViewOptions={{
            behavior: 'smooth',
            block: 'start',
          }}
          updateScroll={Autoscroll.ALWAYS}
        >
          {amountsCents.map((_, index) => (
            <ScrollAnchor key={index} index={index} />
          ))}
        </Autoscroll>
        <Increments>
          {amountsCents.map((amountCents, index) => {
            return (
              <Increment
                key={index}
                index={index}
                isBiddable={isStepBiddable(index, isExternalOperator)}
              >
                {formatMoneyWithoutCents(amountCents / 100)}
              </Increment>
            )
          })}
        </Increments>
      </Content>
      {!isExternalOperator && (
        <ColumnHighlight
          hasMax={onlineMaxStep !== null}
          column={onlineMaxStep % 2 === 0 ? 'left' : 'right'}
        />
      )}
    </Container>
  )
}

const mapStateToProps: (s: any) => Props = state => ({
  currentLot: currentLot(state),
  isStepBiddable: isStepBiddable(state),
  isExternalOperator: isExternalOperator(state),
})

export const Footing = connect(mapStateToProps)(_Footing)

interface FootingPositioning {
  arrowTop: (index: Number) => FlattenSimpleInterpolation
  footingPaddingTop: FlattenSimpleInterpolation
  minMaxLabelTop: (index: Number) => FlattenSimpleInterpolation
  scrollAnchorTop: (index: Number) => FlattenSimpleInterpolation
}
// Calculate these related positioning metrics in one spot, so that if we
// change them, the relationships will be easier to keep consistent. These all
// use `calc` to produce the final amounts in CSS, since our theme is specified
// with units.
const footingPositioning: (theme: any) => FootingPositioning = theme => {
  const footingTopMargin = `${theme.margins.large} * 3`
  const footingEntryHeight = index => `${theme.margins.large} * 1.5 * ${index}`
  return {
    arrowTop: index => css`
      top: calc(12px + ${footingTopMargin} + ${footingEntryHeight(index)});
    `,
    footingPaddingTop: css`
      padding-top: calc(2px + ${footingTopMargin});
    `,
    minMaxLabelTop: index => css`
      top: calc(22px + ${footingTopMargin} + ${footingEntryHeight(index)});
    `,
    scrollAnchorTop: index => css`
      top: calc(
        ${footingTopMargin} + ${footingEntryHeight(index)} - ${footingTopMargin}
      );
    `,
  }
}

const Arrow = styled.div<{ column: string; direction: string }>`
  border-width: ${p =>
    p.direction === 'right' ? '8px 0 8px 10px' : '8px 10px 8px 0'};
  border-color: ${p =>
    p.direction === 'right'
      ? `transparent transparent transparent ${p.theme.colors.green100}`
      : `transparent ${p.theme.colors.green100} transparent transparent`};
  padding-left: ${p =>
    p.column === 'left' && p.direction === 'left' ? '79px' : '0px'};
  padding-right: ${p =>
    p.column === 'right' && p.direction === 'right' ? '77px' : '0px'};
  border-style: solid;
  width: 0;
  height: 0;
`

const Arrows = styled.div<{ askingStep: number }>`
  ${p => p.theme.utils.stretch};
  height: ${p => p.theme.margins.large};
  width: 100%;
  position: absolute;
  transition: top ${p => p.theme.delays.footingIndicator};
  ${p => footingPositioning(p.theme).arrowTop(p.askingStep)}
`

// This is a little subtle, but it accounts for both the highlight if there is
// an active max and the black divider if there isn't.
const ColumnHighlight = styled.div<{ hasMax: boolean; column: string }>`
  display: block;
  position: absolute;
  border-width: ${p => (p.hasMax ? '2px' : '0 2px 0 0')};
  border-style: solid;
  border-color: ${p => p.theme.colors[p.hasMax ? 'green100' : 'black100']};
  width: 101px;
  height: 100%;
  top: 0;
  left: ${p => (!p.hasMax || p.column === 'left' ? '0' : '50%')};
  pointer-events: none;
`

const Container = styled.div`
  width: 200px;
  position: relative;
  height: 100%;
`

const Content = styled.div`
  position: relative; /* For absolute positioning the arrows */
  overflow-y: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Increment = styled.li<{ index: number; isBiddable: boolean }>`
  padding: ${p => p.theme.margins.medium} 0;
  font-size: ${p => p.theme.fontSizes.small};
  height: ${p => p.theme.margins.large};
  color: ${p =>
    p.isBiddable ? p.theme.colors.white100 : p.theme.colors.black60};
  transition: color ${p => p.theme.delays.footingTextFade} ease;
  text-align: center;
  width: 50%;
  margin-left: ${p => (p.index % 2 === 0 ? '0' : '50%')};
  position: relative;
`

const Increments = styled.ul`
  padding-bottom: ${p => p.theme.margins.large};
  ${p => footingPositioning(p.theme).footingPaddingTop}
`

const MinMaxLabel = styled.div<{ index: number }>`
  font-size: ${p => p.theme.fontSizes.xsmall};
  line-height: 1;
  padding: 5px 0 3px 0;
  width: 40px;
  text-align: center;
  display: ${p => (p.index === null ? 'none' : 'block')};
  position: absolute;
  color: ${p => p.theme.colors.black100};
  left: 50%;
  transform: translate(-50%, -50%);
  ${p => p.theme.utils.zBelowModalBackground};
  border: 4px solid ${p => p.theme.colors.black80};
  background: ${p => p.theme.colors[p.color]};
  transition: top ${p => p.theme.delays.footingIndicator};
  ${p => footingPositioning(p.theme).minMaxLabelTop(p.index)}
`

const ScrollAnchor = styled.div<{ index: number }>`
  position: absolute;
  ${p => footingPositioning(p.theme).scrollAnchorTop(p.index)}
`
