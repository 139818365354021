import PropTypes from 'prop-types'
import { selectedLot } from './lotSelectors'
import { find, last } from 'lodash'
import moment from 'moment'
import { isPendingBid } from './uiSelectors'

/**
 * [myLatestSelectedLotEvent description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
const myLatestSelectedLotEvent = state => {
  const reversedEvents = selectedLot(state)
    .eventHistory.slice()
    .reverse()

  const event = find(
    reversedEvents,
    evt => evt.bidder && evt.bidder.bidderId === state.bidderId
  )

  return event
}

export const bidderId = state => state.bidderId
bidderId.propType = PropTypes.string.isRequired

export const userId = state => state.userId
userId.propType = PropTypes.string.isRequired

export const paddleNumber = state => state.paddleNumber
paddleNumber.propType = PropTypes.string.isRequired

export const qualifiedForBidding = state => state.qualifiedForBidding
qualifiedForBidding.propType = PropTypes.bool.isRequired

/**
 * [myBidButtonState description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const myBidButtonState = state => {
  switch (state.role) {
    case 'observer': {
      const endTime = state.sale.registrationEndsAt
      const isAfterRegistration = moment().isAfter(endTime)

      const buttonState = isAfterRegistration
        ? 'registration-closed'
        : 'register-to-bid'

      return buttonState
    }
    case 'bidder': {
      if (state.qualifiedForBidding) {
        const biddingStatus = selectedLot(state).derivedLotState.biddingStatus

        const buttonState = biddingStatus === 'Open' ? 'max-bid' : 'bid'

        return buttonState
      } else {
        return 'registration-pending'
      }
    }
    default:
      return 'register-to-bid'
  }
}

myBidButtonState.propType = PropTypes.string.isRequired

/**
 * [myMaxBidButtonState description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const myMaxBidButtonState = state => {
  const lot = selectedLot(state)
  const hasNoBids = !lot || !lot.eventHistory

  if (hasNoBids) {
    return 'no-bids'
  }

  const { winningBidEventId } = lot.derivedLotState

  const myLatestEvent = myLatestSelectedLotEvent(state)

  if (!myLatestEvent) {
    return 'no-bids'
  }

  // Winning & Losing
  const isWinning = winningBidEventId === myLatestEvent.eventId

  if (isWinning && state.winningFlashing && !state.justWinning) {
    return 'winning-flashing'
  }
  if (isWinning) {
    return 'winning'
  }
  if (!isWinning && state.outbidFlashing && !state.justOutbid) {
    return 'outbid'
  }
  if (!isWinning) {
    return 'losing'
  }
}

myMaxBidButtonState.propType = PropTypes.string.isRequired

/**
 * [mySelectedLotState description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const mySelectedLotState = state => {
  if (!state.bidderId) {
    return 'observer'
  }

  const lot = selectedLot(state)

  const {
    floorIsOpen,
    winningBidEventId,
    floorWinningBidder,
  } = lot.derivedLotState

  // Sold
  const lastEvent = last(lot.eventHistory)
  const biddingClosed = lastEvent && lastEvent.type === 'BiddingClosed'

  if (biddingClosed) {
    const {
      derivedLotState: { soldStatus },
    } = lot

    const lotSold = soldStatus && soldStatus === 'Sold'

    if (lotSold) {
      return 'sold'
    } else {
      return 'passed'
    }
  }

  // Closed
  if (!lot.eventHistory || !floorIsOpen) {
    return 'closed'
  }

  // Opened
  const myLatestEvent = myLatestSelectedLotEvent(state)

  if (!myLatestEvent) {
    return 'opened'
  }

  if (isPendingBid(state)) {
    return 'notAcceptedYet'
  }

  // Winning & Losing
  const isHighestArtsyBidder = winningBidEventId === myLatestEvent.eventId

  if (isHighestArtsyBidder) {
    if (floorWinningBidder && state.bidderId === floorWinningBidder.bidderId) {
      return 'winning'
    }
    return 'notAcceptedYet'
  }
  if (!isHighestArtsyBidder && state.outbidFlashing && !state.justOutbid) {
    return 'outbid'
  }
  if (!isHighestArtsyBidder) {
    return 'losing'
  }
}

mySelectedLotState.propType = PropTypes.string.isRequired
