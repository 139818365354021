import Icon from 'assets/icons'
import LotDetails from 'components/LotDetails'
import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import { data as sd } from 'sharify'
import { Image } from '@artsy/palette'

import { trackClickLink } from 'utils/analytics'
import './LotInfoModal.styl'

export default function LotInfoModal(_, context) {
  const { deviceMode, hideLotInfo, selectedSaleArtwork } = context
  const b = block('lot-info-modal')
  const artistLink = selectedSaleArtwork.artwork.artist
    ? `${sd.FORCE_BASE_URL}/artist/${selectedSaleArtwork.artwork.artist.slug}`
    : null

  return (
    <div className={b({ 'device-mode': deviceMode })}>
      <Icon className={b('close')} name="close" onClick={() => hideLotInfo()} />
      <Image
        mr="3"
        mb="3"
        height={130}
        src={selectedSaleArtwork.artwork.image?.url}
        preventRightClick
      />
      <LotDetails>
        <div className={b('medium')}>{selectedSaleArtwork.artwork.medium}</div>
        <div className={b('category')}>
          {selectedSaleArtwork.artwork.category}
        </div>
        <div className={b('dimensions')}>
          {selectedSaleArtwork.artwork.dimensions.cm}
        </div>
      </LotDetails>
      {selectedSaleArtwork.artwork.description ? (
        <div className={b('description')}>
          <div className={b('description-label')}>Description</div>
          {selectedSaleArtwork.artwork.description}
        </div>
      ) : null}
      {artistLink ? (
        <div className={b('artsy-artist')}>
          <a
            className={b('artsy-artist-link')}
            href={artistLink}
            target="_blank"
            onClick={() =>
              trackClickLink({
                label: 'view artist on artsy',
                contextModule: 'lots',
                destinationPath: artistLink,
              })
            }
          >{`View ${selectedSaleArtwork.artwork.artist.name ||
            ''} on Artsy`}</a>
          <Icon name="chevronRight" className={b('chevron-right')} />
        </div>
      ) : null}
    </div>
  )
}

LotInfoModal.contextTypes = {
  deviceMode: PropTypes.string.isRequired,
  hideLotInfo: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
}
