import {
  isSaleClosed,
  isSaleOpen,
  isToggledChangeCurrentLotModal,
  isToggledSaleOnHold,
  isOperatorStartingAskModalOpen,
} from 'selectors/uiSelectors'

/* operator3 sale states */
export enum SaleState {
  /* sale is closed */
  CLOSED = 'CLOSED',
  /* operator has closed sale (sent the `/api/${saleId}/end_sale` message) */
  CLOSING = 'CLOSING',
  /* sale is open for live bidding */
  OPEN = 'OPEN',
  /* sale is not open for live bidding (hasn't started yet) */
  PRE_LIVE = 'PRE_LIVE',
  /* operator is closing sale */
  CONFIRMING_CLOSE = 'CONFIRMING_CLOSE',
  /* operator is changing lots */
  CHANGING_LOT = 'CHANGING_LOT',
  /* operator is opening the lot */
  OPENING_LOT = 'OPENING_LOT',
  /* sale is paused */
  SALE_PAUSED = 'SALE_PAUSED',
}

export const saleState: StateMapper<SaleState> = state => {
  if (isSaleClosed(state)) {
    return SaleState.CLOSED
  } else if (isToggledSaleOnHold(state)) {
    return SaleState.SALE_PAUSED
  } else if (!isSaleOpen(state)) {
    // TODO: is this (not closed, not closing, && !state.sale.isLiveOpen => pre-live) a fair assumption? derived from state.sale.isLiveOpen
    return SaleState.PRE_LIVE
  } else if (isToggledChangeCurrentLotModal(state)) {
    return SaleState.CHANGING_LOT
  } else if (isOperatorStartingAskModalOpen(state)) {
    return SaleState.OPENING_LOT
  } else return SaleState.OPEN
}
