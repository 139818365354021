import PropTypes from 'prop-types'
import React from 'react'
import { Serif, Join, Spacer, Flex } from '@artsy/palette'

const metadataItemDisplay = (item: string) => {
  return (
    <Serif size="3t" color="black60">
      {item}
    </Serif>
  )
}
export function LotArtworkTombstone(p, { selectedSaleArtwork }) {
  let { artwork } = selectedSaleArtwork
  return (
    <Join separator={<Spacer m="0" />}>
      {selectedSaleArtwork.artwork.artistNames && (
        <Serif size="3t" weight="semibold">
          {selectedSaleArtwork.artwork.artistNames}
        </Serif>
      )}
      <Flex>
        <Serif italic size="3t" color="black60">
          {artwork.title}
        </Serif>
        {artwork.date && (
          <Serif size="3t" color="black60">
            {', ' + artwork.date}
          </Serif>
        )}
      </Flex>
      {metadataItemDisplay(artwork.medium)}
      {artwork.dimensions.in && metadataItemDisplay(artwork.dimensions.in)}
      {artwork.dimensions.cm && metadataItemDisplay(artwork.dimensions.cm)}
      {artwork.editionOf && metadataItemDisplay(artwork.editionOf)}
    </Join>
  )
}

LotArtworkTombstone.contextTypes = {
  selectedSaleArtwork: PropTypes.object.isRequired,
}
