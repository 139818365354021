import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Autofocus } from 'components/Autofocus'
import { Modal, ActionButtons } from 'apps/operator/components/Modals/Modal'
import { ActionButton, Button } from 'apps/operator/components/Utils/ButtonBase'
import {
  RoundArtworkImage,
  ColorBlock,
} from 'apps/operator/components/Utils/Common'
import { PauseEndButtons } from './PauseEndButtons'
import { addCommaToInput } from 'utils/inputBoxUtils'

// TODO: remove this
// The reason behind this is that the when lot changes after passing or selling,
// for a fraction of seconds StartingAskModal renders with the previous lot info.
// If we use `defaultValue` for input initial value the value sticks there when lot
// changes to the new one. And in order to use `value` some sort of 'state' is needed
// to have the initial value and also keep this editable with comma.
// -> https://github.com/artsy/prediction/pull/531

export class StartingAskModal extends React.Component {
  static contextTypes = {
    currentLot: PropTypes.object.isRequired,
    currentSaleArtwork: PropTypes.object.isRequired,
    formatMoneyWithoutCents: PropTypes.func.isRequired,
    hasCompetitiveArtsyBidsAtOpen: PropTypes.bool.isRequired,
    isExternalOperator: PropTypes.bool.isRequired,
    isToggledSaleOnHold: PropTypes.bool.isRequired,
    onOpenChangeCurrentLotModal: PropTypes.func.isRequired,
    openCurrentLotAtStartingAsk: PropTypes.func.isRequired,
    openWithFloorBid: PropTypes.func.isRequired,
    skipCurrentLot: PropTypes.func.isRequired,
    startingAskInputValue: PropTypes.string.isRequired,
    startingAskInputValueChanged: PropTypes.func.isRequired,
    toggleSaleOnHold: PropTypes.func.isRequired,
  }

  numInput = null

  inputKeyPressed = e => {
    const { startingAskInputValueChanged } = this.context
    addCommaToInput(e)
    startingAskInputValueChanged(e.target.value)
  }

  render() {
    const {
      currentLot,
      currentSaleArtwork,
      startingAskInputValue,
      formatMoneyWithoutCents,
      isExternalOperator,
      onOpenChangeCurrentLotModal,
      openCurrentLotAtStartingAsk,
      openWithFloorBid,
      skipCurrentLot,
      hasCompetitiveArtsyBidsAtOpen,
    } = this.context
    const { maxBidAmountCents, sellingPriceCents } = currentLot.derivedLotState

    const maxBidAmount =
      !isExternalOperator && maxBidAmountCents
        ? formatMoneyWithoutCents(maxBidAmountCents / 100)
        : '--'

    const minBidAmount = hasCompetitiveArtsyBidsAtOpen
      ? formatMoneyWithoutCents(sellingPriceCents / 100)
      : '--'

    return (
      <Modal
        renderOutside={() => {
          return <PauseEndButtons />
        }}
      >
        <Container>
          <Content>
            <CurrentLotContainer>
              <CurrentLot>
                <RoundArtworkImage src={currentSaleArtwork.artwork.image.url} />
                <CurrentLotLabel>
                  Lot {currentSaleArtwork.lotLabel}
                </CurrentLotLabel>
              </CurrentLot>

              <LotActions>
                <SmallButton onClick={() => onOpenChangeCurrentLotModal(true)}>
                  Change Lot
                </SmallButton>
                <SmallButton onClick={() => skipCurrentLot()}>
                  Skip Lot
                </SmallButton>
              </LotActions>
            </CurrentLotContainer>
            <MinMaxContainer>
              <Value>{minBidAmount}</Value>
              <ColorBlock color="yellow80">Min</ColorBlock>
              {!isExternalOperator && (
                <>
                  <Value>{maxBidAmount}</Value>
                  <ColorBlock color="red100">Max</ColorBlock>
                </>
              )}
            </MinMaxContainer>

            <StartingAsk>
              <Label>Starting Ask</Label>
              <Autofocus>
                <Input
                  ref={el => {
                    this.numInput = el
                  }}
                  type="text"
                  onFocus={(e: any) => e.target.select()}
                  onChange={this.inputKeyPressed}
                  value={startingAskInputValue}
                />
              </Autofocus>
            </StartingAsk>
          </Content>
          <ActionButtons>
            <ActionButton
              onClick={() =>
                openWithFloorBid(
                  parseInt(this.numInput.value.replace(/\D/g, ''), 10) * 100
                )
              }
              color="green100"
            >
              Floor
            </ActionButton>
            <ActionButton
              onClick={() =>
                openCurrentLotAtStartingAsk(
                  parseInt(this.numInput.value.replace(/\D/g, ''), 10) * 100
                )
              }
              color="purple100"
            >
              OK
            </ActionButton>
          </ActionButtons>
        </Container>
      </Modal>
    )
  }
}

const Container = styled.div`
  width: 470px;
`

const Content = styled.div`
  padding: ${p => p.theme.margins.large};
`

const CurrentLotContainer = styled.div`
  ${p => p.theme.utils.stretch};
`

const CurrentLot = styled.div``

const CurrentLotLabel = styled.div`
  display: inline-block;
  padding: 10px;
  vertical-align: top;
`
const LotActions = styled.div``

const SmallButton = styled(Button)`
  ${({ theme }) => {
    return `
      background: ${theme.colors.yellow80};
      font-size: ${theme.fontSizes.medium};
      width: 115px;
      height: 35px;

      &:hover {
        border: ${theme.utils.border({
          color: theme.colors.black100,
        })};
      }

      &:active {
        border: ${theme.utils.border()};
      }

      &:first-child {
        margin-right: ${theme.margins.medium};
      }
    `
  }};
`

const Input = styled.input`
  ${({ theme }) => {
    return `
      width: 100%;
      border: ${theme.utils.border({ color: theme.colors.purple100 })};
      margin: ${theme.margins.small} 0 0 0;
      color: ${theme.colors.lightPurple};
      height: calc(${theme.margins.large} * 3);
      padding: 0 ${theme.margins.large};
      font-size: ${theme.fontSizes.large};
      caret-color: ${theme.colors.purple100};
      text-align: right;
      &:hover {
        color: ${theme.colors.purple100};
      }
    `
  }};
`
const MinMaxContainer = styled.div`
  font-size: ${p => p.theme.fontSizes.small};
  display: flex;
  align-items: center;
  margin-top: ${p => p.theme.margins.medium};
`

const StartingAsk = styled.div`
  position: relative;
`

const Value = styled.span`
  margin-right: ${p => p.theme.margins.xsmall};
  margin-top: 2px;
  &:not(:first-child) {
    margin-left: ${p => p.theme.margins.small};
  }
`

const Label = styled.div`
  ${({ theme }) => {
    return `
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.purple100};
      left: ${theme.margins.medium};
      position: absolute;
      top: 50%;
    `
  }};
`
