import createLogger from 'utils/logger'
import { isDeploy } from 'utils/environment'

const logger = createLogger('reducers/errorMsg.js')

export default function errorMsg(msg, action) {
  if (isDeploy) {
    logger.error(msg, action)
    return 'Bidding error, please try again'
  } else {
    logger.warn('Bidding error, please try again', msg, action)
  }

  return msg
}
