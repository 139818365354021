import { formatMoneyWithoutCents } from 'utils/formatHelper'

const formatPriceCent = amount =>
  amount ? formatMoneyWithoutCents(amount / 100) : '--'

export interface EstimateRange {
  lowEstimateCents?: number
  highEstimateCents?: number
}

export interface EstimateValue {
  estimateCents: number
}

export const presentEstimate = (estimate: EstimateValue | EstimateRange) => {
  if (!estimate) {
    return
  }

  const estimateValue = estimate as EstimateValue
  const estimateRange = estimate as EstimateRange

  if (estimateValue.estimateCents) {
    return `Est: ${formatPriceCent(estimateValue.estimateCents)}`
  } else if (
    estimateRange.lowEstimateCents ||
    estimateRange.highEstimateCents
  ) {
    const lowEstimate = formatPriceCent(estimateRange.lowEstimateCents)
    const highEstimate = formatPriceCent(estimateRange.highEstimateCents)

    return `Est: ${lowEstimate} - ${highEstimate}`
  }
}
