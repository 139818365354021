import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ActionButton } from 'apps/operator/components/Utils/ButtonBase'
import { Modal, ActionButtons } from './Modal'

export const ConfirmSaleModal = (_, context) => {
  const {
    currentLot,
    currentSaleArtwork,
    formatMoneyWithoutCents,
    onSellLot,
    setOperatorConfirmOpen,
    winningBidderLabel,
  } = context
  const { floorSellingPriceCents } = currentLot.derivedLotState

  const formatPriceCent = amount =>
    amount
      ? formatMoneyWithoutCents(amount / 100, currentSaleArtwork.currency)
      : '--'

  return (
    <Modal>
      <Container>
        <Message>
          <Row>
            <Label>Sell To</Label>
            <Value>{winningBidderLabel(currentLot)}</Value>
          </Row>
          <Row>
            <Label>For</Label>
            <Value>{formatPriceCent(floorSellingPriceCents)}</Value>
          </Row>
        </Message>
        <ActionButtons>
          <ActionButton
            color="white100"
            hoverBackground="black"
            onClick={() => setOperatorConfirmOpen(false)}
          >
            Cancel
          </ActionButton>
          <ActionButton color="purple100" onClick={() => onSellLot()}>
            OK
          </ActionButton>
        </ActionButtons>
      </Container>
    </Modal>
  )
}

// TODO: Generalize modal styles/components
const Container = styled.div``

const Message = styled.div`
  padding: 25px 20px;
`

const Row = styled.div`
  display: block;
  margin: 16px 0;
  display: flex;
`
const Label = styled.div`
  text-align: left;
`
const Value = styled.div`
  text-align: right;
  min-width: 300px;
  flex-grow: 1;
`

ConfirmSaleModal.contextTypes = {
  currentLot: PropTypes.object.isRequired,
  currentSaleArtwork: PropTypes.object.isRequired,
  formatMoneyWithoutCents: PropTypes.func.isRequired,
  nextLot: PropTypes.object.isRequired,
  onSellLot: PropTypes.func.isRequired,
  setOperatorConfirmOpen: PropTypes.func.isRequired,
  winningBidderLabel: PropTypes.func.isRequired,
}
