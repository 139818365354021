import PropTypes from 'prop-types'
import { find, some } from 'lodash'

/**
 * [isFloorOutbid description]
 * @param  {[type]}  lotEvent [description]
 * @param  {[type]}  lot      [description]
 * @return {Boolean}          [description]
 */
const isFloorOutbid = (lotEvent, lot) => {
  const eventIndex = lot.eventHistory.indexOf(lotEvent)
  const eventsAfterCurrent = lot.eventHistory.slice(eventIndex + 1)
  const isForSameAmount = event =>
    !event.cancelled &&
    event.type === 'FirstPriceBidPlaced' &&
    event.amountCents === lotEvent.amountCents
  return some(eventsAfterCurrent, isForSameAmount)
}

/**
 * [saleArtworkForEvent description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const saleArtworkForEvent = state => event => {
  return find(state.sale.saleArtworks, { internalID: event.lotId })
}

saleArtworkForEvent.propType = PropTypes.func.isRequired

/**
 * [lotEventState description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const lotEventState = state => (lotEvent, lot) => {
  // Bidding opened
  if (lotEvent.type === 'BiddingOpened') {
    return 'bidding-opened'
  }

  // Final call
  if (lotEvent.type === 'FinalCall') {
    return 'final-call'
  }

  // Fair warning
  if (lotEvent.type === 'FairWarning') {
    return 'fair-warning'
  }

  // Bidding sold or passed
  if (lotEvent.type === 'BiddingClosed') {
    if (
      lot.derivedLotState.soldStatus &&
      lot.derivedLotState.soldStatus === 'Sold'
    ) {
      return 'lot-sold'
    }
    return 'lot-passed'
  }

  const { floorAskingPriceCents, winningBidEventId } = lot.derivedLotState

  const isMyBid = lotEvent.bidder && lotEvent.bidder.bidderId === state.bidderId
  const isArtsyBidder =
    lotEvent.bidder && lotEvent.bidder.type === 'ArtsyBidder'
  const isOfflineBidder =
    lotEvent.bidder && lotEvent.bidder.type === 'OfflineBidder'
  const isPending = lotEvent.amountCents >= floorAskingPriceCents
  const isTopBid = lotEvent.eventId === winningBidEventId

  if (isOfflineBidder) {
    return 'floor-bid'
  }

  if (isMyBid) {
    if (isPending) {
      return 'me-pending'
    }
    if (isTopBid) {
      return 'me-winning'
    }
    if (!isTopBid) {
      return 'me-losing'
    }
  }

  if (isArtsyBidder) {
    if (isPending) {
      return 'artsy-pending'
    }
    if (isFloorOutbid(lotEvent, lot)) {
      return 'artsy-outbid'
    }

    return 'artsy-bid'
  }

  return 'unknown'
}

/**
 * Operator v2 lotEventState
 * @param  {[type]} state lotEvent and open lot
 * @return {[object]}       a more orthogonal list of flags to display history
 */
export const lotEventDisplayState = state => (lotEvent, lot) => {
  const { floorAskingPriceCents, winningBidEventId } = lot.derivedLotState
  const isMyBid = lotEvent.bidder && lotEvent.bidder.bidderId === state.bidderId
  const isArtsyBidder =
    lotEvent.bidder && lotEvent.bidder.type === 'ArtsyBidder'
  const isOfflineBidder =
    lotEvent.bidder && lotEvent.bidder.type === 'OfflineBidder'
  const isPending = lotEvent.amountCents >= floorAskingPriceCents
  const isTopBid = lotEvent.eventId === winningBidEventId
  const showAmount = !!lotEvent.bidder
  const isSold =
    lotEvent.type === 'BiddingClosed' &&
    lot.derivedLotState.soldStatus &&
    lot.derivedLotState.soldStatus === 'Sold'
  const isPassed = lotEvent.type === 'BiddingClosed' && !isSold
  const isArtsyOutbid = isArtsyBidder && isFloorOutbid(lotEvent, lot)
  const isExcluded = lotEvent.isArtsyOutbid || lotEvent.cancelled

  return {
    isArtsyBidder,
    isArtsyOutbid,
    isExcluded,
    isMyBid,
    isOfflineBidder,
    isPassed,
    isPending,
    isSold,
    isTopBid,
    showAmount,
  }
}

lotEventState.propType = PropTypes.func.isRequired

lotEventDisplayState.propType = PropTypes.func.isRequired
