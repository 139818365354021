import React from 'react'
import styled from 'styled-components'
import { CurrentAsk } from './CurrentAsk'
import { IncrementSelection } from './IncrementSelection'
import { History } from './History'

const Container = styled.div`
  width: 340px;
  padding: 20px;
  border-left: 2px solid black;
`

export const Activity = props => {
  return (
    <Container>
      <CurrentAsk />
      <IncrementSelection />
      <History />
    </Container>
  )
}
