import PropTypes from 'prop-types'
import React from 'react'
import MonitorEventListItem from './MonitorEventListItem'
import block from 'bem-cn-lite'
import './MonitorEventList.styl'

export default function MonitorEventList(_, { eventList, monitorSelectedLot }) {
  const b = block('monitor-event-list')
  const events = monitorSelectedLot
    ? eventList.filter(l => l.lotId === monitorSelectedLot.id)
    : eventList
  return (
    <div className={b()}>
      {events.map(event => (
        <MonitorEventListItem key={event.eventId} event={event} />
      ))}
    </div>
  )
}

MonitorEventList.contextTypes = {
  eventList: PropTypes.array.isRequired,
  monitorSelectedLot: PropTypes.object,
}
