import React, { Component } from 'react'
import createLogger from 'utils/logger'
import { ErrorModal } from './ErrorModal'
import { connect } from 'react-redux'
import { errorRuntime } from 'actions/appActions'

const logger = createLogger('components/ErrorBoundary.js')

interface Props {
  errorRuntimeAction: (error, info) => void
}

class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    })

    logger.error(error, info) // logger handles Sentry dispatches
    this.props.errorRuntimeAction(error, info)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorModal />
    }
    return this.props.children
  }
}

const mapDispatchToProps = {
  errorRuntimeAction: errorRuntime,
}

export default connect(null, mapDispatchToProps)(ErrorBoundary)
