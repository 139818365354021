import keyMirror from 'utils/keyMirror'

export enum Interaction {
  NONE = 'NONE',
  CONFIRM_CLOSE_LOT = 'CONFIRM_CLOSE_LOT',
  CONFIRM_PASS_LOT = 'CONFIRM_PASS_LOT',
  EDIT_FLOOR_ASKING_PRICE = 'EDIT_FLOOR_ASKING_PRICE',
  EDIT_INCREMENT = 'EDIT_INCREMENT',
}

export const actions = keyMirror(
  'CURRENT_ASK_INPUT_VALUE_CHANGED',
  'BID_SENT',
  'CLOSE_EIGEN_MODAL',
  'CLOSE_BID_ERROR_MESSAGE',
  'DISCONNECT',
  'ERROR_RUNTIME',
  'LOT_UPDATE_BROADCAST_DELAY',
  'MONITOR_SELECT_LOT',
  'MONITOR_SELECT_MODE',
  'OFFLINE',
  'ONLINE',
  'OPERATOR_CONNECTED',
  'RETRY_COUNTDOWN_TICK',
  'SALE_LOT_CHANGE_BROADCAST',
  'SALE_LOT_CHANGE_BROADCAST_DELAY',
  'SELECT_LOT',
  'SET_DEBUG_EXTERNAL_OPERATOR',
  'SET_OPERATOR_CHANGE_CURRENT_LOT',
  'SET_OPERATOR_UNDONE_KEY',
  'STARTING_ASK_INPUT_CHANGED',
  'TOGGLE_ARTWORK_LIST',
  'TOGGLE_AUCTION_INFO_MODAL',
  'TOGGLE_LOTS_MODAL',
  'UPDATE_OPERATOR_CHANGE_SELECTED_LOT',
  'UPDATE_SELECTED_MAX_BID',
  'USER_INTERACTION_COMPLETED',
  'USER_INTERACTION_STARTED',
  'WINDOW_RESIZE',
  // Dynamic Events from socketEvent.js #58
  'COMMAND_FAILED',
  'COMMAND_SUCCESSFUL',
  'INVALID_MESSAGE_REPLY',
  'LOT_UPDATE_BROADCAST',
  'POST_EVENT_RESPONSE',
  'SALE_ON_HOLD',
  'SALE_CLOSED'
)

export const askPriceInputChanged = value => ({
  type: actions.CURRENT_ASK_INPUT_VALUE_CHANGED,
  value,
})

export const startingAskInputValueChanged = value => ({
  type: actions.STARTING_ASK_INPUT_CHANGED,
  value,
})

export const bidSent = () => ({
  type: actions.BID_SENT,
})

export const closeBidErrorMessage = () => ({
  type: actions.CLOSE_BID_ERROR_MESSAGE,
})

export const closeEigenModal = () => ({
  type: actions.CLOSE_EIGEN_MODAL,
})

export const closeSale = () => ({
  type: actions.SALE_CLOSED,
})

export const disconnect = () => ({
  type: actions.DISCONNECT,
})

export const errorRuntime = error => ({
  type: actions.ERROR_RUNTIME,
  error,
})

export const lotUpdateBroadcastDelay = () => ({
  type: actions.LOT_UPDATE_BROADCAST_DELAY,
})

export const monitorSelectLot = data => ({
  type: actions.MONITOR_SELECT_LOT,
  data,
})

export const monitorSelectMode = data => ({
  type: actions.MONITOR_SELECT_MODE,
  data,
})

export const offline = () => ({
  type: actions.OFFLINE,
})

export const online = () => ({
  type: actions.ONLINE,
})

export const operatorConnected = data => ({
  type: actions.OPERATOR_CONNECTED,
  data,
})

export const retryCountdownTick = () => ({
  type: actions.RETRY_COUNTDOWN_TICK,
})

export const saleLotChangeBroadcast = data => ({
  type: actions.SALE_LOT_CHANGE_BROADCAST,
  data,
})

export const saleLotChangeBroadcastDelay = data => ({
  type: actions.SALE_LOT_CHANGE_BROADCAST_DELAY,
  data,
})

export const selectLot = ({ lotId }) => ({
  type: actions.SELECT_LOT,
  lotId,
})

export const setDebugExternalOperator = debugExternalOperator => ({
  type: actions.SET_DEBUG_EXTERNAL_OPERATOR,
  debugExternalOperator,
})

export const setOperatorChangeCurrentLot = (open?: any) => ({
  type: actions.SET_OPERATOR_CHANGE_CURRENT_LOT,
  open,
})

export const setOperatorConfirmSaleEndModal = open => ({
  type: actions.SET_OPERATOR_CONFIRM_SALE_END,
  open,
})

export const setOperatorUndoneKey = key => ({
  type: actions.SET_OPERATOR_UNDONE_KEY,
  key,
})

export const toggleArtworkList = upcoming => ({
  type: actions.TOGGLE_ARTWORK_LIST,
  upcoming,
})

export const toggleAuctionInfoModal = () => ({
  type: actions.TOGGLE_AUCTION_INFO_MODAL,
})

export const toggleLotsModal = () => ({
  type: actions.TOGGLE_LOTS_MODAL,
})

export const updateSelectedMaxBid = (newMaxBid, lotId) => ({
  type: actions.UPDATE_SELECTED_MAX_BID,
  newMaxBid,
  lotId,
})

export const updateOperatorChangeSelectedLot = lotId => ({
  type: actions.UPDATE_OPERATOR_CHANGE_SELECTED_LOT,
  lotId,
})

export const userInteractionStarted = (userInteraction: Interaction) => ({
  type: actions.USER_INTERACTION_STARTED,
  userInteraction,
})

export const userInteractionCompleted = () => ({
  type: actions.USER_INTERACTION_COMPLETED,
})

export const windowResize = windowWidth => ({
  type: actions.WINDOW_RESIZE,
  windowWidth,
})
