import styled from 'styled-components'
import { color } from '@artsy/palette'

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0.9;
  top: 0;
  left: 0;
  position: absolute;
  background-color: ${color('black80')};
`
