import React from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { Box, Flex } from '@artsy/palette'

import { GrayBox, Overlay } from 'apps/operator/elements'
import { Header } from 'apps/operator/components/Header'
import {
  Activity,
  ControlPanel,
  Footing,
  StartingAsk,
} from 'apps/operator/components/Main'
import { OperatorGlobalStyles, theme } from 'apps/operator/components/styles'

import {
  ClosedSaleModal,
  StartSaleModal,
  ChangeLotModal,
  PauseSaleModal,
} from 'apps/operator/components/Modals'
import { SaleState, saleState } from 'apps/operator/selectors/stateSelectors'
import { currentSaleArtwork } from 'selectors/lotSelectors'
import { Footer } from 'apps/operator/components/Footer'
import { reportToSentry } from 'apps/operator/operatorController'

/**
 * A Mapping of sale states to full-screen modals that should be shown
 * when they are active.
 */
const modalMap: { [value in SaleState]?: any } = {
  [SaleState.CLOSED]: ClosedSaleModal,
  [SaleState.PRE_LIVE]: StartSaleModal,
  [SaleState.CHANGING_LOT]: ChangeLotModal,
  [SaleState.SALE_PAUSED]: PauseSaleModal,
}

const mapStateToProps = state => ({
  saleState: saleState(state),
  currentSaleArtwork: currentSaleArtwork(state),
})

interface Props {
  saleState: SaleState
  currentSaleArtwork: SaleArtwork
}

export const _Layout: React.FunctionComponent<Props> = ({
  saleState,
  currentSaleArtwork,
}) => {
  const FullScreenModal = modalMap[saleState]
  const isOpeningLot = saleState === SaleState.OPENING_LOT
  return (
    <>
      <OperatorGlobalStyles />

      <ThemeProvider theme={theme}>
        <>
          {FullScreenModal && <FullScreenModal />}
          <Box width="884px" mx="auto">
            <Header />

            <Flex height="500px">
              <GrayBox width="344px" mr="2px">
                {isOpeningLot ? <StartingAsk /> : <Activity />}
              </GrayBox>
              <GrayBox width="344px" mr="2px" position="relative">
                {isOpeningLot && <Overlay />}

                <ControlPanel />
              </GrayBox>
              <GrayBox width="200px">
                <Footing />
              </GrayBox>
            </Flex>
            <Footer
              partnerName={currentSaleArtwork.artwork.partner.name}
              reportToSentry={reportToSentry}
            />
          </Box>
        </>
      </ThemeProvider>
    </>
  )
}

export const Layout = connect(mapStateToProps)(_Layout)
