import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { data as sd } from 'sharify'
import block from 'bem-cn-lite'
import './MaxBidButton.styl'
import { trackClickLink } from 'utils/analytics'

export default function MaxBidButton(_, context) {
  const b = block('max-bid-button')

  const {
    bidError,
    isTabletOrMobile,
    formatMoneyWithoutCents,
    isPendingBid,
    maxBidIncrements,
    myMaxBidButtonState,
    onChangeSelectedMaxBid,
    onPlaceMaxBid,
    selectedLot,
    selectedMaxBid,
    selectedSaleArtwork,
  } = context

  const bidButtonModifier = {
    [myMaxBidButtonState]: true,
  }

  /* eslint-disable max-len */
  const bidButtonMessage = isPendingBid
    ? 'Bidding…'
    : {
        'no-bids': `Bid ${formatMoneyWithoutCents(
          selectedMaxBid / 100,
          selectedSaleArtwork.symbol
        )}`,
        'winning-flashing': 'You’re the highest bidder',
        outbid: 'Outbid',
        winning: `Bid ${formatMoneyWithoutCents(
          selectedMaxBid / 100,
          selectedSaleArtwork.symbol
        )}`,
        losing: `Bid ${formatMoneyWithoutCents(
          selectedMaxBid / 100,
          selectedSaleArtwork.symbol
        )}`,
      }[myMaxBidButtonState]

  const labelMessage = {
    'no-bids': 'Set your max bid',
    winning: 'You’re the highest bidder',
    'winning-flashing': 'You’re the highest bidder',
    outbid: 'You’ve been outbid, increase your bid',
    losing: 'You’ve been outbid, increase your bid',
  }[myMaxBidButtonState]

  const conditionsPath = `${sd.FORCE_BASE_URL}/conditions-of-sale`

  return (
    <div className={b()}>
      <div className={b('current-bid')}>
        <div className={b('label')}>
          <h4>Current Bid</h4>
        </div>
        <span className={b('amount')}>
          {formatMoneyWithoutCents(
            selectedLot.derivedLotState.askingPriceCents / 100,
            selectedSaleArtwork.symbol
          )}
        </span>
      </div>
      <MaxBidToolTip
        bidButtonModifier={bidButtonModifier}
        isTabletOrMobile={isTabletOrMobile}
        labelMessage={labelMessage}
      />
      <div className={b('bid-select')}>
        <select
          value={selectedMaxBid}
          className={b('select')}
          onChange={e => {
            const newMaxBid = Number(e.target.value)
            onChangeSelectedMaxBid({
              lotId: selectedLot.id,
              prevMaxBid: selectedMaxBid,
              newMaxBid,
            })
          }}
        >
          {maxBidIncrements.map(incr => (
            <option value={incr} key={incr}>
              {formatMoneyWithoutCents(incr / 100, selectedSaleArtwork.symbol)}
            </option>
          ))}
        </select>
      </div>
      <div className={b('bid-button')}>
        <button
          className={b
            .builder()(bidButtonModifier)
            .mix('avant-garde-button-black')()}
          onClick={() =>
            !isPendingBid && onPlaceMaxBid(selectedLot.id, selectedMaxBid)
          }
        >
          {bidButtonMessage}
        </button>
      </div>
      {bidError && <p className={b('error')}>{bidError}</p>}
      <small>
        By placing your bid you agree to Artsy’s{' '}
        <a
          className={b('conditions-link')}
          onClick={() =>
            trackClickLink({
              label: 'conditions of sale',
              contextModule: 'bidding',
              destinationPath: conditionsPath,
            })
          }
          href={conditionsPath}
          target="_blank"
        >
          Conditions of Sale
        </a>
      </small>
    </div>
  )
}

MaxBidButton.contextTypes = {
  bidError: PropTypes.string.isRequired,
  deviceMode: PropTypes.string.isRequired,
  formatMoneyWithoutCents: PropTypes.func.isRequired,
  isPendingBid: PropTypes.bool.isRequired,
  isTabletOrMobile: PropTypes.bool.isRequired,
  maxBidIncrements: PropTypes.array.isRequired,
  myMaxBidButtonState: PropTypes.string.isRequired,
  onChangeSelectedMaxBid: PropTypes.func.isRequired,
  onPlaceMaxBid: PropTypes.func.isRequired,
  selectedLot: PropTypes.object.isRequired,
  selectedMaxBid: PropTypes.number.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
}

interface MaxBidToolTipProps {
  bidButtonModifier: any
  labelMessage: string
  isTabletOrMobile: string
}

interface State {
  isOpen: boolean
}

class MaxBidToolTip extends Component<MaxBidToolTipProps, State> {
  static propTypes = {
    bidButtonModifier: PropTypes.object.isRequired,
    isTabletOrMobile: PropTypes.bool.isRequired,
    labelMessage: PropTypes.string.isRequired,
  }

  state = {
    isOpen: false,
  }

  handleToggle = () => {
    const isOpen = !this.state.isOpen

    this.setState({
      isOpen,
    })
  }

  render() {
    const b = block('max-bid-button')
    const { bidButtonModifier, labelMessage, isTabletOrMobile } = this.props
    const { isOpen } = this.state
    const tooltipClass = isOpen ? 'show-tool-tip' : ''
    const events = {} as any

    if (isTabletOrMobile) {
      events.onClick = this.handleToggle
    } else {
      events.onMouseOver = this.handleToggle
      events.onMouseOut = this.handleToggle
    }

    return (
      <div className={b('set-max-bid')}>
        <div className={b('label', bidButtonModifier)}>
          <h4>{labelMessage}</h4>
        </div>
        <a
          {...events}
          title="Set the maximum amount you would like Artsy to bid up to on your behalf"
          className={b
            .builder()('tooltip')
            .mix(tooltipClass)()}
        />
      </div>
    )
  }
}
