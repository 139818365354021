import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import './MonitorEventListItem.styl'

export default function MonitorEventListItem(props, context) {
  const { event } = props
  const { eventTitle, eventSubtitle } = context
  const b = block('monitor-event-list-item')
  // TODO: Handle cancelled events.
  return (
    <div className={b()}>
      <div className={b('title')}>{eventTitle(event)}</div>
      <div className={b('subtitle')}>{eventSubtitle(event)}</div>
    </div>
  )
}

MonitorEventListItem.propTypes = {
  event: PropTypes.object.isRequired,
}

MonitorEventListItem.contextTypes = {
  sale: PropTypes.object.isRequired,
  eventTitle: PropTypes.func.isRequired,
  eventSubtitle: PropTypes.func.isRequired,
}
