import styled from 'styled-components'

export const RoundArtworkImage = styled.div<{ src: string }>`
  background-image: ${props => `url(${props.src})`};
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: inline-block;
  background-size: 38px;
`

export const ColorBlock = styled.div`
  ${({ theme, color }) => {
    const backgroundColor = theme.colors[color] || theme.colors.yellow80
    return `
      font-size: ${theme.fontSizes.xsmall};
      color: ${theme.colors.black100};
      ${theme.utils.center};
      width: 32px;
      height: 16px;
      background-color: ${backgroundColor}
    `
  }};
`
