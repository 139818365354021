/*
  This allows us to type-coerce things later from CausalityEvents into BidEvents later.
  Unfortunately it doesn't work from the adjacent local.d.ts type declarations file
  and has to be specially exported here - kind of a hack.
  TODO: Perhaps all causality types could live here and be import/exported from local.d.ts?
*/
export enum BidEventType {
  FirstPriceBidPlaced = 'FirstPriceBidPlaced',
  SecondPriceBidPlaced = 'SecondPriceBidPlaced',
}
