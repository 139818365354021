import PropTypes from 'prop-types'
import React from 'react'
import SaleListItem from './SaleListItem'
import block from 'bem-cn-lite'

export default function SaleList(props) {
  const { header, isAdmin = false, sales } = props

  const b = block('sale-list')

  return (
    <div className={b()}>
      <h1>{header}</h1>
      {sales.map(sale => {
        return (
          <SaleListItem isAdmin={isAdmin} key={sale.internalID} sale={sale} />
        )
      })}
    </div>
  )
}

SaleList.propTypes = {
  header: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  sales: PropTypes.array.isRequired,
}
