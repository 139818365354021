import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import LotEventList from 'components/LotEventList'
import LotShortStats from 'components/LotShortStats'
import CurrentLotBar from './CurrentLotBar'
import LotDetails from 'components/LotDetails'
import BidderButton from './BidderButton'
import './LotInfoTablet.styl'

export default function LotInfoTablet(_, context) {
  const b = block('lot-info-tablet')

  const { selectedSaleArtwork, sale, deviceMode, showLotInfo } = context

  const selectedSaleArtworkIndex =
    sale.saleArtworks.findIndex(lot => lot === selectedSaleArtwork) + 1

  return (
    <div className={b({ 'device-mode': deviceMode })}>
      <div className={b('lot-details')}>
        <LotDetails />

        <div className={b('lot-info-button')} onClick={() => showLotInfo()}>
          Lot info
        </div>
      </div>
      <CurrentLotBar />
      <div className={b('stats')}>
        <div className={b('current-lot-stats')}>
          <span>LOT&nbsp;</span>
          <span className={b('index')}>{selectedSaleArtworkIndex}</span>
          <span className={b('length')}>/{sale.saleArtworks.length}</span>
        </div>
        <LotShortStats />
      </div>
      <BidderButton />
      <LotEventList />
    </div>
  )
}

LotInfoTablet.contextTypes = {
  deviceMode: PropTypes.string.isRequired,
  sale: PropTypes.object.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
  showLotInfo: PropTypes.func.isRequired,
}
