import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ActionButton } from 'apps/operator/components/Utils/ButtonBase'
import { Modal, ActionButtons } from './Modal'

export const PassLotModal = (_, context) => {
  const { currentSaleArtwork, onPassLot, setOperatorConfirmOpen } = context

  const lotId = currentSaleArtwork.lotLabel
  return (
    <Modal>
      <Container>
        <Content>Pass lot {lotId}?</Content>
        <ActionButtons>
          <ActionButton
            color="white100"
            hoverBackground="black"
            onClick={() => setOperatorConfirmOpen(false)}
          >
            Cancel
          </ActionButton>
          <ActionButton color="purple100" onClick={() => onPassLot()}>
            OK
          </ActionButton>
        </ActionButtons>
      </Container>
    </Modal>
  )
}

const Container = styled.div``

const Content = styled.div`
  min-height: 140px;
  ${p => p.theme.utils.center};
`

PassLotModal.contextTypes = {
  currentSaleArtwork: PropTypes.object.isRequired,
  onPassLot: PropTypes.func.isRequired,
  setOperatorConfirmOpen: PropTypes.func.isRequired,
}
