import React from 'react'
import { connect } from 'react-redux'
import { Flex } from '@artsy/palette'
import { currentLot, nextLot } from 'selectors/lotSelectors'
import { artsyCanBid } from 'selectors/operatorUISelectors'

import {
  onConfirmFloorBid,
  onConfirmOnlineBid,
  onFairWarning,
  onFinalCall,
  onSellLot,
  onPassLot,
  operatorConfirmingSell,
  operatorConfirmingPass,
} from 'apps/operator/operatorController'

import {
  ArtsyBidButton,
  Text,
  Button,
  PriceText,
  BigButton,
} from 'apps/operator/elements/Buttons'
import { Interaction } from 'actions/appActions'

interface Props {
  currentLot: Lot
  nextLot: Lot
  artsyCanBid: boolean
  dispatch: any // Dispatch<?>
  confirmingSell: boolean
  confirmingPass: boolean
}

interface State {
  flashMsg: boolean
}

const mapStateToProps: (state: any) => Partial<Props> = state => ({
  artsyCanBid: artsyCanBid(state),
  currentLot: currentLot(state),
  nextLot: nextLot(state),
  confirmingPass: state.ongoingUserInteraction === Interaction.CONFIRM_PASS_LOT,
  confirmingSell:
    state.ongoingUserInteraction === Interaction.CONFIRM_CLOSE_LOT,
})

// tslint:disable-next-line:class-name
export class _ControlPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { flashMsg: false }
  }

  componentDidUpdate(prevProps) {
    if (this.props.artsyCanBid && !prevProps.artsyCanBid) {
      this.setState({ flashMsg: true })
      setTimeout(() => this.setState({ flashMsg: false }), 375)
    }
  }

  passClicked = () => {
    if (this.props.confirmingPass) {
      onPassLot({
        currentLot: this.props.currentLot,
        nextLot: this.props.nextLot,
        dispatch: this.props.dispatch,
      })
    } else {
      operatorConfirmingPass({
        open: true,
        dispatch: this.props.dispatch,
      })
    }
  }

  sellClicked = () => {
    if (this.props.confirmingSell) {
      onSellLot({
        currentLot: this.props.currentLot,
        nextLot: this.props.nextLot,
        dispatch: this.props.dispatch,
      })
    } else {
      operatorConfirmingSell({ open: true, dispatch: this.props.dispatch })
    }
  }

  winningBidderLabel: (lot: Lot) => string = lot =>
    (lot.derivedLotState.floorWinningBidder &&
      (lot.derivedLotState.floorWinningBidder.type === 'OfflineBidder'
        ? 'Floor'
        : 'Artsy')) ||
    ''

  render() {
    const {
      artsyCanBid,
      currentLot,
      dispatch,
      confirmingPass,
      confirmingSell,
    } = this.props
    const {
      floorAskingPriceCents,
      floorSellingPriceCents,
    } = this.props.currentLot.derivedLotState

    return (
      <Flex height="100%" p={2} flexDirection="column">
        <BigButton
          mb={1}
          onClick={() =>
            onConfirmFloorBid({
              currentLot,
              floorAskingPrice: floorAskingPriceCents,
              dispatch,
            })
          }
        >
          <Text>Floor</Text>
          <PriceText price={floorAskingPriceCents} color="black60" />
        </BigButton>

        <ArtsyBidButton
          mb={1}
          height="90px"
          disabled={!artsyCanBid}
          flash={this.state.flashMsg}
          askingPriceCents={floorAskingPriceCents}
          onClick={() =>
            onConfirmOnlineBid({
              currentLot,
              floorAskingPrice: floorAskingPriceCents,
              dispatch,
            })
          }
        />

        <Flex
          mt="auto"
          mb={2}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            bg="yellow80"
            borderColor="yellow80"
            onClick={() => onFairWarning({ currentLot })}
          >
            <Text color="black100">Warning</Text>
          </Button>

          <Button
            bg="red100"
            borderColor="red100"
            onClick={() => onFinalCall({ currentLot })}
          >
            <Text color="black100">Ending</Text>
          </Button>
        </Flex>

        <BigButton
          mb={2}
          bg="black100"
          color="white100"
          borderColor="black100"
          borderWidth="2px"
          height="60px"
          onClick={this.sellClicked}
          confirming={confirmingSell}
        >
          {confirmingSell ? (
            <Text>{`Confirm sale to ${this.winningBidderLabel(
              currentLot
            )}?`}</Text>
          ) : (
            <>
              <Text>{`Sell to ${this.winningBidderLabel(currentLot)}`}</Text>
              <PriceText price={floorSellingPriceCents} color="inherit" />
            </>
          )}
        </BigButton>

        <BigButton
          height="60px"
          borderColor="black100"
          borderWidth="2px"
          bg="black80"
          color="white100"
          confirming={confirmingPass}
          onClick={this.passClicked}
        >
          <Text>{confirmingPass ? 'Confirm Pass Lot?' : 'Pass Lot'}</Text>
        </BigButton>
      </Flex>
    )
  }
}

export const ControlPanel = connect(mapStateToProps)(_ControlPanel)
