import RavenClient from 'raven-js'
import * as appActions from 'actions/appActions'
import { Layout } from 'apps/operator/components/OperatorLayout'
import Socket from 'utils/socket'
import { controller, getProps } from 'react-redux-controller'
import { isServer } from 'utils/environment'
import createLogger from 'utils/logger'
import { operatorSelectorBundles } from 'selectors'

const logger = createLogger('operatorController.tsx')

let socket

// TODO: The socket is currently still managed by react-redux-controller
// in this file and executed by operator3's RRC-less functions because rrc
// only because rrc has been initialized by other components on the page.
function* initialize(props) {
  if (isServer) {
    return
  }

  const { causalityJWT, dispatch, role, sale, location } = yield getProps

  // Set debugExternalOperator from query param to override admin operator view
  // and switch to external operator view
  const debugExternalOperator = location.query.debugExternalOperator === 'true'
  dispatch(appActions.setDebugExternalOperator(debugExternalOperator))
  if (sale.isClosed) {
    dispatch(appActions.closeSale())
  }
  socket = yield Socket.sharedSocketOrConnect({
    causalityJWT,
    dispatch,
    role,
    saleId: sale.internalID,
  })
}

// Agnostic socket senders and action dispatchers for operator3.
// Connected to _RRC functions (with same name prefixed) below

export function onConfirmFloorBid({
  currentLot,
  floorAskingPrice,
  dispatch,
  amountCents = null,
}) {
  const amountToSend = amountCents || floorAskingPrice

  socket.send({
    type: 'PostEvent',
    event: {
      type: 'FirstPriceBidPlaced',
      lotId: currentLot.id,
      amountCents: amountToSend,
      bidder: { type: 'OfflineBidder' },
    },
  })

  dispatch(appActions.userInteractionCompleted())
}

export function onConfirmOnlineBid({
  currentLot,
  floorAskingPrice,
  dispatch,
  amountCents = null,
}) {
  const amountToSend = amountCents || floorAskingPrice

  socket.send({
    type: 'PostEvent',
    event: {
      type: 'CompositeOnlineBidConfirmed',
      lotId: currentLot.id,
      amountCents: amountToSend,
    },
  })

  dispatch(appActions.userInteractionCompleted())
}

export function onFairWarning({ currentLot }: { currentLot: Lot }) {
  socket.send({
    type: 'PostEvent',
    event: {
      type: 'FairWarning',
      lotId: currentLot.id,
    },
  })
}

export function onFinalCall({ currentLot }: { currentLot: Lot }) {
  socket.send({
    type: 'PostEvent',
    event: {
      type: 'FinalCall',
      lotId: currentLot.id,
    },
  })
}

export function onSellLot({ currentLot, nextLot, dispatch }) {
  // TODO this doesn't need to be sent if the lot has already met reserve
  socket.send({
    type: 'PostEvent',
    event: {
      type: 'UnknownReserveMet',
      lotId: currentLot.id,
    },
  })

  socket.send({
    type: 'PostEvent',
    event: {
      type: 'BiddingClosed',
      lotId: currentLot.id,
    },
  })

  socket.send({
    type: 'ChangeCurrentLot',
    lotId: nextLot.id,
  })

  dispatch(appActions.userInteractionCompleted())
}

export function onPassLot({ currentLot, nextLot, dispatch }) {
  socket.send({
    type: 'PostEvent',
    event: {
      type: 'BiddingClosed',
      lotId: currentLot.id,
    },
  })

  socket.send({
    type: 'ChangeCurrentLot',
    lotId: nextLot.id,
  })

  dispatch(appActions.userInteractionCompleted())
}

export function operatorConfirmingSell({ open, dispatch }) {
  if (open) {
    dispatch(
      appActions.userInteractionStarted(
        appActions.Interaction.CONFIRM_CLOSE_LOT
      )
    )
  } else {
    dispatch(appActions.userInteractionCompleted())
  }
}

export function operatorConfirmingPass({ open, dispatch }) {
  if (open) {
    dispatch(
      appActions.userInteractionStarted(appActions.Interaction.CONFIRM_PASS_LOT)
    )
  } else {
    dispatch(appActions.userInteractionCompleted())
  }
}

export function startingAskInputValueChanged({ value, dispatch }) {
  dispatch(appActions.startingAskInputValueChanged(value))
}

export function undoLastLotOperationAction({ currentLot, dispatch }) {
  const lastEventId = currentLot.derivedLotState.lastLiveOperatorEventId

  const { key } = socket.send({
    type: 'PostEvent',
    event: {
      type: 'LiveOperatorEventUndone',
      lotId: currentLot.id,
      event: {
        type: 'IdReference',
        eventId: lastEventId,
      },
    },
  })

  dispatch(appActions.setOperatorUndoneKey(key))
}

export function openWithFloorBid({ amountCents, currentLot, dispatch }) {
  try {
    socket.ask({
      type: 'PostEvent',
      event: {
        type: 'FloorAskingPriceSet',
        lotId: currentLot.id,
        amountCents,
      },
    })

    socket.ask({
      type: 'PostEvent',
      event: {
        type: 'CompositeLotPlacedOnBlock',
        lotId: currentLot.id,
      },
    })

    socket.ask({
      type: 'PostEvent',
      event: {
        type: 'FirstPriceBidPlaced',
        lotId: currentLot.id,
        amountCents: amountCents,
        bidder: { type: 'OfflineBidder' },
      },
    })
    dispatch(appActions.startingAskInputValueChanged(NaN))
  } catch (error) {
    logger.error('openWithFloorBid failed', error)
  }
}

export function openWithArtsyBid({ amountCents, currentLot, dispatch }) {
  try {
    socket.ask({
      type: 'PostEvent',
      event: {
        type: 'FloorAskingPriceSet',
        lotId: currentLot.id,
        amountCents,
      },
    })

    socket.ask({
      type: 'PostEvent',
      event: {
        type: 'CompositeLotPlacedOnBlock',
        lotId: currentLot.id,
      },
    })

    socket.send({
      type: 'PostEvent',
      event: {
        type: 'CompositeOnlineBidConfirmed',
        lotId: currentLot.id,
        amountCents: amountCents,
      },
    })
    dispatch(appActions.startingAskInputValueChanged(NaN))
  } catch (error) {
    logger.error('openWithArtsyBid failed', error)
  }
}

export function openCurrentLotAtStartingAsk({
  amountCents,
  currentLot,
  floorAskingPrice,
  dispatch,
}) {
  try {
    if (amountCents && amountCents !== floorAskingPrice) {
      socket.ask({
        type: 'PostEvent',
        event: {
          type: 'FloorAskingPriceSet',
          lotId: currentLot.id,
          amountCents,
        },
      })
    }

    socket.ask({
      type: 'PostEvent',
      event: {
        type: 'CompositeLotPlacedOnBlock',
        lotId: currentLot.id,
      },
    })
    dispatch(appActions.startingAskInputValueChanged(NaN))
  } catch (error) {
    logger.error('openCurrentLotAtStartingAsk failed', error)
  }
}

export function skipCurrentLot({ dispatch, nextLot }) {
  try {
    socket.ask({
      type: 'ChangeCurrentLot',
      lotId: nextLot.lotId,
    })
  } finally {
    dispatch(appActions.userInteractionCompleted())
  }
}

export function onOpenChangeCurrentLotModal({ dispatch }) {
  dispatch(appActions.setOperatorChangeCurrentLot())
}

export function reportToSentry() {
  RavenClient.captureMessage('User Issue Report')
}

// Begin RRC Generator Wrappers
function* setOperatorConfirmOpenRRC(open) {
  const { dispatch } = yield getProps
  operatorConfirmingSell({ open, dispatch })
}

function* setOperatorPassLotConfirmOpenRRC(open) {
  const { dispatch } = yield getProps
  operatorConfirmingPass({ open, dispatch })
}

function* onFairWarningRRC() {
  const { currentLot } = yield getProps
  onFairWarning({ currentLot })
}

function* onFinalCallRRC() {
  const { currentLot } = yield getProps
  onFinalCall({ currentLot })
}

function* onConfirmFloorBidRRC(amountCents = null) {
  const props = yield getProps
  onConfirmFloorBid({ ...props, amountCents })
}

function* onConfirmOnlineBidRRC(amountCents = null) {
  const props = yield getProps
  onConfirmOnlineBid({ ...props, amountCents })
}

function* onSellLotRRC() {
  const { currentLot, nextLot, dispatch } = yield getProps
  onSellLot({ currentLot, nextLot, dispatch })
}

function* onPassLotRRC() {
  const { currentLot, nextLot, dispatch } = yield getProps
  onPassLot({ currentLot, nextLot, dispatch })
}

function* startingAskInputValueChangedRRC(value) {
  const { dispatch } = yield getProps
  startingAskInputValueChanged({ value, dispatch })
}

function* undoLastLotOperationActionRRC() {
  const { currentLot, dispatch } = yield getProps
  undoLastLotOperationAction({ currentLot, dispatch })
}

function* openCurrentLotAtStartingAskRRC(amountCents) {
  const { currentLot, floorAskingPrice, dispatch } = yield getProps
  openCurrentLotAtStartingAsk({
    amountCents,
    currentLot,
    floorAskingPrice,
    dispatch,
  })
}

function* openWithFloorBidRRC(amountCents) {
  const { currentLot, dispatch } = yield getProps
  openWithFloorBid({ amountCents, currentLot, dispatch })
}

function* onOpenChangeCurrentLotModalRRC() {
  const { dispatch } = yield getProps
  onOpenChangeCurrentLotModal({ dispatch })
}

function* skipCurrentLotRRC() {
  const { dispatch, nextLot } = yield getProps
  skipCurrentLot({ dispatch, nextLot })
}

function* toggleSaleOnHoldRRC() {
  const { isToggledSaleOnHold, sale } = yield getProps
  toggleSaleOnHold({ isToggledSaleOnHold, sale })
}

export function toggleSaleOnHold({
  isToggledSaleOnHold,
  sale,
  userMessage = 'The auction is currently on hold. You can still place max bids.',
}) {
  // toggle - switching between true and false based on current value of isToggledSaleOnHold
  const isOnHold = !isToggledSaleOnHold
  socket.send({
    type: 'UpdateSaleOnHold',
    isOnHold: isOnHold,
    saleId: sale.internalID,
    userMessage: isOnHold ? userMessage : '',
  })
}

export function setSaleOnHoldMessage({ sale, userMessage }) {
  socket.send({
    type: 'UpdateSaleOnHold',
    isOnHold: true,
    saleId: sale.internalID,
    userMessage: userMessage,
  })
}

// Begin RRC Generators

function* onCancelAction() {
  const { dispatch } = yield getProps
  dispatch(appActions.userInteractionCompleted())
}

function* openCurrentLot() {
  const { currentLot } = yield getProps
  yield onOpenLot(currentLot.id)
}

function* onOpenLot(lotId) {
  socket.send({
    type: 'PostEvent',
    event: {
      type: 'CompositeLotPlacedOnBlock',
      lotId,
    },
  })
}

function* onOperatorChangeLot(nextLotId) {
  const { dispatch } = yield getProps

  socket.send({
    type: 'ChangeCurrentLot',
    lotId: nextLotId,
  })

  dispatch(appActions.userInteractionCompleted())
}

function* changeLotFromModal(nextLotId) {
  const { dispatch } = yield getProps

  try {
    yield socket.ask({
      type: 'ChangeCurrentLot',
      lotId: nextLotId,
    })
  } finally {
    dispatch(appActions.userInteractionCompleted())

    // Reset currently selected so when returning to the change lot modal
    // the currently selected item will be the selected lot, rather than the
    // lot that was selected during an action that may have been cancelled.
    dispatch(appActions.updateOperatorChangeSelectedLot(-1))
  }

  // When a previously sold or passed lot is reopened, undo the last event
  // (BiddingClosed) to return directly to active bidding.

  // FIXME: Because socket.ask doesn't currently have a way to wait for the
  // actual state to change in response to its command, it's likely
  // `currentLot` isn't the next lot at this point in execution.
  const { lotById } = yield getProps
  const newlySelectedLot = lotById(nextLotId)

  if (newlySelectedLot.derivedLotState.biddingStatus === 'Complete') {
    const lastEventId = newlySelectedLot.derivedLotState.lastLiveOperatorEventId

    try {
      yield socket.ask({
        type: 'PostEvent',
        event: {
          type: 'LiveOperatorEventUndone',
          lotId: newlySelectedLot.id,
          event: {
            type: 'IdReference',
            eventId: lastEventId,
          },
        },
      })
    } catch (error) {
      logger.error('Failed to undo to reopen closed lot', error)
    }
    yield* closeFloorAskingModal()
  }
  dispatch(appActions.startingAskInputValueChanged(NaN))
  yield* closeChangeLotModal()
}

function* onOperatorChangeFloorAskingPrice(amountCents) {
  const { currentLot, dispatch } = yield getProps
  socket.send({
    type: 'PostEvent',
    event: {
      type: 'FloorAskingPriceSet',
      lotId: currentLot.id,
      amountCents,
    },
  })
  dispatch(appActions.askPriceInputChanged(0))
  dispatch(appActions.userInteractionCompleted())
}

// TODO remove when replaced

function* openChangeLotModal() {
  const { dispatch } = yield getProps
  dispatch(appActions.setOperatorChangeCurrentLot(true))
}

function* closeChangeLotModal() {
  const { dispatch } = yield getProps
  dispatch(appActions.setOperatorChangeCurrentLot(false))
}

function* onSelectChangeLot(lotId) {
  const { dispatch } = yield getProps
  dispatch(appActions.updateOperatorChangeSelectedLot(lotId))
}

function* setUpdateFloorAskingPriceOpen(open) {
  const { dispatch } = yield getProps

  if (open) {
    dispatch(
      appActions.userInteractionStarted(
        appActions.Interaction.EDIT_FLOOR_ASKING_PRICE
      )
    )
  } else {
    dispatch(appActions.userInteractionCompleted())
    dispatch(appActions.askPriceInputChanged('0'))
  }
}

function* currentAskInputValueChanged(value) {
  const { dispatch } = yield getProps
  dispatch(appActions.askPriceInputChanged(value))
}

function* onReserveToggle(lotId) {
  socket.send({
    type: 'PostEvent',
    event: {
      type: 'UnknownReserveMet',
      lotId,
    },
  })
}

function* closeBidErrorMessage() {
  const { dispatch } = yield getProps
  dispatch(appActions.closeBidErrorMessage())
}

function* closeFloorAskingModal() {
  const { dispatch } = yield getProps
  dispatch(appActions.userInteractionCompleted())
}

function* returnToIncrementPolicy() {
  const { currentLot, dispatch } = yield getProps
  yield socket.ask({
    type: 'PostEvent',
    event: {
      type: 'IncrementPolicyChanged',
      lotId: currentLot.id,
      newIncrementPolicy: currentLot.derivedLotState.incrementPolicyId,
    },
  })
  dispatch(appActions.userInteractionCompleted())
}

function* set258Increments() {
  const { currentLot, dispatch } = yield getProps
  yield socket.ask({
    type: 'PostEvent',
    event: {
      type: 'IncrementChanged',
      lotId: currentLot.id,
      incrementStrategy: {
        type: 'PatternIncrement',
        startingAmountCents: currentLot.derivedLotState.floorSellingPriceCents,
        stopPattern: [0.2, 0.5, 0.8],
      },
    },
  })
  dispatch(appActions.userInteractionCompleted())
}

function* setConstantIncrement(amountCents) {
  const { currentLot, dispatch } = yield getProps
  yield socket.ask({
    type: 'PostEvent',
    event: {
      type: 'IncrementChanged',
      lotId: currentLot.id,
      incrementStrategy: {
        type: 'ConstantIncrement',
        amountCents,
      },
    },
  })
  dispatch(appActions.userInteractionCompleted())
}

function* startEditingIncrement() {
  const { dispatch } = yield getProps
  dispatch(
    appActions.userInteractionStarted(appActions.Interaction.EDIT_INCREMENT)
  )
}

function* stopEditingIncrement() {
  const { dispatch } = yield getProps
  dispatch(appActions.userInteractionCompleted())
}

const operatorMethods = {
  currentAskInputValueChanged,
  changeLotFromModal,
  closeBidErrorMessage,
  closeChangeLotModal,
  closeFloorAskingModal,
  initialize,
  onCancelAction,
  onConfirmFloorBid: onConfirmFloorBidRRC,
  onConfirmOnlineBid: onConfirmOnlineBidRRC,
  onFairWarning: onFairWarningRRC,
  onFinalCall: onFinalCallRRC,
  onOpenChangeCurrentLotModal: onOpenChangeCurrentLotModalRRC,
  onOperatorChangeFloorAskingPrice,
  onOperatorChangeLot,
  onOpenLot,
  onPassLot: onPassLotRRC,
  onReserveToggle,
  onSelectChangeLot,
  onSellLot: onSellLotRRC,
  openCurrentLotAtStartingAsk: openCurrentLotAtStartingAskRRC,
  openCurrentLot,
  openChangeLotModal,
  openWithFloorBid: openWithFloorBidRRC,
  returnToIncrementPolicy,
  set258Increments,
  setConstantIncrement,
  setOperatorConfirmOpen: setOperatorConfirmOpenRRC,
  setOperatorPassLotConfirmOpen: setOperatorPassLotConfirmOpenRRC,
  setUpdateFloorAskingPriceOpen,
  skipCurrentLot: skipCurrentLotRRC,
  startEditingIncrement,
  startingAskInputValueChanged: startingAskInputValueChangedRRC,
  stopEditingIncrement,
  toggleSaleOnHold: toggleSaleOnHoldRRC,
  undoLastLotOperationAction: undoLastLotOperationActionRRC,
}

export const OperatorController = controller(
  Layout,
  operatorMethods,
  operatorSelectorBundles
)
