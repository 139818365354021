import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { theme } from './styles'

export default function MonitorModePicker(
  _,
  { monitorMode, onMonitorSelectMode }
) {
  return (
    <Container>
      <label>Mode:</label>
      <ModeButton
        selected={monitorMode === 'lots'}
        onClick={() => onMonitorSelectMode('lots')}
      >
        Lots
      </ModeButton>
      <ModeButton
        selected={monitorMode === 'bids'}
        onClick={() => onMonitorSelectMode('bids')}
      >
        Bids
      </ModeButton>
    </Container>
  )
}

const Container = styled.div``

const ModeButton = styled.button<{ selected: boolean }>`
  margin: 10px;
  width: 200px;
  padding: 5px;
  outline: none;
  background-color: ${({ selected }) =>
    selected ? theme.colors.grayccc : theme.colors.white};
  border: 1px solid
    ${({ selected }) =>
      selected ? theme.colors.gray333 : theme.colors.gray666};
`

MonitorModePicker.contextTypes = {
  monitorMode: PropTypes.string.isRequired,
  onMonitorSelectMode: PropTypes.func.isRequired,
}
