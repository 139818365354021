import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import Icon from 'assets/icons'
import './LotEventListItem.styl'

export default function LotEventListItem(props, context) {
  const { lot, lotEvent, saleArtwork } = props
  const {
    viewMode,
    selectedSaleArtwork,
    lotEventState,
    formatMoneyWithoutCents,
  } = context
  const b = block('lot-event-list-item')
  const lotState = lotEventState(lotEvent, lot)
  const lotEventModifiers = {
    'view-mode': viewMode,
    [lotState]: true,
    cancelled: lotEvent.cancelled,
  }

  let amount
  if (viewMode === 'operator') {
    amount = formatMoneyWithoutCents(lotEvent.amountCents / 100)
  } else {
    amount = formatMoneyWithoutCents(
      lotEvent.amountCents / 100,
      saleArtwork.symbol
    )
  }
  const eventPaddleNumber = lotEvent.bidder ? lotEvent.bidder.paddleNumber : ''
  const [lotEventLabel, lotEventData] = {
    'floor-bid': ['Floor', amount],
    'me-winning': ['You', amount],
    'me-losing': ['You (outbid)', amount],
    'me-pending': ['You', amount],
    'artsy-pending': [eventPaddleNumber, amount],
    'artsy-bid': [eventPaddleNumber, amount],
    'artsy-outbid': [eventPaddleNumber, amount],
    'bidding-opened': [
      `Lot ${selectedSaleArtwork.lotLabel} now open for bidding`,
      <Icon name="gavel" className={b('gavel')} key="gravel" />,
    ],
    'lot-sold': [
      `Lot ${selectedSaleArtwork.lotLabel} sold for ${formatMoneyWithoutCents(
        lot.derivedLotState.floorSellingPriceCents / 100
      )}`,
      <Icon name="gavel" className={b('gavel')} key="gravel" />,
    ],
    'lot-passed': [
      `Lot ${selectedSaleArtwork.lotLabel} passed`,
      <Icon name="gavel" className={b('gavel')} key="gravel" />,
    ],
    'fair-warning': ['Fair Warning', <Icon name="alert" key="alert" />],
    'final-call': ['Final Call', <Icon name="alert" key="alert" />],
    unknown: [
      'WTF',
      <input
        className={b('wtf')}
        value={JSON.stringify(lotEvent)}
        readOnly={true}
        key="wtf"
      />,
    ],
  }[lotState]

  return (
    <div className={b(lotEventModifiers)}>
      <div>
        <div className={b('event-label')}>{lotEventLabel}</div>
        <div className={b('event-data')}>{lotEventData}</div>
      </div>
    </div>
  )
}

LotEventListItem.propTypes = {
  lot: PropTypes.object.isRequired,
  saleArtwork: PropTypes.object.isRequired,
  lotEvent: PropTypes.object.isRequired,
}

LotEventListItem.contextTypes = {
  viewMode: PropTypes.string.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
  lotEventState: PropTypes.func.isRequired,
  formatMoneyWithoutCents: PropTypes.func.isRequired,
}
