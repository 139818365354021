import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

interface ButtonProps {
  color?: string
  hoverBackground?: string
  hoverBorder?: string
  hoverOutline?: string
}

// prettier-ignore
export const Button = styled.button<ButtonProps & SpaceProps>`
  ${space}
  cursor: pointer;
  outline: 0;
`

export const ButtonBase = styled(Button)`
  &:hover {
    border: 2px solid ${p => p.theme.colors.white100};
  }

  &:active {
    border: 2px solid ${p => p.theme.colors.white100};
    background: black;
    color: black;
  }
`

export const ModalButton = styled(ButtonBase)`
  ${({ theme }) => {
    const border = theme.utils.border()

    return `
      background: ${theme.colors.purple100};
      border-top: ${border};
      color: ${theme.colors.white100};
      font-size: ${theme.fontSizes.large};
      height: 60px;
      width: 100%;

      ${theme.utils.center};

      &:hover {
        border: 0;
        border-top: ${border};
      }

      &:active {
        color: ${theme.colors.white100};
      }
    `
  }};
`

export const ActionButton = styled(Button)`
  ${({ color, hoverBackground, hoverOutline, disabled, theme }) => {
    return `
      background: ${theme.colors[color]};
      border: ${theme.utils.border({ size: 1, color: theme.colors[color] })};
      color: ${color !== 'white100' ? 'white' : 'black'};
      font-size: ${theme.fontSizes.large};
      height: calc(${theme.margins.large} * 3);
      width: calc(50% - 1px);
      ${
        disabled
          ? `
        opacity: 0.7;
        pointer-events: none;
      `
          : null
      }

      ${theme.utils.center};

      &:hover {
        background: ${hoverBackground};
        border: ${theme.utils.border()};
        color: ${theme.colors.white100};
        ${hoverOutline && `outline: ${hoverOutline};`}
      }
    `
  }};
`
