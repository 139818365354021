import PropTypes from 'prop-types'
import React from 'react'
import BidButton from 'components/BidButton'
import MaxBidButton from 'components/MaxBidButton'
import RegisterButton from 'components/RegisterButton'
import DisabledButton from 'components/DisabledButton'
import SessionLink from 'components/SessionLink'
import block from 'bem-cn-lite'
import './BidderButton.styl'

export default function BidderButton(_, context) {
  const b = block('bidder-button')
  const { isLoggedIn, myBidButtonState, sale, selectedSaleArtwork } = context
  const button = {
    'registration-closed': <DisabledButton message={'Registration closed'} />,
    'register-to-bid': <RegisterButton />,
    'max-bid': <MaxBidButton />,
    bid: <BidButton />,
    'registration-pending': <DisabledButton message={'Registration pending'} />,
  }[myBidButtonState] || <RegisterButton />

  let caption
  if (!isLoggedIn && myBidButtonState === 'registration-closed') {
    caption = (
      <div className={b('caption')}>
        Already registered for this sale?{' '}
        <SessionLink loginHref={`/${sale.slug}/login`} />
      </div>
    )
  } else {
    caption = (
      <div className={b('caption')}>
        Currency in {selectedSaleArtwork.currency}
      </div>
    )
  }

  return (
    <div className={b()}>
      {button}
      {caption}
    </div>
  )
}

BidderButton.contextTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  myBidButtonState: PropTypes.string.isRequired,
  sale: PropTypes.object.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
}
