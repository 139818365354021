import React from 'react'
import styled from 'styled-components'
import { Modal } from './Modal'

export const ClosedSaleModal = props => {
  return (
    <Modal>
      <Container>
        <Content>Sale is closed</Content>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  ${p => p.theme.utils.center};
  height: 140px;
`

const Content = styled.div``
