import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'apps/operator/components/Utils/ButtonBase'

export const PauseEndButtons = (_, context) => {
  const { isToggledSaleOnHold, toggleSaleOnHold } = context

  return (
    <TopRightButtons>
      <TopRightButton
        onClick={() => toggleSaleOnHold()}
        color={!isToggledSaleOnHold ? 'yellow80' : 'green100'}
      >
        {' '}
        {!isToggledSaleOnHold ? 'Pause' : 'Resume'} Sale
      </TopRightButton>
    </TopRightButtons>
  )
}

const TopRightButtons = styled.div`
  top: ${p => p.theme.margins.large};
  right: ${p => p.theme.margins.large};
  position: absolute;
  display: flex;
`

const TopRightButton = styled(Button)`
  ${({ theme, color }) => {
    return `
    color: ${theme.colors.white100};
    font-size: ${theme.fontSizes.small};
    margin-right: ${theme.margins.large};
    height: calc(${theme.margins.large} * 3);
    width: 150px;
    background-color: ${theme.colors[color]};

    &:hover {
      border: ${theme.utils.border()};
    }

    &:active {
      border: transparent;
    }
  `
  }};
`

PauseEndButtons.contextTypes = {
  isToggledSaleOnHold: PropTypes.bool.isRequired,
  toggleSaleOnHold: PropTypes.func.isRequired,
}
