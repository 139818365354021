import { default as React } from 'react'
import styled from 'styled-components'

export default function LotsHeaderBar() {
  return (
    <Container>
      <Spacer />
      <ColumnHeader>Current</ColumnHeader>
      <ColumnHeader>Asking</ColumnHeader>
      <ColumnHeader>Low Est.</ColumnHeader>
      <ColumnHeader>Artsy Min</ColumnHeader>
      <ColumnHeader>Artsy Max</ColumnHeader>
      <ColumnHeader>Interest / Outcome</ColumnHeader>
      <ColumnHeader>Hammer Price</ColumnHeader>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  position: absolute;
  background-color: white;
  z-index: 2;
  width: 100%;
`

const ColumnHeader = styled.div`
  display: flex;
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: rotate(180deg);
  width: 60px;
  height: 100px;
  padding-top: 10px;
  align-items: center;

  &:nth-child(even) {
    background: #eee;
  }
`

const Spacer = styled.div`
  width: 200px;
  height: 100px;
`
