/**
 * Roles used throughout Causality and Prediction
 */
export const roles: { [key: string]: Roles } = {
  BIDDER: 'bidder',
  MONITOR: 'monitor',
  OBSERVER: 'observer',
  OPERATOR: 'operator',
  EXTERNAL_OPERATOR: 'externalOperator',
  PARTICIPANT: 'participant',
}
