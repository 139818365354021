import reset from 'styled-reset'
import { createGlobalStyle } from 'styled-components'

export const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    grayaaa: '#aaa',
    gray333: '#333',
    gray666: '#666',
    gray777: '#777',
    grayccc: '#ccc',
    graye5: '#e5e5e5',
    grayf3: '#f3f3f3',
    green: '#0EDA83',
    purple: '#6E1FFF',
    lightPurple: 'rgba(98,46,236,.5)',
    red: '#F7625A',
    yellow: '#F1CD39',
  },
  delays: {
    footingArrows: '0.25s',
  },
  fontFamily: {
    avant_garde_gothic: `
      font-family: 'ITC Avant Garde Gothic W04', 'AvantGardeGothicITCW01D 731075',
    'AvantGardeGothicITCW01Dm', 'Helvetica', 'sans-serif';
    `,
    garamond: `
     font-family: 'Adobe Garamond W08', 'adobe-garamond-pro',
    'AGaramondPro-Regular', 'Times New Roman', 'Times', 'serif';
    `,
  },
  fontSizes: {
    xsmall: '8px',
    small: '12px',
    large: '22px',
  },
  margins: {
    small: '10px',
    medium: '15px',
    large: '20px',
  },
  utils: {
    center: `
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    stretch: `
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    flexEnd: `
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `,
    zBelowModalBackground: `
      z-index: 1;
    `,
    zModalBackground: `
      z-index: 2;
    `,
    zAboveModalBackground: `
      z-index: 2;
    `,
    border: ({ size = 2, color = '#fff' } = {}) => `
      ${size}px solid ${color};
    `,
  },
}

export const GlobalStyles = createGlobalStyle`
  ${reset};

  *, *:before, *:after {
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'avant-garde', Helvetica, arial, sans-serif;
    font-size: 12px;
    /* text-transform: uppercase; */
  }

  input, button {
    font-family: 'avant-garde', Helvetica, arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    text-transform: uppercase;
  }

  input {
    background: #fff;
    border: 2px solid #000;
    font-size: 12px;
    outline: none;

    &:focus {
      border-color: #622eec;
    }
  }

  input[type=text],
  input[type=password] {
    box-sizing : border-box;
  }

  button {
    padding: 0;
    margin: 0;
    border: 0;
  }
`
