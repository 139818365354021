import React from 'react'
import styled from 'styled-components'

export const Modal = props => {
  return (
    <Container>
      <Content>{props.children}</Content>
      {props.renderOutside()}
    </Container>
  )
}

Modal.defaultProps = {
  // For content that exists outside of modal window
  renderOutside: x => x,
}

const Container = styled.div`
  background: rgba(0, 0, 0, 0.85);
  position: absolute;
  width: 100%;
  height: 100%;
  ${p => p.theme.utils.center};
  ${p => p.theme.utils.zModalBackground};
`

const Content = styled.div`
  ${({ theme }) => {
    return `
      background: ${theme.colors.white100};
      font-size: ${theme.fontSizes.large};
      min-width: 340px;
      min-height: 140px;
      text-align: center;
    `
  }};
`

export const ActionButtons = styled.div`
  ${({ color, theme }) => {
    return `
      background: ${theme.colors.black100};
      ${theme.utils.stretch};
      border-top: ${theme.utils.border({
        size: 2,
        color: theme.colors.black100,
      })};
    `
  }};
`
