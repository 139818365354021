import PropTypes from 'prop-types'
import React from 'react'
import ArtworkListItem from 'apps/bidder/components/ArtworkListItem'
import block from 'bem-cn-lite'
import './ArtworkListTablet.styl'

export default function ArtworkListTablet(_, context) {
  const { sale, lotById } = context
  const b = block('artwork-list-tablet')

  return (
    <div className={b()}>
      {sale.saleArtworks.map(saleArtwork => {
        const isPast =
          lotById(saleArtwork.internalID).derivedLotState.biddingStatus ===
          'Complete'
        return (
          <ArtworkListItem
            key={saleArtwork.internalID}
            lot={saleArtwork}
            past={isPast}
          />
        )
      })}
    </div>
  )
}

ArtworkListTablet.contextTypes = {
  sale: PropTypes.object.isRequired,
  lotById: PropTypes.func.isRequired,
}
