import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import Icon from 'assets/icons'
import './LotShortStats.styl'

export default function LotShortStats(_, { selectedLot, viewMode }) {
  const b = block('lot-short-stats')
  const { bidCount } = selectedLot.derivedLotState
  return (
    <div className={b({ 'view-mode': viewMode })}>
      <div className={b('paddle')}>
        <Icon name="paddle" />
        <span>{bidCount}</span>
      </div>
    </div>
  )
}

LotShortStats.contextTypes = {
  selectedLot: PropTypes.object.isRequired,
  viewMode: PropTypes.string.isRequired,
}
