import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const HistoryItem = (props, context) => {
  const { lotEvent } = props
  const {
    selectedSaleArtwork,
    lotEventDisplayState,
    formatMoneyWithoutCents,
    currentLot,
  } = context

  const amount = formatMoneyWithoutCents(lotEvent.amountCents / 100)

  const eventPaddleNumber = lotEvent.bidder ? lotEvent.bidder.paddleNumber : ''

  const lotEventDisplay = lotEventDisplayState(lotEvent, currentLot)

  let label = ''
  if (lotEvent.type === 'BiddingOpened') {
    label = `Lot ${selectedSaleArtwork.lotLabel} now open for bidding`
  } else if (lotEventDisplay.isOfflineBidder) {
    label = 'Floor'
  } else if (lotEventDisplay.isArtsyBidder) {
    label = eventPaddleNumber
  } else if (lotEvent.type === 'FairWarning') {
    label = 'Fair Warning'
  } else if (lotEvent.type === 'FinalCall') {
    label = 'Final Call'
  } else if (lotEventDisplay.isPassed) {
    label = `Lot ${selectedSaleArtwork.lotLabel} passed`
  } else if (lotEventDisplay.isSold) {
    label = `Lot ${
      selectedSaleArtwork.lotLabel
    } sold for ${formatMoneyWithoutCents(
      currentLot.derivedLotState.floorSellingPriceCents / 100
    )}`
  }

  const value = lotEventDisplay.showAmount ? amount : ''
  const eventStyles = {
    isExcluded: lotEventDisplay.isExcluded,
    isTopBid: lotEventDisplay.isTopBid,
    eventType: lotEvent.type,
  }
  return (
    !lotEventDisplay.isPending && (
      <Item eventStyles={eventStyles}>
        <span>{label}</span>
        <span>{value}</span>
      </Item>
    )
  )
}

const Item = styled.li<{
  eventStyles: {
    eventType: string
    isExcluded: boolean
    isTopBid: boolean
  }
}>`
  ${({ eventStyles, theme }) => {
    let color = theme.colors.black60
    if (eventStyles.eventType === 'FairWarning') {
      color = theme.colors.yellow80
    } else if (eventStyles.eventType === 'FinalCall') {
      color = theme.colors.red100
    } else if (eventStyles.isTopBid) {
      color = theme.colors.white100
    }
    return `
      color: ${color};
      padding: ${theme.margins.small} ${theme.margins.medium};
      text-decoration: ${eventStyles.isExcluded ? 'line-through' : 'none'};
      ${theme.utils.stretch};
    `
  }};
`

HistoryItem.propTypes = {
  lotEvent: PropTypes.object.isRequired,
}

HistoryItem.contextTypes = {
  currentLot: PropTypes.object.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
  lotEventDisplayState: PropTypes.func.isRequired,
  formatMoneyWithoutCents: PropTypes.func.isRequired,
}
