import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'

export default function LotEstimate(
  { saleArtwork },
  { formatMoneyWithoutCents }
) {
  if (saleArtwork) {
    if (
      saleArtwork.lowEstimate &&
      saleArtwork.lowEstimate.cents &&
      saleArtwork.highEstimate &&
      saleArtwork.highEstimate.cents
    ) {
      const b = block('lot-estimate')
      const low = formatMoneyWithoutCents(
        saleArtwork.lowEstimate.cents / 100,
        saleArtwork.symbol
      )
      const high = formatMoneyWithoutCents(
        saleArtwork.highEstimate.cents / 100,
        saleArtwork.symbol
      )
      return (
        <span>
          <span className={b('label')}>Estimate:&nbsp;</span>
          <span className={b('dollars')}>
            {low}–{high}
          </span>
        </span>
      )
    }
  }
  return <span />
}

LotEstimate.contextTypes = {
  formatMoneyWithoutCents: PropTypes.func.isRequired,
}
