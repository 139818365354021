import React from 'react'
import block from 'bem-cn-lite'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import './ErrorModal.styl'

export const ErrorModal = connect(state => {
  return {
    errorModal: state.errorModal,
  }
})(props => {
  const { errorModal } = props
  const b = block('error-modal')

  if (isEmpty(errorModal)) {
    return <div />
  }
  return (
    <div className={b()}>
      <div className={b('content')}>
        <h1 className={b('h1')}>{errorModal.header}</h1>
        <h2 className={b('h2')}>{errorModal.subHeader}</h2>
        <div className={b('error-link')}>
          <a href="#" onClick={() => window.location.reload()}>
            Reload
          </a>
        </div>
      </div>
    </div>
  )
})
