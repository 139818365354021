import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import './SessionLink.styl'

export default function SessionLink(props, context) {
  const { isLoggedIn } = context
  const href = isLoggedIn ? '/logout' : props.loginHref
  const b = block('session-link')
  const linkText = isLoggedIn ? 'Log Out' : 'Log In'

  return (
    <a className={b()} href={href}>
      {linkText}
    </a>
  )
}

SessionLink.propTypes = {
  loginHref: PropTypes.string,
}
SessionLink.contextTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}
