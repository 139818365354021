import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import Icon from 'assets/icons'
import './AuctionNavBar.styl'

export default function AuctionNavBar(_, context) {
  const {
    deviceMode,
    sale,
    onToggleLotsModal,
    onToggleAuctionInfoModal,
    isToggledLotsModal,
    isToggledAuctionInfoModal,
  } = context
  const b = block('auction-nav-bar')
  const modalPresent = isToggledAuctionInfoModal || isToggledLotsModal

  let navButtons
  let navTitle
  let onClickEvent
  if (!modalPresent) {
    navButtons = (
      <div>
        <div className={b('lots-button')}>
          <Icon
            name="lots"
            className={b('lots-icon')}
            onClick={() => onToggleLotsModal()}
          />
        </div>
        <div className={b('info-button')}>
          <Icon
            name="info"
            className={b('info-icon')}
            onClick={() => onToggleAuctionInfoModal()}
          />
        </div>
      </div>
    )
    navTitle = (
      <div className={b('auction-title')}>
        <img className={b('partner-icon')} src={sale.partner_icon_url} />
      </div>
    )
  } else {
    if (isToggledAuctionInfoModal) {
      onClickEvent = onToggleAuctionInfoModal
      navTitle = 'Auction Information'
    }
    if (isToggledLotsModal) {
      onClickEvent = onToggleLotsModal
      navTitle = 'Lots'
    }
    navButtons = (
      <Icon
        className={b('close')}
        name="close"
        onClick={() => onClickEvent()}
      />
    )
  }

  return (
    <div className={b({ 'device-mode': deviceMode })}>
      <div className={b('nav-title')}>{navTitle}</div>
      <div className={b('nav-buttons')}>{navButtons}</div>
    </div>
  )
}

AuctionNavBar.contextTypes = {
  deviceMode: PropTypes.string.isRequired,
  sale: PropTypes.object.isRequired,
  onToggleLotsModal: PropTypes.func.isRequired,
  onToggleAuctionInfoModal: PropTypes.func.isRequired,
  isToggledAuctionInfoModal: PropTypes.bool.isRequired,
  isToggledLotsModal: PropTypes.bool.isRequired,
}
