import PropTypes from 'prop-types'
import React from 'react'
import { data as sd } from 'sharify'
import block from 'bem-cn-lite'
import './MonitorAuctionInfo.styl'
import { compactAmount } from 'utils/formatHelper'
import { artsyUnderbids, lotHammerPrice } from 'utils/lotHelper'

export default function MonitorAuctionInfo(_, context) {
  const {
    sale,
    artsyWonLots,
    auctionEditLinkPath,
    gmvAmountDisplay,
    percentageByEstimate,
    maxBidsAmountDisplay,
    reserveCount,
    reserveSales,
    causalityBidCount,
    onlineBidCount,
    passedOrSoldLots,
  } = context

  const b = block('monitor-auction-info')

  const totalWonByArtsy = artsyWonLots.reduce(
    (sum, l) => sum + lotHammerPrice(l),
    0
  )
  const artsyLostArtsyUnderbids = artsyUnderbids(
    // FIXME: fix me later- check the right lots, whatever they are
    passedOrSoldLots
  )
  const artsyWonVsTotal: string = `${artsyWonLots.length} / ${compactAmount(
    totalWonByArtsy / 100
  )}`

  return (
    <div className={b()}>
      <h1 className={b('sale-name')}>
        {sale.name} (
        <a href={sd.OHM_BASE_URL + auctionEditLinkPath()}>{sale.slug}</a>)
      </h1>
      <div className={b('stat-column')}>
        <div className={b('stat-list')}>
          <span className={b('stat-name')}>Total Sold GMV: </span>
          <span className={b('stat-value')}>{gmvAmountDisplay()}</span>
        </div>
        <div className={b('stat-list')}>
          <span className={b('stat-name')}>Total Max Bids: </span>
          <span className={b('stat-value')}>{maxBidsAmountDisplay()}</span>
        </div>
        <div className={b('stat-list')}>
          <span className={b('stat-name')}>Sale % by estimate: </span>
          <span className={b('stat-value')}>{percentageByEstimate()}</span>
        </div>
        <div className={b('stat-list')}>
          <span className={b('stat-name')}>Total sales above reserve: </span>
          <span className={b('stat-value')}>{reserveSales()}</span>
        </div>
        <div className={b('stat-list')}>
          <span className={b('stat-name')}>Number of Artsy bids placed: </span>
          <span className={b('stat-value')}>{onlineBidCount()}</span>
        </div>
      </div>
      <div className={b('stat-column')}>
        <div className={b('stat-list')}>
          <span className={b('stat-name')}>
            Number of causality bids events:{' '}
          </span>
          <span className={b('stat-value')}>{causalityBidCount()}</span>
        </div>
        <div className={b('stat-list')}>
          <span className={b('stat-name')}>
            Number of lots meeting reserve:{' '}
          </span>
          <span className={b('stat-value')}>{reserveCount()}</span>
        </div>
        <div className={b('stat-list')}>
          <span className={b('stat-name')}>Artsy Lots Won/Total: </span>
          <span className={b('stat-value')}>{artsyWonVsTotal}</span>
        </div>
        <div className={b('stat-list')}>
          <span className={b('stat-name')}>Artsy Underbids: </span>
          <span className={b('stat-value')}>
            {artsyLostArtsyUnderbids.length}
          </span>
        </div>
      </div>
    </div>
  )
}

MonitorAuctionInfo.contextTypes = {
  sale: PropTypes.object.isRequired,
  artsyWonLots: PropTypes.array.isRequired,
  artsyLosingLots: PropTypes.array.isRequired,
  auctionEditLinkPath: PropTypes.func.isRequired,
  gmvAmountDisplay: PropTypes.func.isRequired,
  percentageByEstimate: PropTypes.func.isRequired,
  reserveCount: PropTypes.func.isRequired,
  reserveSales: PropTypes.func.isRequired,
  maxBidsAmountDisplay: PropTypes.func.isRequired,
  causalityBidCount: PropTypes.func.isRequired,
  onlineBidCount: PropTypes.func.isRequired,
  passedOrSoldLots: PropTypes.array.isRequired,
}
