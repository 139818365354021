import { cloneDeepWith, cloneDeep, isObject } from 'lodash'

export const omitDeep = (collection: object, excludeKeys: string[]) => {
  const omitFn = (value: any) => {
    if (isObject(value)) {
      excludeKeys.forEach((key: string) => {
        delete value[key]
      })
    }
  }

  const result = cloneDeepWith(cloneDeep(collection), omitFn)
  return result
}
