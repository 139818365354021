import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import { Image } from '@artsy/palette'
import styled from 'styled-components'

import './SelectedLot.styl'

export default function SelectedLot(_, context) {
  const { deviceMode, isDesktop, selectedSaleArtwork, showLotInfo } = context

  const b = block('selected-lot')

  return (
    <div className={b({ 'device-mode': deviceMode })}>
      {selectedSaleArtwork.artwork.image && (
        <SelectedImage
          src={selectedSaleArtwork.artwork.image.url}
          alt={selectedSaleArtwork.artwork.title}
          preventRightClick
        />
      )}
      <br />

      {isDesktop && (
        <a className={b('toggle')} onClick={() => showLotInfo()}>
          Lot information
        </a>
      )}
    </div>
  )
}

// TODO: Inline once styled-system or palette supports all of them
const SelectedImage = styled(Image)`
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
  max-height: 77vh;
`

SelectedLot.contextTypes = {
  deviceMode: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
  showLotInfo: PropTypes.func.isRequired,
}
