import SaleList from './SaleList'
import SessionLink from 'components/SessionLink'
import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import { partition } from 'lodash'
import './SaleListLayout.styl'

export default function SaleListLayout(_, context) {
  const { isAdmin = false, sales } = context
  const b = block('sale-list-layout')
  const [liveAuctionData, futureAuctionData] = partition(
    sales,
    sale => sale.isLiveOpen
  )

  return (
    <div className={b()}>
      <nav className={b('nav')}>
        {' '}
        <SessionLink loginHref="/login" />{' '}
      </nav>
      <SaleList
        header="Current Live Auctions"
        isAdmin={isAdmin}
        sales={liveAuctionData}
      />

      {isAdmin && (
        <SaleList
          header="Future Live Auctions"
          isAdmin={isAdmin}
          sales={futureAuctionData}
        />
      )}
    </div>
  )
}

SaleListLayout.contextTypes = {
  isAdmin: PropTypes.bool.isRequired,
  sales: PropTypes.array.isRequired,
}
