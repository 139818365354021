import { formatMoney } from 'accounting'

/**
 * Formats money with optional currency symbol
 * @param amount money amount without cents
 * @param symbol optional currency symbol (assumes it appears before number)
 */
export const formatMoneyWithoutCents = (amount: number, symbol = '') =>
  formatMoney(amount, symbol, 0)

/**
 * Compact money formatter. Uses `k`, `M` and `B` for 1,000, 1,000,000 etc
 * @param amount money amount without cents
 * @param symbol optional currency symbol (assumes it appears before number)
 */
export const compactAmount = (amount: number, symbol = ''): string => {
  if (amount < 1000) {
    return `${symbol}${amount}`
  } else if (amount < 1000000) {
    return `${symbol}${parseFloat((amount / 1000).toFixed(1))}k`
  } else if (amount < 1000000000) {
    return `${symbol}${parseFloat((amount / 1000000).toFixed(1))}M`
  } else {
    return `${symbol}${parseFloat((amount / 1000000000).toFixed(1))}B`
  }
}
