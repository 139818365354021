import React, { useState } from 'react'
import { Modal } from '../Modal'
import { PauseEndButtons } from '../PauseEndButtons'
import { ChangeHoldMessage } from './ChangeHoldMessage'
import { SaleOnHold } from './SaleOnHold'
import { connect } from 'react-redux'
import { Box } from '@artsy/palette'

interface Props {
  saleOnHoldMessage: string
  sale: {
    internalID: string // TODO: Re-use metaphysics types if possible
  }
}

export const UnconnectedPauseSaleModal: React.FC<Props> = props => {
  const [changingHoldMessage, setChangingHoldMessage] = useState<Boolean>(false)

  function toggleChangeHoldMessage() {
    setChangingHoldMessage(!changingHoldMessage)
  }

  const InnerComponent = changingHoldMessage ? ChangeHoldMessage : SaleOnHold
  return (
    <Modal
      renderOutside={() => {
        return <PauseEndButtons />
      }}
    >
      <Box width={450} textAlign="center" p={4}>
        <InnerComponent
          toggleChangeHoldMessage={toggleChangeHoldMessage}
          saleOnHoldMessage={props.saleOnHoldMessage}
          sale={props.sale}
        />
      </Box>
    </Modal>
  )
}

const mapStateToProps = state => ({
  sale: state.sale,
  saleOnHoldMessage: state.saleOnHoldMessage,
})

export const PauseSaleModal = connect(mapStateToProps)(
  UnconnectedPauseSaleModal
)
