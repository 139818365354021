import React, { EventHandler } from 'react'
import styled from 'styled-components'
import { Button } from 'apps/operator/elements/Buttons'
import { Flex, Sans, color } from '@artsy/palette'
import { Text } from 'apps/operator/elements'

interface Props {
  partnerName: string
  reportToSentry: EventHandler<any>
}

export const Footer: React.FunctionComponent<Props> = ({
  partnerName,
  reportToSentry,
}) => {
  return (
    <Flex mt={1} justifyContent="space-between" alignItems="center">
      <ReportIssueButton
        ml="2px"
        height="25px"
        borderColor="black60"
        onClick={reportToSentry}
      >
        <Sans size="2">Send Error Log</Sans>
      </ReportIssueButton>
      <Text color="black60">{partnerName}</Text>
    </Flex>
  )
}

const ReportIssueButton = styled(Button)`
  height: 25px;
  background: ${color('black100')};
  color: ${color('black60')};
  ${Sans} {
    color: inherit;
    background: inherit;
  }
  &:hover {
    color: ${color('white100')};
  }
  &:active {
    background: ${color('white100')};
    color: ${color('black100')};
  }
`
