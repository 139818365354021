import React, { useState, MouseEventHandler } from 'react'
import { Flex, Sans, TextArea } from '@artsy/palette'
import { Button } from 'apps/operator/elements/Buttons'
import { setSaleOnHoldMessage } from 'apps/operator/operatorController'

interface Props {
  toggleChangeHoldMessage: MouseEventHandler<HTMLButtonElement>
  saleOnHoldMessage: string
  sale: {
    internalID: string // TODO: Re-use metaphysics types if possible
  }
}

export const ChangeHoldMessage: React.FC<Props> = props => {
  const [message, setMessage] = useState('')

  function handleInput(event) {
    const message = event.value
    setMessage(message)
  }

  function onSubmit(event) {
    setSaleOnHoldMessage({
      sale: props.sale,
      userMessage: getFinalMessage(),
    })
    props.toggleChangeHoldMessage(event)
  }

  function getFinalMessage() {
    if (message.length > 0) {
      return message + ' You can still place max bids.'
    } else {
      return props.saleOnHoldMessage
    }
  }

  return (
    <>
      <Sans size="5t" color="black100">
        Change hold message
      </Sans>
      <Sans size="3t" color="black80">
        This message will display immediately for all bidders.
      </Sans>
      <Sans size="3t" color="black60" py="20px">
        <i>{getFinalMessage()}</i>
      </Sans>
      <TextArea
        characterLimit={300}
        placeholder="Add text"
        onChange={handleInput}
      />
      <Flex justifyContent="space-between">
        <Button
          width="150px"
          mt={1}
          borderColor="black10"
          bg="black10"
          onClick={props.toggleChangeHoldMessage}
        >
          <Sans size="3t" weight="medium" color="black100">
            Cancel
          </Sans>
        </Button>
        <Button
          width="150px"
          mt={1}
          borderColor="black100"
          bg="black100"
          onClick={onSubmit}
        >
          <Sans size="3t" weight="medium" color="white100">
            Display
          </Sans>
        </Button>
      </Flex>
    </>
  )
}
