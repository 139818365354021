import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import moment from 'moment-timezone'

export default function SaleListItem(props) {
  const { isAdmin, sale } = props
  const b = block('sale-list-item')

  const time = moment.tz(sale.liveStartAt, 'America/New_York')
  const formattedTime = time.format('MMM D h:mm:ssA z')

  const timeMessage = sale.isLiveOpen
    ? 'Live Bidding Open'
    : `Bidding opens ${formattedTime}`

  return (
    <li className={b()}>
      <div className={b('sale-link')}>
        <a href={`/${sale.slug}`}>
          {sale.coverImage && sale.coverImage.url ? (
            <img className={b('cover')} src={sale.coverImage.url} />
          ) : (
            <div className={b('no-cover')} />
          )}
          <h2>{sale.name}</h2>
          <h3>{timeMessage}</h3>
          <span className="chevron" />
        </a>
      </div>

      {isAdmin && (
        <div className={b('admin-links')}>
          <a
            href={`/operator/${sale.slug}`}
            title="operator"
            className={b('admin-links__op-link')}
          >
            O
          </a>
          <a
            href={`/monitor/${sale.slug}`}
            className={b('admin-links__mon-link')}
            title="monitor"
          >
            M
          </a>
        </div>
      )}
    </li>
  )
}

SaleListItem.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  sale: PropTypes.object.isRequired,
}
