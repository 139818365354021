import React from 'react'
import styled from 'styled-components'
import { Modal } from './Modal'
import { ModalButton } from 'apps/operator/components/Utils/ButtonBase'

export const LoginModal = props => {
  return (
    <Modal>
      <Container>
        <Content>
          <Label>Log in</Label>
          <Input type="text" placeholder="Username" />
          <Input type="password" placeholder="Password" />
        </Content>
        <ActionButtons>
          <ActionButton color="white100" hoverBackground="black">
            Log in
          </ActionButton>
        </ActionButtons>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  background: ${p => p.theme.colors.white100};
  width: 340px;
`

const Content = styled.div`
  padding: ${p => p.theme.margins.large};
`

const Label = styled.div`
  font-size: ${p => p.theme.fontSizes.large};
  color: ${p => p.theme.colors.black100};
`

const Input = styled.input`
  ${({ theme }) => {
    return `
      width: 100%;
      margin: ${theme.margins.large} 0 0 0;
      padding: ${theme.margins.large};
      font-size: ${theme.fontSizes.small};
      border: ${theme.utils.border({ color: theme.colors.black100 })};
    `
  }};
`

const ActionButtons = styled.div`
  border-top: ${p => p.theme.utils.border({ color: p.theme.colors.black100 })};
`

const ActionButton = styled(ModalButton)`
  border: ${p => p.theme.utils.border({ color: p.theme.colors.black100 })};

  &:hover {
    border: ${p => p.theme.utils.border({ color: p.theme.colors.white100 })};
  }
`
