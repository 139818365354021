import * as appActions from 'actions/appActions'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './AppContainer.styl'
import { Dispatch } from 'redux'

interface AppContainerProps {
  dispatch: Dispatch<any>
}

class AppContainer extends Component<AppContainerProps> {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  componentDidMount() {
    window.addEventListener('online', this.handleOnlineEvent)
    window.addEventListener('offline', this.handleOfflineEvent)
    window.addEventListener('resize', this.handleResizeEvent)

    this.props.dispatch(appActions.windowResize(window.innerWidth))
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnlineEvent)
    window.removeEventListener('offline', this.handleOfflineEvent)
    window.removeEventListener('resize', this.handleResizeEvent)
  }

  handleOnlineEvent = () => {
    this.props.dispatch(appActions.online())
  }

  handleOfflineEvent = () => {
    this.props.dispatch(appActions.offline())
  }

  handleResizeEvent = () => {
    this.props.dispatch(appActions.windowResize(window.innerWidth))
  }

  render() {
    return <div>{this.props.children}</div>
  }
}

export default connect()(AppContainer)
