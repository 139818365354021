import { omitDeep } from './omitDeep'

const RAVEN_REDUX_PAYLOAD_SIZE_LIMIT: number = 500

const STATE_EXCLUDE_KEYS: string[] = [
  'clientMetadata',
  'eventHistory',
  'causalityJWT',
  'description',
  'lots',
  'saleArtworks',
]

const ACTION_PAYLOAD_EXCLUDE_KEYS: string[] = [
  'clientMetadata',
  'clientCredentials',
  'partialEventHistory',
  'fullEventOrder',
  'amountsCents',
  'events',
]

const actionPayloadData = (action: object): object => {
  const keys: string[] = Object.keys(action).filter(
    (key: string) => key !== 'type'
  )
  return keys.reduce((clone: any, key: string): any => {
    if (key === 'data') {
      clone[key] = omitDeep(action[key], ACTION_PAYLOAD_EXCLUDE_KEYS)
    } else {
      clone[key] = action[key]
    }
    return clone
  }, {})
}

export const createLogFromState = (state: object): object => {
  // excludes large and unnecessary keys from state to keep the raven POST under 100k
  const omitted = omitDeep(state, STATE_EXCLUDE_KEYS)
  return omitted
}

export const breadcrumbDataFromAction = (action: object): object => {
  const payload: string = JSON.stringify(actionPayloadData(action)).substring(
    0,
    RAVEN_REDUX_PAYLOAD_SIZE_LIMIT
  )
  return { payload: payload }
}
