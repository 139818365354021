/* eslint-disable max-len */

import PropTypes from 'prop-types'
import { formatMoney } from 'accounting'
import { floorAskingPrice } from './lotSelectors'
import { Interaction } from 'actions/appActions'

export const currentAskInputValue = state => state.currentAskInputValue
currentAskInputValue.propType = PropTypes.string.isRequired

export const startingAskInputValue = state => {
  if (!Number.isNaN(state.startingAskInputValue)) {
    return state.startingAskInputValue
  } else {
    return formatMoneyWithoutCents()(floorAskingPrice(state) / 100)
  }
}
startingAskInputValue.propType = PropTypes.string.isRequired

export const bidError = state => state.bidError
bidError.propType = PropTypes.string.isRequired

// tslint:disable-next-line
export const causalityJWT = state => state.causalityJWT
causalityJWT.propType = PropTypes.string.isRequired

export const deviceMode = state => state.deviceMode
deviceMode.propType = PropTypes.string.isRequired

export const eigenModalOpen = state => state.eigenModalOpen
eigenModalOpen.propType = PropTypes.bool.isRequired

export const errorModal = state => state.errorModal
errorModal.propType = PropTypes.object.isRequired

// TODO: use formatMoneyWithoutCents in utils/formatHelper
export const formatMoneyWithoutCents = () => (amount, symbol = '') =>
  formatMoney(amount, symbol, 0)
formatMoneyWithoutCents.propType = PropTypes.func.isRequired

export const isLoggedIn = state => Boolean(state.me)
isLoggedIn.propType = PropTypes.bool.isRequired

export const isDesktop = state =>
  !(state.deviceMode === 'tablet' || state.deviceMode === 'mobile')
isDesktop.propType = PropTypes.bool.isRequired

export const isMobile = state => state.deviceMode === 'mobile'
isMobile.propType = PropTypes.bool.isRequired

export const isTabletOrMobile = state =>
  state.deviceMode === 'tablet' || state.deviceMode === 'mobile'
isTabletOrMobile.propType = PropTypes.bool.isRequired

export const isPendingBid = state => !state.bidError && state.isPendingBid
isPendingBid.propType = PropTypes.bool.isRequired

export const isToggledAuctionInfoModal = state =>
  state.isToggledAuctionInfoModal
isToggledAuctionInfoModal.propType = PropTypes.bool.isRequired

export const isToggledChangeCurrentLotModal = state =>
  state.isToggledChangeCurrentLotModal
isToggledChangeCurrentLotModal.propType = PropTypes.bool.isRequired

export const isToggledLotsModal = state => state.isToggledLotsModal
isToggledLotsModal.propType = PropTypes.bool.isRequired

export const isOperatorConfirmOpen = state =>
  state.ongoingUserInteraction === Interaction.CONFIRM_CLOSE_LOT
isOperatorConfirmOpen.propType = PropTypes.bool.isRequired

export const isOperatorPassLotConfirmOpen = state =>
  state.ongoingUserInteraction === Interaction.CONFIRM_PASS_LOT
isOperatorPassLotConfirmOpen.propType = PropTypes.bool.isRequired

export const isOperatorStartingAskModalOpen = state => {
  const lotId = state.currentLotId
  const currentLot = state.lots[lotId]
  return currentLot
    ? currentLot.derivedLotState.biddingStatus !== 'OnBlock'
    : false
}
isOperatorStartingAskModalOpen.propType = PropTypes.bool.isRequired

export const isToggledSaleOnHold = state => state.isToggledSaleOnHold
isToggledSaleOnHold.propType = PropTypes.bool.isRequired

export const isSaleClosed = state => state.isSaleClosed
isSaleClosed.propType = PropTypes.bool.isRequired

export const isSaleOpen = state => {
  return state.sale.isLiveOpen
}
isSaleOpen.propType = PropTypes.bool.isRequired

export const saleOnHoldMessage = state => state.saleOnHoldMessage
saleOnHoldMessage.propType = PropTypes.string

export const isUpcomingArtworkList = state => state.isUpcomingArtworkList
isUpcomingArtworkList.propType = PropTypes.bool.isRequired

export const isUpdatingFloorAskingPrice = state =>
  state.ongoingUserInteraction === Interaction.EDIT_FLOOR_ASKING_PRICE
isUpdatingFloorAskingPrice.propType = PropTypes.bool.isRequired

export const isUpdatingIncrement = state =>
  state.ongoingUserInteraction === Interaction.EDIT_INCREMENT
isUpdatingIncrement.propType = PropTypes.bool.isRequired

export const operatorUndoActionKey = state =>
  state.operatorUndoActionKey.length > 0
operatorUndoActionKey.propType = PropTypes.bool.isRequired

export const outbidFlashing = state => state.outbidFlashing
outbidFlashing.propType = PropTypes.bool.isRequired

export const retryCountdown = state => state.retryCountdown
retryCountdown.propType = PropTypes.number.isRequired

export const role = state => state.role
role.propType = PropTypes.string.isRequired

export const viewMode = state => state.viewMode
viewMode.propType = PropTypes.string.isRequired

export const winningFlashing = state => state.winningFlashing
winningFlashing.propType = PropTypes.bool.isRequired

export const winningBidderLabel = () => lot => {
  let winnerLabel = 'Unknown'

  if (lot.derivedLotState.floorWinningBidder) {
    if (lot.derivedLotState.floorWinningBidder.type === 'OfflineBidder') {
      winnerLabel = 'floor bidder'
    } else {
      winnerLabel = `Artsy bidder ${lot.derivedLotState.floorWinningBidder.paddleNumber}`
    }
  }

  return winnerLabel
}
winningBidderLabel.propType = PropTypes.func.isRequired
