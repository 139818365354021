import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import './RegisterButton.styl'

export default function RegisterButton(_, { sale, onRegisterToBid }) {
  const b = block('register-button')

  function handleButtonClick() {
    onRegisterToBid(sale.slug)
  }

  return (
    <div className={b()}>
      <a
        onClick={handleButtonClick}
        className={b.builder().mix('avant-garde-button-black')()}
      >
        Register to bid
      </a>
    </div>
  )
}

RegisterButton.contextTypes = {
  sale: PropTypes.object.isRequired,
  onRegisterToBid: PropTypes.func.isRequired,
}
