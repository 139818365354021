import { BidEventType } from '../typings/CausalityTypes'
import { formatMoneyWithoutCents } from 'utils/formatHelper'
import groupBy from 'lodash/groupBy'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'

export const isSold = (lot: Lot): boolean =>
  lot.derivedLotState.soldStatus === 'Sold'

export const isPassed = (lot: Lot): boolean =>
  lot.derivedLotState.soldStatus === 'Passed'

// lot is selling to artsy bidder
export const isSellingToArtsyBidder = (lot: Lot): boolean => {
  const { derivedLotState: s } = lot
  return s.sellingToBidder && s.sellingToBidder.type === 'ArtsyBidder'
}

const isBiddable = (lot: Lot): boolean =>
  ['Open', 'OnBlock'].indexOf(lot.derivedLotState.biddingStatus) !== -1

// Complete (sold/passed) lots where artsy almost won, but didn't.
export const artsyUnderbids = lots => {
  return lots.filter(lot => {
    if (!lot.derivedLotState.isComplete) return false
    return (
      // Lot passed, but artsy was winning
      (isPassed(lot) &&
        get(lot, 'derivedLotState.floorWinningBidder.type') !==
          'ArtsyBidder') ||
      // Lot sold, but not to artsy and artsy was the underbidder
      (isSold(lot) &&
        lot.derivedLotState.sellingToBidder.type !== 'ArtsyBidder' &&
        floorUnderbid(lot).bidder.type === 'ArtsyBidder')
    )
  })
}

export const lotHammerPrice = (lot: Lot): Cents =>
  (isSold(lot) && lot.derivedLotState.sellingPriceCents) || 0

// bid event for the lot's underbid (or 'NoBidder' sentinel event)
// this does not check who is the winning bid or the sold/passed state of the lot.
export const floorUnderbid = (lot: Lot): BidEvent => {
  const sentinelNoBid = {
    amountCents: 0,
    bidder: { type: 'NoBidder' },
  } as BidEvent
  let underBid
  const {
    derivedLotState: { floorAskingPriceCents, floorWinningBidEventId },
    eventHistory,
  } = lot
  const allBids = bidEvents(eventHistory)
  if (isPassed(lot)) {
    underBid =
      allBids.find(e => e.eventId === floorWinningBidEventId) || sentinelNoBid
  } else {
    underBid = allBids
      .filter(event => !isCancelled(event))
      .filter(
        event =>
          event.eventId !== floorWinningBidEventId ||
          event.amountCents < floorAskingPriceCents
      )
      .reduce(
        (prev, current) =>
          current.amountCents >= prev.amountCents ? current : prev,
        sentinelNoBid
      )
  }
  return underBid
}

// TODO: lot.eventHistory is commented as 'deprecated, waiting for clients to drop' in
// should we be using events/fullEventOrderInstead? Are these equal?
// const lotEventHistory: (l: Lot) => Event[] = lot =>
//   lot.fullEventHistory.map(id => lot.events[id])

// get participation on a lot, grouped by bidder
export const groupEventsByArtsyBidder = events => {
  const artsyBidderEvents = bidEvents(events).filter(eb =>
    isArtsyBidder(eb.bidder)
  )
  return groupBy(artsyBidderEvents, e => e.bidder.bidderId)
}

export const bidEvents = (events: CausalityEvent[]): BidEvent[] =>
  events.map(asBidEvent).filter(e => e)

export const groupEventsByLot = events => {
  return groupBy(events, e => e.lotId)
}

export const artsyBidderParticipationForLot = lot =>
  groupEventsByArtsyBidder(lot.eventHistory)

export const artsyBidderParticipationForLots = lots =>
  groupEventsByArtsyBidder(flatMap(lots, l => l.eventHistory))

const isCancelled = (e: CausalityEvent): boolean => e.cancelled

export const asBidEvent = (e: CausalityEvent): BidEvent =>
  e.type in BidEventType ? ((e as unknown) as BidEvent) : null

// look for a bidder
export const eventBidder = (event: CausalityEvent): Bidder => {
  const e = asBidEvent(event)
  return e ? e.bidder : null
}

// is the bidder an Artsy Bidder?
export const isArtsyBidder = (bidder: Bidder): boolean => {
  return bidder.type === 'ArtsyBidder'
}

/**
 * [interestOrOutcome description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const interestOrOutcome = lot => {
  const {
    derivedLotState: {
      bidCount,
      biddingStatus,
      estimate,
      floorSellingPriceCents,
      floorWinningBidEventId,
      floorWinningBidder,
      maxBidAmountCents,
      sellingPriceCents,
      soldStatus,
      winningBidder,
    },
  } = lot

  let lowEstimateCents = estimate.lowEstimateCents || estimate.amountCents

  let interestOrOutcome = { abbrev: '', detail: '' }

  if (isBiddable(lot)) {
    if (maxBidAmountCents && maxBidAmountCents > floorSellingPriceCents) {
      const percentage = `${Math.floor(
        (maxBidAmountCents / lowEstimateCents) * 100
      )}%`
      interestOrOutcome = {
        abbrev: percentage,
        detail: `Artsy max bid at ${percentage} of low estimate from ${winningBidder.paddleNumber}`,
      }
    } else if (bidCount > 0 && sellingPriceCents > floorSellingPriceCents) {
      const percentage = `${Math.floor(
        (sellingPriceCents / lowEstimateCents) * 100
      )}%`
      interestOrOutcome = {
        abbrev: percentage,
        detail: `Artsy bid at ${percentage} of low estimate from ${winningBidder.paddleNumber}`,
      }
    } else {
      interestOrOutcome = {
        abbrev: '',
        detail: 'No Artsy activity ahead of room',
      }
    }
  } else if (biddingStatus === 'Complete') {
    if (floorWinningBidder && floorWinningBidder.type === 'ArtsyBidder') {
      if (soldStatus === 'Sold') {
        interestOrOutcome = {
          abbrev: 'Win',
          detail: `Win: Sold to Artsy bidder ${floorWinningBidder.paddleNumber}`,
        }
      } else if (soldStatus === 'Passed') {
        interestOrOutcome = {
          abbrev: 'Pass',
          detail: `Passed: Passed with Artsy bidder ${floorWinningBidder.paddleNumber} as high bidder`,
        }
      }
    } else if (floorWinningBidEventId) {
      const underbid = floorUnderbid(lot)

      if (underbid.bidder.type === 'ArtsyBidder') {
        interestOrOutcome = {
          abbrev: 'DUB',
          detail: `Direct underbid: Artsy direct underbid ${formatMoneyWithoutCents(
            underbid.amountCents / 100
          )}`,
        }
      } else {
        interestOrOutcome = {
          abbrev: 'Pass',
          detail: `Lot ended ${soldStatus} with no Artsy winner or direct underbid`,
        }
      }
    }
  }

  return interestOrOutcome
}
