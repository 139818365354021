import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import LotsHeaderBar from './LotsHeaderBar'
import MonitorLotList from './MonitorLotList'
import MonitorEventList from './MonitorEventList'
import { MonitorBidderView } from './MonitorBidderView'

export default function MonitorDetail(_, { monitorMode }) {
  switch (monitorMode) {
    case 'bids':
      return <MonitorBidderView />
    case 'lots':
    default:
      return <LotDetail />
  }
}

MonitorDetail.contextTypes = {
  monitorMode: PropTypes.string.isRequired,
}

// from the original layout, wrapped in a fragment
const LotDetail = () => (
  <>
    <Lots>
      <LotsHeader>
        <LotsHeaderBar />
      </LotsHeader>
      <LotsBody>
        <MonitorLotList />
      </LotsBody>
    </Lots>
    <AllEvents>
      <MonitorEventList />
    </AllEvents>
  </>
)

const AllEvents = styled.aside`
  grid-column: 2;
  overflow: auto;
  grid-row: 2 / span 2;
`

const LotsHeader = styled.div`
  grid-row: 1;
`

const LotsBody = styled.div`
  grid-row: 2;
`
export const Lots = styled.main`
  grid-column: 1;
  grid-row: 2 / span 2;
  overflow-y: scroll;

  display: grid;
  grid-template-rows: 100px auto;
  overflow-x: hidden;
`
