import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { groupEventsByLot } from 'utils/lotHelper'
import { formatMoneyWithoutCents } from 'utils/formatHelper'

export const MonitorBidderView = () => {
  return (
    <Container>
      <BidderParticipationDetail />
    </Container>
  )
}

const BidderParticipationDetail = (
  _,
  { artsyBidderParticipationForSale, lotById }
) => {
  const participation = artsyBidderParticipationForSale

  return (
    <div>
      {Object.keys(participation).map(bidderId => {
        const events = participation[bidderId]
        const eventsByLotId = groupEventsByLot(events)
        const bidder = events[0].bidder

        return (
          <div key={bidderId}>
            <BidderInfo>
              Bidder #{bidder.paddleNumber} : {events.length} bids
            </BidderInfo>
            {Object.keys(eventsByLotId).map(lotId => {
              const lot = lotById(lotId)
              const rawEvents = eventsByLotId[lotId]
              const finalEvents = rawEvents
                .filter(e => !e.cancelled)
                .sort(e => e.amountCents)
              return (
                <div key={lotId}>
                  <LotInfo>LOT {lot.lotId}</LotInfo>
                  {finalEvents.map((e, i) => {
                    return (
                      <BidderEvent
                        isLatest={i === finalEvents.length - 1}
                        key={e.eventId}
                      >
                        {e.type} {formatMoneyWithoutCents(e.amountCents)}
                      </BidderEvent>
                    )
                  })}
                  <br />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

BidderParticipationDetail.contextTypes = {
  artsyBidderParticipationForSale: PropTypes.object.isRequired,
  lotById: PropTypes.func.isRequired,
}

const Container = styled.div`
  grid-column: 1;
  grid-row: 2 / span 2;
  overflow-y: scroll;
`
const BidderInfo = styled.div`
  font-size: 13px;
  line-height: 1.33em;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 15px 0;
  ${({ theme }) => theme.fontFamily.avant_garde_gothic};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray777};
  display: inline-block;
`

const LotInfo = styled.div`
  font-size: 13px;
  line-height: 1.33em;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
  margin: 5px 0;
  ${({ theme }) => theme.fontFamily.avant_garde_gothic};
`

const BidderEvent = styled.div<{ isLatest: boolean }>`
  ${({ theme }) => theme.fontFamily.garamond};
  font-size: 16px;
  color: ${p => (p.isLatest ? p.theme.colors.black : p.theme.colors.gray666)};
  padding: 2px 0;
  line-height: 1.2em;
`
