import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { HistoryItem } from './HistoryItem'

export const History = (_, context) => {
  const { currentLot, filteredLotEvents } = context

  const lotEvents = filteredLotEvents(currentLot)

  return (
    <Container>
      {lotEvents.map(lotEvent => (
        <HistoryItem key={lotEvent.eventId} lotEvent={lotEvent} />
      ))}
    </Container>
  )
}

const Container = styled.ul`
  ${({ theme }) => {
    return `
      color: ${theme.colors.white100};
      margin-top: ${theme.margins.large};
      font-size: ${theme.fontSizes.small};
      width: 300px;
      height: 200px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
       display: none;
      }
    `
  }};
`

History.contextTypes = {
  currentLot: PropTypes.object.isRequired,
  filteredLotEvents: PropTypes.func.isRequired,
}
