import PropTypes from 'prop-types'
import React, { Component } from 'react'
import block from 'bem-cn-lite'
import './BuyersPremium.styl'

const displayPercentage = (percent: number) =>
  parseFloat((percent * 100).toFixed(5))

export default function BuyersPremium(_, { sale }) {
  const b = block('buyers-premium')
  const buyersPremium = sale.buyersPremium
  const noBuyersPremium = !(sale && buyersPremium && buyersPremium.length)

  if (noBuyersPremium) {
    return null

    // Just 1
  } else if (buyersPremium.length === 1) {
    const [{ percent }] = buyersPremium

    return (
      <Wrapper>
        <div className={b('item')}>
          Buyer's premium for this sale is {displayPercentage(percent)}% of the
          hammer
        </div>
      </Wrapper>
    )

    // More than 1
  } else if (buyersPremium.length > 1) {
    return (
      <Wrapper>
        {buyersPremium.map((premium, idx) => {
          if (idx === 0) {
            return (
              <div className={b('item')} key={idx}>
                On the hammer price up to and including{' '}
                {buyersPremium[idx + 1].amount}: &nbsp;
                <strong>
                  {displayPercentage(buyersPremium[idx].percent)}%
                </strong>
              </div>
            )
          } else if (idx === buyersPremium.length - 1) {
            return (
              <div className={b('item')} key={idx}>
                On the portion of the hammer price in excess of {premium.amount}
                :&nbsp;
                <strong>{displayPercentage(premium.percent)}%</strong>
              </div>
            )
          } else {
            return (
              <div className={b('item')} key={idx}>
                On the hammer price in excess of {buyersPremium[idx].amount} up
                to and including {buyersPremium[idx + 1].amount}: &nbsp;
                <strong>
                  {displayPercentage(buyersPremium[idx].percent)}%
                </strong>
              </div>
            )
          }
        })}
      </Wrapper>
    )
  }
}

BuyersPremium.contextTypes = {
  sale: PropTypes.object.isRequired,
}

export class Wrapper extends Component {
  static contextTypes = {
    deviceMode: PropTypes.string,
    isTabletOrMobile: PropTypes.bool,
  }

  state = {
    isOpen: false,
  }

  handleToggle = () => {
    if (this.context.isTabletOrMobile) {
      const isOpen = !this.state.isOpen

      this.setState({
        isOpen,
      })
    }
  }

  render() {
    const { children } = this.props
    const { isOpen } = this.state
    const { deviceMode, isTabletOrMobile } = this.context
    const b = block('buyers-premium')
    let tooltipStyle = { display: '' } // desktop

    if (isTabletOrMobile) {
      tooltipStyle.display = isOpen ? 'block' : 'none'
    }

    return (
      <div className={b({ 'device-mode': deviceMode })}>
        This work has a&nbsp;
        <strong onClick={this.handleToggle}>Buyer’s Premium</strong>
        <div className={b('modal')} style={tooltipStyle}>
          {children}
        </div>
      </div>
    )
  }
}
