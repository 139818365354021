import PropTypes from 'prop-types'
import React from 'react'
import MonitorLotListItem from './MonitorLotListItem'
import './MonitorLotList.styl'
import { Autoscroll } from 'components/Autoscroll'

export default function MonitorLotList(_, { currentLot, lotById, sale }) {
  return (
    <Autoscroll
      focusIndex={sale.saleArtworks.findIndex(
        saleArtwork => saleArtwork.internalID === currentLot.id
      )}
      nodeSelector={(rootNode, index) => rootNode.children[index]}
      scrollIntoViewOptions={{
        behavior: 'smooth',
        block: 'center',
      }}
      updateScroll={Autoscroll.FOCUS_CHANGE}
    >
      {sale.saleArtworks.map(saleArtwork => {
        const isPast =
          lotById(saleArtwork.internalID).derivedLotState.biddingStatus ===
          'Complete'
        return (
          <MonitorLotListItem
            key={saleArtwork.internalID}
            sale={sale}
            saleArtwork={saleArtwork}
            past={isPast}
          />
        )
      })}
    </Autoscroll>
  )
}

MonitorLotList.contextTypes = {
  currentLot: PropTypes.object.isRequired,
  lotById: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
}
