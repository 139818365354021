import createLogger from 'utils/logger'
import keyMirror from 'utils/keyMirror'
import { keys } from 'lodash'

const logger = createLogger('utils/environment.js')

export const NODE_ENV = process.env.NODE_ENV || 'development'
export const PORT = process.env.PORT

/**
 * An enum of all possible execution environments
 */
const validNodeEnvs = keyMirror('development', 'staging', 'production', 'test')

/**
 * Ensure that application boots with a valid NODE_ENV
 */
const invalid = !keys(validNodeEnvs).includes(NODE_ENV)

if (invalid) {
  throw new Error(
    `(utils/environment.js) NODE_ENV value of ${NODE_ENV} is not allowed. Must ` +
      `be one of ${keys(validNodeEnvs).join(', ')}.`
  )
}

/**
 * Client represents a browser window and guards against various
 * isomorphic conditions.
 */
export const isClient = typeof window !== 'undefined'
export const isDevelopment = NODE_ENV === validNodeEnvs.development
export const isStaging = NODE_ENV === validNodeEnvs.staging
export const isProduction = NODE_ENV === validNodeEnvs.production
export const isTest = NODE_ENV === validNodeEnvs.test
export const isServer = !isClient
export const isDeploy = isStaging || isProduction

if (!isTest) {
  logger.log(`[Prediction] NODE_ENV=${NODE_ENV}`)
}
