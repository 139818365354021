import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getContext } from 'recompose'
import { Autofocus } from 'components/Autofocus'
import { ButtonBase } from 'apps/operator/components/Utils/ButtonBase'
import { addCommaToInput, addCommaToNumber } from 'utils/inputBoxUtils'

export const IncrementSelection = getContext({
  currentLot: PropTypes.object.isRequired,
  formatMoneyWithoutCents: PropTypes.func.isRequired,
  isUpdatingIncrement: PropTypes.bool.isRequired,
  returnToIncrementPolicy: PropTypes.func.isRequired,
  set258Increments: PropTypes.func.isRequired,
  setConstantIncrement: PropTypes.func.isRequired,
  startEditingIncrement: PropTypes.func.isRequired,
  stopEditingIncrement: PropTypes.func.isRequired,
})(
  ({
    currentLot,
    formatMoneyWithoutCents,
    isUpdatingIncrement,
    returnToIncrementPolicy,
    set258Increments,
    setConstantIncrement,
    startEditingIncrement,
    stopEditingIncrement,
  }) => {
    const {
      currentIncrementCents,
      temporaryIncrements,
    } = currentLot.derivedLotState

    let numInput

    const displayValue = addCommaToNumber(
      formatMoneyWithoutCents(currentIncrementCents / 100),
      '+'
    )

    return (
      <Container>
        <InputPane onClick={() => startEditingIncrement()}>
          <Label>Increment</Label>
          {isUpdatingIncrement ? (
            <Autofocus>
              <Input
                defaultValue={displayValue}
                ref={el => {
                  numInput = el
                }}
                onFocus={e => e.target.select()}
                onChange={e => {
                  addCommaToInput(e, '+')
                }}
              />
            </Autofocus>
          ) : (
            <LiveView>{displayValue}</LiveView>
          )}
        </InputPane>

        {isUpdatingIncrement ? (
          <ActionButtons>
            <ActionButton
              color="white100"
              onClick={() => stopEditingIncrement()}
            >
              Cancel
            </ActionButton>
            <ActionButton
              color="purple100"
              onClick={() =>
                setConstantIncrement(
                  parseInt(numInput.value.replace(/\D/g, ''), 10) * 100
                )
              }
            >
              Ok
            </ActionButton>
          </ActionButtons>
        ) : (
          <SelectionButtons>
            <SelectionButton
              onClick={() => returnToIncrementPolicy()}
              selected={!temporaryIncrements}
            >
              Default
            </SelectionButton>
            <SelectionButton
              onClick={() => set258Increments()}
              selected={
                temporaryIncrements &&
                temporaryIncrements.type === 'PatternIncrement'
              }
            >
              2-5-8
            </SelectionButton>
            <SelectionButton
              enabledWhenSelected={true}
              onClick={() => startEditingIncrement()}
              selected={
                temporaryIncrements &&
                temporaryIncrements.type === 'ConstantIncrement'
              }
            >
              Manual
            </SelectionButton>
          </SelectionButtons>
        )}
      </Container>
    )
  }
)

const ActionButtons = styled.div`
  ${p => p.theme.utils.flexEnd};
  width: 100%;
  &:button:nth-of-type(1) {
    margin-left: auto;
  }
}
`

const ActionButton = styled(ButtonBase)`
  background: ${p => p.theme.colors[p.color]};
  width: 96px;
  height: 38px;
  justify-content: center;
  margin: 1px;
  border: ${p =>
    p.theme.utils.border({
      color: p.color === 'white100' ? p.theme.colors.black10 : 'transparent',
    })};
  color: ${p =>
    p.theme.colors[p.color === 'white100' ? 'black80' : 'white100']};

  &:hover {
    border: ${p => p.theme.utils.border({ color: p.theme.colors.black100 })};
  }
`

const Container = styled.div`
  border: ${p => p.theme.utils.border({ color: p.theme.colors.black100 })};
  font-size: ${p => p.theme.fontSizes.small};
  margin-top: ${p => p.theme.margins.large};
  background: ${p => p.theme.colors.white100};
  width: 300px;
  height: 88px;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px;

  &:hover {
    color: ${p => p.theme.colors.purple100};
    border-color: ${p => p.theme.colors.purple100};
  }
`

const LiveView = styled.div``

const Input = styled.input`
  height: ${p => p.theme.fontSizes.small};
  width: 100%;
  border: none;
  padding: 0;
  font-size: ${p => p.theme.fontSizes.small};
  text-align: right;
  &:hover {
    color: ${p => p.theme.colors.purple100};
  }
  &:focus {
    color: ${p => p.theme.colors.black100};
  }
`

const InputPane = styled.div`
  padding: ${p => p.theme.margins.medium};
  ${p => p.theme.utils.stretch};
`
const Label = styled.div``

const SelectionButtons = styled.div`
  ${p => p.theme.utils.stretch};
`

export const SelectionButton = styled(ButtonBase)<{
  selected?: boolean
  enabledWhenSelected?: boolean
}>`
  background-color: ${p => p.theme.colors[p.selected ? 'black100' : 'black10']};
  color: ${p => p.theme.colors[p.selected ? 'white100' : 'black60']};
  justify-content: center;
  width: 96px;
  height: 38px;
  pointer-events: ${p =>
    p.selected && !p.enabledWhenSelected ? 'none' : null};

  &:hover {
    border: ${p =>
      p.theme.utils.border({
        color: p.theme.colors.black100,
      })};
  }
`
