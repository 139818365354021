import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getContext, withState } from 'recompose'
import { Autofocus } from 'components/Autofocus'
import { Autoscroll } from 'components/Autoscroll'
import { Modal, ActionButtons } from './Modal'
import { ActionButton } from 'apps/operator/components/Utils/ButtonBase'
import { RoundArtworkImage } from 'apps/operator/components/Utils/Common'
import { PauseEndButtons } from './PauseEndButtons'

export const ChangeLotModal = getContext({
  changeLotFromModal: PropTypes.func.isRequired,
  closeChangeLotModal: PropTypes.func.isRequired,
  currentLot: PropTypes.object.isRequired,
  currentSaleArtwork: PropTypes.object.isRequired,
  operatorChangeSelectedLotId: PropTypes.string.isRequired,
  saleArtworkLists: PropTypes.object.isRequired,
})(
  withState(
    'displayItemList',
    'setDisplayItemList',
    ({ saleArtworkLists }) => saleArtworkLists.skippedLast
  )(
    ({
      changeLotFromModal,
      closeChangeLotModal,
      currentLot,
      displayItemList,
      operatorChangeSelectedLotId,
      saleArtworkLists,
      setDisplayItemList,
    }) => {
      let searchInput

      const filterList = () => {
        const text = searchInput.value
        const fullSaleArtworkList = saleArtworkLists.skippedLast
        const newDisplayItemList =
          text && text.length
            ? fullSaleArtworkList.filter(saleArtwork => {
                const name = displayLotName(saleArtwork).toLowerCase()
                return name.search(text.toLowerCase()) !== -1
              })
            : fullSaleArtworkList
        setDisplayItemList(newDisplayItemList)
      }

      return (
        <Modal
          renderOutside={() => {
            return <PauseEndButtons />
          }}
        >
          <SearchBar>
            <Autofocus>
              <SearchInputField
                type="text"
                placeholder="Change lot..."
                ref={el => (searchInput = el)}
                onChange={() => filterList()}
              />
            </Autofocus>
          </SearchBar>
          <ChangeLotModalList itemList={displayItemList} />
          <ActionButtons>
            <ActionButton
              color="white100"
              hoverBackground="black"
              onClick={() => closeChangeLotModal()}
            >
              Cancel
            </ActionButton>
            <ActionButton
              disabled={operatorChangeSelectedLotId === currentLot.id}
              color="purple100"
              onClick={() => {
                changeLotFromModal(operatorChangeSelectedLotId)
              }}
            >
              OK
            </ActionButton>
          </ActionButtons>
        </Modal>
      )
    }
  )
)

const ChangeLotModalList = getContext({
  currentLot: PropTypes.object.isRequired,
  lotForSaleArtwork: PropTypes.func.isRequired,
  onSelectChangeLot: PropTypes.func.isRequired,
  operatorChangeSelectedLotId: PropTypes.string.isRequired,
})(
  ({
    currentLot,
    itemList,
    lotForSaleArtwork,
    onSelectChangeLot,
    operatorChangeSelectedLotId,
  }) => {
    const initialSelectedIndex =
      operatorChangeSelectedLotId !== -1
        ? // User is currently interacting with list
          operatorChangeSelectedLotId
        : // Modal just opened, use the current lot id and scroll
          currentLot.id

    const selectedIndex = itemList.findIndex(
      sa => sa.internalID === initialSelectedIndex
    )
    return (
      <Autoscroll
        focusIndex={selectedIndex}
        scrollIntoViewOptions={{
          block: 'center',
        }}
      >
        <ScrollingLotList>
          {itemList.map((saleArtwork, index) => {
            const lot = lotForSaleArtwork(saleArtwork)
            const isSelected = index === selectedIndex
            let status = 'upcoming'
            if (lot === currentLot) {
              status = 'current'
            } else if (lot.derivedLotState.biddingStatus === 'Complete') {
              status = lot.derivedLotState.soldStatus.toLowerCase()
            }
            const imageSrc = isSelected
              ? '/img/lot-button-selected.svg'
              : saleArtwork.artwork.image?.url

            return (
              <ScrollingLotListItem
                isSelected={isSelected}
                key={saleArtwork.internalID}
                onClick={() => onSelectChangeLot(saleArtwork.internalID)}
              >
                <LotDetails>
                  <LotStatus status={status}>{status}</LotStatus>
                  <div>{displayLotName(saleArtwork)}</div>
                </LotDetails>
                <RoundArtworkImage src={imageSrc} />
              </ScrollingLotListItem>
            )
          })}
        </ScrollingLotList>
      </Autoscroll>
    )
  }
)

const displayLotName = lot =>
  `Lot ${lot.lotLabel}: ${(lot.artwork.artist && lot.artwork.artistNames) ||
    'N/A'}`

const SearchBar = styled.div`
  border-bottom: 2px solid ${p => p.theme.colors.black100};
  background: url(/img/search-icon.svg) ${p => p.theme.margins.large}
    ${p => p.theme.margins.large} no-repeat;
  padding-left: 40px;
`

const SearchInputField = styled.input`
  ${({ theme }) => {
    const margin = theme.margins.large

    return `
      width: 100%;
      border: 0px;
      padding: calc(${margin} + 2px) ${theme.margins.small};
      font-size: ${theme.fontSizes.small};
      &:focus {
        border: 0 !important;
      }
    `
  }};
`

const ScrollingLotList = styled.ul`
  width: 470px;
  height: 295px;
  padding: ${p => p.theme.margins.large};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
`

const ScrollingLotListItem = styled.li<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => {
    return `
      width: 100%;
      height: calc(${theme.margins.large} * 3);
      padding: ${theme.margins.large} ${theme.margins.small};
      border: ${theme.utils.border({
        size: 2,
        color: isSelected ? theme.colors.purple100 : theme.colors.black10,
      })};
      font-size: ${theme.fontSizes.small};
      margin-bottom: ${theme.margins.large};
      cursor: pointer;

      ${theme.utils.stretch};

      &:hover {
        border: ${theme.utils.border({ color: theme.colors.purple100 })};
      }

      &:last-child {
        margin-bottom: 0;
      }
    `
  }};
`

const LotDetails = styled.div`
  display: flex;
  align-items: center;
`
const LotStatus = styled.div<{ status?: string }>`
  ${({ theme, status }) => {
    const getColor = () => {
      switch (status) {
        case 'sold':
        case 'passed':
          return theme.colors.black100
        case 'current':
          return theme.colors.green100
        case 'upcoming':
          return theme.colors.yellow80
        default:
          return 'white'
      }
    }

    return `
      background: ${getColor()};
      color: ${theme.colors.white100};
      margin-right: ${theme.margins.small};
      font-size: ${theme.fontSizes.xsmall};
      width: calc(${theme.margins.large} * 3);
      height: 18px;
      ${theme.utils.center};
    `
  }};
`
