import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { saleArtworkForEvent } from './lotEventSelectors'
import { compactAmount } from 'utils/formatHelper'
import {
  isSellingToArtsyBidder,
  artsyBidderParticipationForLots,
  isPassed,
  isSold,
} from 'utils/lotHelper'

import {
  filter,
  first,
  flatMap,
  includes,
  round,
  startCase,
  sumBy,
  values,
} from 'lodash'

/**
 * [ohmEditLinkPath description]
 * @return {[type]} [description]
 */
export const ohmEditLinkPath = () => (lot, sale) => {
  return '/sales/' + sale.slug + '/artworks/' + lot.slug + '/edit'
}

ohmEditLinkPath.propType = PropTypes.func.isRequired

/**
 * [cmsEditLinkPath description]
 * @return {[type]} [description]
 */
export const cmsEditLinkPath = () => lot => {
  return '/artworks/artwork/' + lot.artwork.id
}

cmsEditLinkPath.propType = PropTypes.func.isRequired

/**
 * [auctionEditLinkPath description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const auctionEditLinkPath = state => () => {
  return '/sales/' + state.sale.slug
}

auctionEditLinkPath.propType = PropTypes.func.isRequired

/**
 * The selected lot in the monitor interface
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const monitorSelectedLot = state => {
  const lot = state.lots[state.monitorSelectedLotId]
  return lot
}

monitorSelectedLot.propType = PropTypes.object

export const monitorMode = state => state.monitorMode

monitorMode.propType = PropTypes.string

/**
 * [gmvAmountDisplay description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const gmvAmountDisplay = state => () => {
  const {
    saleStatistics: { totalSoldGMVCents },
  } = state

  const formatted = compactAmount(totalSoldGMVCents / 100)
  return formatted
}

gmvAmountDisplay.propType = PropTypes.func.isRequired

/**
 * [percentageByEstimate description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const percentageByEstimate = state => () => {
  const {
    sale: { saleArtworks },
    saleStatistics: { onBlockSellingPriceCents },
  } = state

  const estimateTotal = sumBy(saleArtworks, saleArtwork => {
    const estimate = saleArtwork.lowEstimate || saleArtwork.estimate || {}
    return estimate.cents || 0
  })

  const percentage = round((onBlockSellingPriceCents / estimateTotal) * 100)
  return percentage + '%'
}

percentageByEstimate.propType = PropTypes.func.isRequired

/**
 * [reserveMetLots description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
const reserveMetLots = state => {
  return filter(values(state.lots), lot => {
    return lot.derivedLotState.reserveStatus !== 'ReserveNotMet'
  })
}

export const passedOrSoldLots = state => {
  return filter(values(state.lots), l => isPassed(l) || isSold(l))
}
passedOrSoldLots.propType = PropTypes.array.isRequired

export const artsyWinningLots = state =>
  filter(values(state.lots), isSellingToArtsyBidder)
artsyWinningLots.propType = PropTypes.array.isRequired

export const artsyLosingLots = state => {
  return filter(values(state.lots), l => !isSellingToArtsyBidder(l))
}
artsyLosingLots.propType = PropTypes.array.isRequired

export const artsyWonLots = state =>
  filter(artsyWinningLots(state), l => l.derivedLotState.soldStatus === 'Sold')

artsyWonLots.propType = PropTypes.array.isRequired

export const artsyBidderParticipationForSale = state =>
  artsyBidderParticipationForLots(state.lots)
artsyBidderParticipationForSale.propType = PropTypes.object.isRequired

/**
 * [reserveSales description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const reserveSales = state => () => {
  const total = sumBy(reserveMetLots(state), lot => {
    return lot.derivedLotState.floorSellingPriceCents
  })

  const formatted = compactAmount(total / 100)
  return formatted
}

reserveSales.propType = PropTypes.func.isRequired

/**
 * [maxBidsAmountDisplay description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const maxBidsAmountDisplay = state => () => {
  const {
    saleStatistics: { maxBidAmountCents },
  } = state

  const formatted = compactAmount(maxBidAmountCents / 100)
  return formatted
}

maxBidsAmountDisplay.propType = PropTypes.func.isRequired

/**
 * [reserveCount description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const reserveCount = state => () => {
  const lotCount = state.sale.saleArtworks.length
  const reserveMetCount = reserveMetLots(state).length
  const percentage = round((reserveMetCount / lotCount) * 100, 1)

  const formatted = `${reserveMetCount} / ${lotCount} (${percentage}%)`
  return formatted
}

reserveCount.propType = PropTypes.func.isRequired

/**
 * [bidEvents description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
const bidEvents = state => {
  return filter(
    flatMap(state.lots, lot => {
      return lot.eventHistory
    }),
    event => {
      return includes(
        ['FirstPriceBidPlaced', 'SecondPriceBidPlaced'],
        event.type
      )
    }
  )
}

/**
 * [causalityBidCount description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const causalityBidCount = state => () => {
  return bidEvents(state).length
}

causalityBidCount.propType = PropTypes.func.isRequired

/**
 * [onlineBidCount description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const onlineBidCount = state => () => {
  return filter(bidEvents(state), event => {
    return event.bidder.type === 'ArtsyBidder'
  }).length
}

onlineBidCount.propType = PropTypes.func.isRequired

/**
 * [bidderDetails description]
 * @param  {[type]} event [description]
 * @return {[type]}       [description]
 */
const bidderDetails = event => {
  if (event.bidder) {
    if (event.bidder.type === 'OfflineBidder') {
      return '(Floor Bidder)'
    } else if (event.bidder.type === 'ArtsyBidder') {
      return '(' + event.bidder.paddleNumber + ')'
    }
  } else {
    return ''
  }
}

/**
 * [eventTitle description]
 * @return {[type]} [description]
 */
export const eventTitle = () => event => {
  if (event.type === 'FirstPriceBidPlaced') {
    return 'Bid Placed ' + bidderDetails(event)
  } else if (event.type === 'SecondPriceBidPlaced') {
    return 'Max Bid Placed ' + bidderDetails(event)
  } else if (event.type === 'CompositeOnlineBidConfirmed') {
    return 'Online Bid Confirmed'
  }
  return startCase(event.type)
}

eventTitle.propType = PropTypes.func.isRequired

/**
 * [eventSubtitle description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const eventSubtitle = state => event => {
  const saleArtwork = saleArtworkForEvent(state)(event)

  if (saleArtwork) {
    const formatted = compactAmount(event.amountCents / 100, saleArtwork.symbol)
    const amountAddendum = event.amountCents ? `, ${formatted}` : ''
    return `Lot ${saleArtwork.lotLabel}${amountAddendum}`
  } else {
    return null
  }
}

eventSubtitle.propType = PropTypes.func.isRequired

/**
 * [numberOfBidsDisplay description]
 * @return {[type]} [description]
 */
export const numberOfBidsDisplay = () => lot => {
  const bids = lot.derivedLotState.bidCount
  const pluralized = pluralize('bid', bids)
  const formatted = startCase(lot.derivedLotState.reserveStatus)

  const out = `${bids} ${pluralized} (${formatted})`
  return out
}

numberOfBidsDisplay.propType = PropTypes.func.isRequired

/**
 * [maxBidDisplay description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const maxBidDisplay = () => lot => {
  const {
    derivedLotState: { maxBidAmountCents },
  } = lot

  if (maxBidAmountCents) {
    const formatted = compactAmount(maxBidAmountCents / 100)
    return formatted
  } else {
    return null
  }
}

maxBidDisplay.propType = PropTypes.func.isRequired

/**
 * [topBidEvent description]
 * @return {[type]} [description]
 */
export const topBidEvent = _state => lot => {
  const allLotEvents = lot.eventHistory
    ? lot.eventHistory.slice().reverse()
    : []

  return first(
    filter(allLotEvents, event => {
      return includes(['FirstPriceBidPlaced'], event.type)
    })
  )
}

topBidEvent.propType = PropTypes.func.isRequired

/**
 * [highBidDisplay description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const highBidDisplay = state => lot => {
  const topBid = topBidEvent(state)(lot)

  if (topBid) {
    const {
      amountCents,
      bidder: { paddleNumber, type },
    } = topBid

    const maxBid = compactAmount(amountCents / 100)

    if (type === 'OfflineBidder') {
      return `${maxBid} (Offline Bidder)`
    }

    return `${maxBid} (Paddle #${paddleNumber})`
  }
  return null
}

highBidDisplay.propType = PropTypes.func.isRequired

/**
 * [artsyMinDisplay description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const artsyMinDisplay = state => lot => {
  const {
    derivedLotState: {
      bidCount,
      biddingStatus,
      sellingPriceCents,
      floorSellingPriceCents,
    },
  } = lot

  let minAmountCents
  if (biddingStatus === 'Open' && bidCount > 0) {
    minAmountCents = sellingPriceCents
  } else if (
    biddingStatus === 'OnBlock' &&
    sellingPriceCents > floorSellingPriceCents
  ) {
    minAmountCents = sellingPriceCents
  }

  if (minAmountCents) {
    const formatted = compactAmount(sellingPriceCents / 100)
    return formatted
  } else {
    return ''
  }
}
artsyMinDisplay.propType = PropTypes.func.isRequired

/**
 * [artsyMaxDisplay description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const artsyMaxDisplay = state => lot => {
  const {
    derivedLotState: { maxBidAmountCents },
  } = lot

  if (maxBidAmountCents) {
    const formatted = compactAmount(maxBidAmountCents / 100)
    return formatted
  } else {
    return ''
  }
}
artsyMaxDisplay.propType = PropTypes.func.isRequired

/**
 * [askingPriceDisplay description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const askingPriceDisplay = state => lot => {
  const {
    derivedLotState: { floorAskingPriceCents },
  } = lot

  if (floorAskingPriceCents) {
    const formatted = compactAmount(floorAskingPriceCents / 100)
    return formatted
  } else {
    return ''
  }
}
askingPriceDisplay.propType = PropTypes.func.isRequired

/**
 * [lowEstimateDisplay description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const lowEstimateDisplay = state => lot => {
  const {
    derivedLotState: { estimate },
  } = lot

  let lowEstimateCents
  if (estimate.lowEstimateCents) {
    lowEstimateCents = estimate.lowEstimateCents
  } else if (estimate.amountCents) {
    lowEstimateCents = estimate.amountCents
  }

  if (lowEstimateCents) {
    const formatted = compactAmount(lowEstimateCents / 100)
    return formatted
  } else {
    return ''
  }
}
lowEstimateDisplay.propType = PropTypes.func.isRequired

/**
 * [sellingPriceDisplay description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const sellingPriceDisplay = state => lot => {
  const {
    derivedLotState: { floorSellingPriceCents },
  } = lot

  if (floorSellingPriceCents) {
    const formatted = compactAmount(floorSellingPriceCents / 100)
    return formatted
  } else {
    return ''
  }
}
sellingPriceDisplay.propType = PropTypes.func.isRequired
