import * as bidderSelectors from 'selectors/bidderSelectors'
import * as lotSelectors from 'selectors/lotSelectors'
import * as lotEventSelectors from 'selectors/lotEventSelectors'
import * as monitorSelectors from 'selectors/monitorSelectors'
import * as saleSelectors from 'selectors/saleSelectors'
import * as operatorUISelectors from 'selectors/operatorUISelectors'
import * as uiSelectors from 'selectors/uiSelectors'

export const selectorBundles = [
  bidderSelectors,
  lotSelectors,
  lotEventSelectors,
  monitorSelectors,
  saleSelectors,
  uiSelectors,
]

export const operatorSelectorBundles = selectorBundles.concat([
  operatorUISelectors,
])
