import AppContainer from 'components/AppContainer'
import makeBidderRoutes from 'apps/bidder'
import MonitorRoute from 'apps/monitor'
import OperatorRoutes from 'apps/operator'
import SaleListRoute from 'apps/sales'

export function makeRoutes(store) {
  return [
    {
      path: '/',
      component: AppContainer,
      indexRoute: SaleListRoute,

      childRoutes: [MonitorRoute, ...OperatorRoutes, makeBidderRoutes(store)],
    },
  ]
}
