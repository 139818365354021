import Icon from 'assets/icons'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import block from 'bem-cn-lite'
import { Link } from 'react-router'
import { isEqual, isFunction } from 'lodash'
import { Box } from '@artsy/palette'

import './ArtworkListItem.styl'
import { trackSelectLot } from 'utils/analytics'
import { ArtworkThumbnail } from 'apps/bidder/components/ArtworkThumbnail'

interface ArtworkListItemProps {
  lot: any
  past?: boolean
  prevTabScrollEnabled?: boolean
}

export default class ArtworkListItem extends Component<ArtworkListItemProps> {
  static propTypes = {
    lot: PropTypes.object.isRequired,
  }

  static contextTypes = {
    currentSaleArtwork: PropTypes.object.isRequired,
    deviceMode: PropTypes.string.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    isTabletOrMobile: PropTypes.bool.isRequired,
    isUpcomingArtworkList: PropTypes.bool.isRequired,
    sale: PropTypes.object.isRequired,
    selectedSaleArtwork: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { prevTabScrollEnabled } = this.props

    const {
      isDesktop,
      isTabletOrMobile,
      isUpcomingArtworkList,
      selectedSaleArtwork,
    } = this.context

    if (isEqual(this.props.lot.slug, selectedSaleArtwork.slug)) {
      const node = ReactDOM.findDOMNode(this) as any
      const scrollEnabled = prevTabScrollEnabled || isUpcomingArtworkList

      if (scrollEnabled && node && isFunction(node.scrollIntoView)) {
        if (isDesktop) {
          setTimeout(() => {
            node.scrollIntoView({ behavior: 'smooth' })
          }, 200)
        } else if (isTabletOrMobile) {
          node.scrollIntoView()
        }
      }
    }
  }

  render() {
    const { lot, past } = this.props

    const {
      currentSaleArtwork,
      deviceMode,
      isTabletOrMobile,
      sale,
    } = this.context

    const b = block('artwork-list-item')
    let lotModifier

    if (isTabletOrMobile) {
      if (isEqual(lot.slug, currentSaleArtwork.slug)) {
        lotModifier = <Icon name="gavel" className={b('gavel')} />
      } else if (past) {
        lotModifier = <div className={b('past-lot-label')}>Closed</div>
      }
    }

    return (
      <Link
        onClick={() => {
          trackSelectLot({ destinationPath: `/${sale.slug}/${lot.internalID}` })
        }}
        to={`/${sale.slug}/${lot.internalID}`}
        className={b({
          current: isEqual(lot.slug, currentSaleArtwork.slug),
          'device-mode': deviceMode,
        })}
        activeClassName={'artwork-list-item_selected'}
      >
        {lot.artwork.image ? (
          <ArtworkThumbnail
            mx={3}
            width={50}
            height={50}
            src={lot.artwork.image.url}
          />
        ) : (
          <Box mx={3} width={50} height={50} />
        )}

        <div className={b('caption')}>
          <div className={b('lot-number-label')}>Lot {lot.lotLabel}</div>
          {lot.artwork.artist && lot.artwork.artistNames && (
            <div className={b('lot-artist-label')}>
              {lot.artwork.artistNames}
            </div>
          )}
        </div>
        {lotModifier}
      </Link>
    )
  }
}
