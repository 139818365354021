import PropTypes from 'prop-types'
import { flatMap } from 'lodash'

export const sale = state => state.sale
sale.propType = PropTypes.object.isRequired

/**
 * [eventList description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const eventList = state => {
  return flatMap(state.lots, lot => {
    return lot.eventHistory || []
  }).sort((lhs, rhs) => {
    // rhs - lhs gives us descending order, so new events are at the top.
    const dateA: any = new Date(rhs.createdAt)
    const dateB: any = new Date(lhs.createdAt)
    return dateA - dateB
  })
}

eventList.propType = PropTypes.array.isRequired
