import PropTypes from 'prop-types'
import React from 'react'
import { data as sd } from 'sharify'
import block from 'bem-cn-lite'
import marked from 'marked'
import dateFormat from 'dateformat'
import Icon from 'assets/icons'
import RegisterButton from 'components/RegisterButton'
import './AuctionInfoModal.styl'

export default function AuctionInfoModal(_, context) {
  const { sale, deviceMode, role } = context
  const b = block('auction-info-modal')
  const startAt = new Date(sale.startAt)

  return (
    <div className={b({ 'device-mode': deviceMode })}>
      <div className={b('inner')}>
        <div className={b('name')}>{sale.name}</div>
        {role === 'observer' ? <RegisterButton /> : <hr />}
        <div
          className={b('description')}
          dangerouslySetInnerHTML={{ __html: marked(sale.description) }}
        />
        <div className={b('auction-info')}>
          <div className={b('auction-begins')}>Auction begins</div>
          {dateFormat(startAt, 'mmmm d h:MMTT Z')}
        </div>
        <a
          className={b('auctions-faq-link')}
          href={`${sd.FORCE_BASE_URL}/how-auctions-work`}
        >
          <div className={b('auctions-faq')}>
            Auctions FAQ
            <Icon name="chevronRight" className={b('chevron-right')} />
          </div>
        </a>
        <a className={b('contact-link')} href={`${sd.FORCE_BASE_URL}/contact`}>
          <div className={b('contact')}>
            Contact
            <Icon name="chevronRight" className={b('chevron-right')} />
          </div>
        </a>
      </div>
    </div>
  )
}

AuctionInfoModal.contextTypes = {
  deviceMode: PropTypes.string.isRequired,
  onToggleAuctionInfoModal: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
}
