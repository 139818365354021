import MonitorLayout from 'apps/monitor/components/MonitorLayout'
import Socket from 'utils/socket'
import { controller, getProps } from 'react-redux-controller'
import { selectorBundles } from 'selectors'
import { isServer } from 'utils/environment'
import * as appActions from 'actions/appActions'

function* initialize() {
  if (isServer) {
    return
  }

  const { causalityJWT, dispatch, role, sale } = yield getProps

  yield Socket.sharedSocketOrConnect({
    causalityJWT,
    dispatch,
    role,
    saleId: sale.internalID,
  })
}

function* onMonitorSelectLot(lotId) {
  const { dispatch } = yield getProps
  dispatch(appActions.monitorSelectLot({ lotId: lotId }))
}

function* onMonitorSelectMode(mode: 'lots' | 'bids') {
  const { dispatch } = yield getProps
  dispatch(appActions.monitorSelectMode({ mode }))
}

const monitorMethods = {
  initialize,
  onMonitorSelectLot,
  onMonitorSelectMode,
}

export const MonitorController = controller(
  MonitorLayout,
  monitorMethods,
  selectorBundles
)
