import { trackAction } from 'utils/analytics'

export default function initializeAnalytics() {
  return function analyticsMiddleware({ getState }) {
    return next => action => {
      const prevState = getState()
      const result = next(action)
      const nextState = getState()
      trackAction(prevState, nextState, action)
      return result
    }
  }
}
