import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Flex, Sans, Image, color as paletteColor, color } from '@artsy/palette'
import { Dispatch } from 'redux'

import { addCommaToInput } from 'utils/inputBoxUtils'
import {
  currentLot,
  currentSaleArtwork,
  floorAskingPrice,
  nextLot,
} from 'selectors/lotSelectors'
import { startingAskInputValue } from 'selectors/uiSelectors'
import { Autofocus } from 'components/Autofocus'
import {
  onOpenChangeCurrentLotModal,
  openCurrentLotAtStartingAsk,
  openWithFloorBid,
  skipCurrentLot,
  startingAskInputValueChanged,
  openWithArtsyBid,
} from 'apps/operator/operatorController'
import {
  ActionButton as ActionButtonBase,
  Button,
} from 'apps/operator/components/Utils/ButtonBase'
import { theme } from 'apps/operator/components/styles'
import { presentEstimate } from 'apps/operator/presenters'
import { artsyCanBid } from 'selectors/operatorUISelectors'

const parseAmount = (input: HTMLInputElement) =>
  parseInt(input.value.replace(/\D/g, ''), 10) * 100

interface StartingAskProps {
  currentLot: Lot
  currentSaleArtwork: {
    artwork: {
      image: {
        url: string
      }
    }
    lotLabel: string
  }
  dispatch: Dispatch<any>
  estimate?: EstimateRange | EstimateValue
  floorAskingPrice: Cents
  nextLot: Lot
  startingAskInputValue: string
  artsyCanBid: boolean
}

const mapStateToProps = state => ({
  currentLot: currentLot(state),
  currentSaleArtwork: currentSaleArtwork(state),
  estimate: (currentLot(state) as any).derivedLotState.estimate,
  floorAskingPrice: floorAskingPrice(state),
  nextLot: nextLot(state),
  startingAskInputValue: startingAskInputValue(state),
  artsyCanBid: artsyCanBid(state),
})

export const _StartingAsk: React.FunctionComponent<StartingAskProps> = ({
  currentLot,
  currentSaleArtwork,
  dispatch,
  estimate,
  floorAskingPrice,
  nextLot,
  startingAskInputValue,
  artsyCanBid,
}) => {
  const inputKeyPressed = event => {
    addCommaToInput(event)
    startingAskInputValueChanged({
      dispatch,
      value: event.target.value,
    })
  }

  let numInput

  return (
    <Container flexDirection="column" height="100%" p="2">
      <Flex
        mt={2}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Sans size="5t">Opening Lot {currentSaleArtwork.lotLabel}</Sans>
        <Sans size="3t" color="black60">
          {presentEstimate(estimate)}
        </Sans>
      </Flex>

      <Flex
        flexGrow={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ImgWithMax src={currentSaleArtwork.artwork.image?.url} />
      </Flex>

      <Flex mb={0.5} position="relative">
        <Label>Starting Ask</Label>
        <Autofocus>
          <Input
            ref={el => {
              numInput = el
            }}
            type="text"
            onFocus={(e: any) => e.target.select()}
            onChange={inputKeyPressed}
            value={startingAskInputValue}
          />
        </Autofocus>
      </Flex>

      <Flex mb={2}>
        <ActionButton
          onClick={() =>
            openCurrentLotAtStartingAsk({
              currentLot,
              floorAskingPrice,
              dispatch,
              amountCents: parseAmount(numInput),
            })
          }
          color="black100"
          hoverOutline={`1px solid ${color('black100')}`}
        >
          <Sans size="3">Ask</Sans>
        </ActionButton>

        <ActionButton
          onClick={() =>
            openWithFloorBid({
              amountCents: parseAmount(numInput),
              currentLot,
              dispatch,
            })
          }
          color="green100"
        >
          <Sans size="3">Floor</Sans>
        </ActionButton>

        <OpenToArtsyButton
          disabled={!artsyCanBid}
          onClick={() =>
            openWithArtsyBid({
              amountCents: parseAmount(numInput),
              currentLot,
              dispatch,
            })
          }
          color="purple100"
        >
          <Sans size="3">Artsy</Sans>
        </OpenToArtsyButton>
      </Flex>

      <Flex>
        <SmallButton onClick={() => onOpenChangeCurrentLotModal({ dispatch })}>
          Change Lot
        </SmallButton>
        <SmallButton onClick={() => skipCurrentLot({ dispatch, nextLot })}>
          Skip Lot
        </SmallButton>
      </Flex>
    </Container>
  )
}

// TODO: The <Flex /> component should accept the `bg` prop
const Container = styled(Flex)`
  background-color: ${theme.colors.white100};
`

// TODO: The <Image /> component should accept `maxWidth` and `maxHeight`
export const ImgWithMax = styled(Image)`
  max-width: 150px;
  max-height: 150px;
`

export const ActionButton = styled(ActionButtonBase)`
  height: 40px;
  width: inherit;
  flex: 1;
  margin-right: ${theme.space[0.3]};
  border: ${({ color }) =>
    theme.utils.border({ size: 2, color: paletteColor(color as any) })};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border: ${({ hoverBorder }) =>
      hoverBorder
        ? hoverBorder
        : theme.utils.border({ color: theme.colors.black100 })};
  }
`

export const OpenToArtsyButton = styled(ActionButton)`
  &:disabled {
    background: ${theme.colors.black10};
    border-color: ${theme.colors.black10};
  }
`

const Label = styled.div`
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.black100};
  left: ${theme.margins.medium};
  position: absolute;
  top: 50%;
`

export const Input = styled.input`
  width: 100%;
  border: ${theme.utils.border({ color: theme.colors.black100 })};
  margin: ${theme.margins.small} 0 0 0;
  color: ${theme.colors.black100};
  height: calc(${theme.margins.large} * 3);
  padding: 0 ${theme.margins.large};
  font-size: ${theme.fontSizes.large};
  caret-color: ${theme.colors.purple100};
  text-align: right;
`

export const SmallButton = styled(Button)`
  background: ${theme.colors.yellow80};
  font-size: ${theme.fontSizes.medium};
  height: 40px;
  width: 50%;

  &:hover {
    border: ${theme.utils.border({ color: theme.colors.black100 })};
  }

  &:active {
    border: ${theme.utils.border()};
  }

  &:first-child {
    margin-right: ${theme.space['0.3']};
  }
`

export const StartingAsk = connect(mapStateToProps)(_StartingAsk)
