import React from 'react'

/**
 * Ignore `.svg` file extension in .tsx. Using import syntax effects a few things
 * related to our tests that `require` would fix, but will have to return to the why.
 *
 * FIXME: Configure tsc to support extends while also supporting ES6 modules.
 */

// @ts-ignore
import bell from './bell.svg'
// @ts-ignore
import clock from './clock.svg'
// @ts-ignore
import ppl from './ppl.svg'
// @ts-ignore
import paddle from './paddle.svg'
// @ts-ignore
import reserve from './reserve.svg'
// @ts-ignore
import alert from './alert.svg'
// @ts-ignore
import gavel from './gavel.svg'
// @ts-ignore
import info from './info.svg'
// @ts-ignore
import lots from './lots.svg'
// @ts-ignore
import close from './close.svg'
// @ts-ignore
import chevronRight from './chevron-right.svg'
// @ts-ignore
import circArrow from './circ-arrow.svg'
// @ts-ignore
import circleCheck from './circle-check.svg'
// @ts-ignore
import circle from './circle.svg'
// @ts-ignore
import arrowDown from './arrow-down.svg'
// @ts-ignore
import edit from './edit.svg'
// @ts-ignore
import check from './check.svg'

const icons = {
  bell,
  clock,
  ppl,
  paddle,
  reserve,
  alert,
  gavel,
  info,
  lots,
  close,
  chevronRight,
  circArrow,
  circleCheck,
  circle,
  arrowDown,
  edit,
  check,
}

interface IconProps {
  className?: string
  name: string
  onClick?: () => void
}

export default function Icon({ className, name, onClick }: IconProps) {
  const SVGIcon = icons[name]

  return (
    <div className={`${className}`} onClick={onClick}>
      <SVGIcon className={`icon icon__${name}`} />
    </div>
  )
}

Icon.defaultProps = {
  className: '',
}
