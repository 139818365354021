import PropTypes from 'prop-types'

import _, {
  compact,
  concat,
  filter,
  find,
  findIndex,
  findLast,
  includes,
  partition,
  remove,
  without,
} from 'lodash'
import { reverse } from 'lodash/fp' // non-mutating
import { isPendingBid } from './uiSelectors'
import { asBidEvent } from 'utils/lotHelper'

/**
 * Used in situations where the existence of a given sale artwork is uncertain.
 */
const fallbackSaleArtwork = state => {
  return state.sale.saleArtworks[0]
}

/**
 * [lotById description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const lotById: RRCSelector<(id: LotId) => Lot> = state => lotId => {
  return state.lots[lotId]
}

lotById.propType = PropTypes.func.isRequired

/**
 * Get the Causality Lot for a Gravity SaleArtwork.
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const lotForSaleArtwork: RRCSelector<(
  saleArtwork: SaleArtwork
) => Lot> = state => saleArtwork => lotById(state)(saleArtwork.internalID)
lotForSaleArtwork.propType = PropTypes.func.isRequired

/**
 * [currentLot description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const currentLot: RRCSelector<Lot> = state => {
  let lotId
  if (state.viewMode === 'operator') {
    lotId = state.currentLotId
  } else {
    lotId = state.bidderCurrentLotId
  }
  const lot = state.lots[lotId]

  return lot || state.lots[state.sale.saleArtworks[0].internalID]
}
currentLot.propType = PropTypes.object.isRequired

/**
 * [selectedLot description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const selectedLot: RRCSelector<Lot> = state => {
  const lot = state.lots[state.selectedLotId]
  return lot || currentLot(state)
}

selectedLot.propType = PropTypes.object.isRequired

/**
 * [selectedLotPrice description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const selectedLotPrice: RRCSelector<Cents> = state => {
  const lot = selectedLot(state)
  const price = lot ? lot.derivedLotState.askingPriceCents : 0
  return price
}

selectedLotPrice.propType = PropTypes.number.isRequired

/**
 * When the bid is pending (button has been clicked but bid has
 * not yet been placed on the floor) this returns the value of
 * that pending bid.
 *
 * (if this is called when no bid is pending, the value of the
 * most recent bid is returned, or the current asking price if
 * no live bids have been placed by the bidder, but they have
 * a winning max bid in the system)
 *
 * TODO: There is some tricky type stuff here.
 * @param  {[type]} state [description]
 * @return {number}
 */
export const pendingBidPrice: RRCSelector<Cents> = state => {
  const lot = selectedLot(state)

  if (!lot) {
    return 0
  }

  const lotPrice = selectedLotPrice(state)

  // isPendingBid returns true if the bid button has been clicked
  // but the bid has not yet been acknowledged by the backend
  if (isPendingBid(state)) {
    return lotPrice
  }

  // otherwise the bid has been acknowledged by the backend
  const lastBidEvent: BidEvent = filteredLotEvents()(lot)
    .filter(asBidEvent)
    .filter(
      (event: any) =>
        event.type === 'FirstPriceBidPlaced' &&
        event.bidder.bidderId === state.bidderId
    )[0] as BidEvent

  if (!lastBidEvent) {
    // bidder has placed a max bid ahead of time.
    // Since we don't know the value of their max,
    // return the asking price
    return lotPrice
  }

  return lastBidEvent.amountCents
}

pendingBidPrice.propType = PropTypes.number.isRequired

/**
 * [floorAskingPrice description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const floorAskingPrice: RRCSelector<Cents> = state => {
  return currentLot(state).derivedLotState.floorAskingPriceCents
}

floorAskingPrice.propType = PropTypes.number.isRequired

/**
 * [selectedLotLastBid description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const selectedLotLastBid: RRCSelector<BidEvent> = state => {
  return findLast(selectedLot(state).eventHistory, {
    type: 'FirstPriceBidPlaced',
  })
}

selectedLotLastBid.propType = PropTypes.object

/**
 * [currentSaleArtwork description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const currentSaleArtwork: RRCSelector<SaleArtwork> = state => {
  const _currentSaleArtwork = find(state.sale.saleArtworks, {
    internalID: currentLot(state).id,
  })
  const _fallbackSaleArtwork = fallbackSaleArtwork(state)
  const out = _currentSaleArtwork || _fallbackSaleArtwork
  return out
}

currentSaleArtwork.propType = PropTypes.object.isRequired

/**
 * [selectedSaleArtwork description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const selectedSaleArtwork: RRCSelector<SaleArtwork> = state => {
  const lot = find(state.sale.saleArtworks, {
    internalID: state.selectedLotId,
  })
  const _currentSaleArtwork = currentSaleArtwork(state)
  const _fallbackSaleArtwork = fallbackSaleArtwork(state)
  const out = lot || _currentSaleArtwork || _fallbackSaleArtwork
  return out
}

selectedSaleArtwork.propType = PropTypes.object.isRequired

/**
 * [maxBidIncrements description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const maxBidIncrements: RRCSelector<Cents[]> = state => {
  const saleArtwork = selectedSaleArtwork(state)
  const lot = selectedLot(state)
  const out = filter(saleArtwork.bidIncrements, inc => {
    return inc >= lot.derivedLotState.askingPriceCents
  })
  return out
}

maxBidIncrements.propType = PropTypes.array.isRequired

/**
 * [selectedMaxBid description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const selectedMaxBid: RRCSelector<Cents> = state => {
  const lot = selectedLot(state)
  const lotMaxBid = state.selectedMaxBid[lot.id]
  return lotMaxBid && lotMaxBid > 0 ? lotMaxBid : maxBidIncrements(state)[0]
}

selectedMaxBid.propType = PropTypes.number

/**
 * [operatorChangeSelectedLotId description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const operatorChangeSelectedLotId: RRCSelector<LotId> = state => {
  const _operatorChangeSelectedLotId = state.operatorChangeSelectedLotId

  const out =
    _operatorChangeSelectedLotId.length > 0
      ? _operatorChangeSelectedLotId
      : currentLot(state).id

  return out
}

operatorChangeSelectedLotId.propType = PropTypes.string.isRequired
interface SaleArtworkLists {
  skippedLast: SaleArtwork[]
  upcomingWithCurrent: SaleArtwork[]
  upcoming: SaleArtwork[]
  pastAscending: SaleArtwork[] // Usually want recent-first, but we get this for free
  past: SaleArtwork[]
}

/**
 * We need to reorder the sale artworks, putting past/skipped lots to the end
 * of the list.
 *
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const saleArtworkLists: RRCSelector<SaleArtworkLists> = state => {
  const curSaleArtwork = currentSaleArtwork(state)
  const curLotIndex = findIndex(state.sale.saleArtworks, curSaleArtwork)
  const saleArtworksAfterCurrent = state.sale.saleArtworks.slice(curLotIndex)
  const saleArtworksBeforeCurrent = state.sale.saleArtworks.slice(
    0,
    curLotIndex
  )

  // Reorder so that skipped artworks will come last
  const reorderedSAs = saleArtworksAfterCurrent.concat(
    saleArtworksBeforeCurrent
  )

  const [completedSAs, upcomingSAs] = partition(reorderedSAs, sa => {
    const lot = state.lots[sa.internalID]
    return lot.derivedLotState.biddingStatus === 'Complete'
  })

  return {
    skippedLast: completedSAs.concat(upcomingSAs),
    upcomingWithCurrent: upcomingSAs,
    upcoming: without(upcomingSAs, curSaleArtwork),
    pastAscending: completedSAs, // Usually want recent-first, but we get this for free
    past: reverse(completedSAs),
  }
}

saleArtworkLists.propType = PropTypes.object.isRequired

/**
 * [nextLot description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const nextLot: RRCSelector<Lot> = state => {
  const currentLotId = currentLot(state).id
  const saleArtworks = saleArtworkLists(state)
  const nextSaleArtwork = _(saleArtworks.past)
    .concat(saleArtworkLists(state).upcomingWithCurrent)
    .dropWhile(saleArtwork => saleArtwork.internalID !== currentLotId)
    .concat(saleArtworks.pastAscending) // Loop around to the start
    .nth(1)
  if (!nextSaleArtwork) {
    // Only one lot in this sale, return that one lot
    return state.lots[currentLotId]
  }
  return state.lots[nextSaleArtwork.internalID]
}

nextLot.propType = PropTypes.object.isRequired

/**
 * [filteredLotEvents description]
 * @return {[type]} [description]
 */
export const filteredLotEvents: RRCSelector<(
  lot: Lot
) => CausalityEvent[]> = () => lot => {
  const allLotEvents = lot.eventHistory
    ? lot.eventHistory.slice().reverse()
    : []

  const winningBid = remove(allLotEvents, event => {
    return event.eventId === lot.derivedLotState.winningBidEventId
  })

  const concatEvents = compact(concat(winningBid, allLotEvents))

  return filter(concatEvents, event => {
    return includes(
      [
        'FirstPriceBidPlaced',
        'BiddingOpened',
        'FinalCall',
        'FairWarning',
        'BiddingClosed',
      ],
      event.type
    )
  })
}

filteredLotEvents.propType = PropTypes.func.isRequired

/**
 * [numPendingBids description]
 * @param  {[type]} state [description]
 * @return {[type]}       [description]
 */
export const numPendingBids: RRCSelector<number> = state => {
  const lot = currentLot(state)
  const { floorAskingPriceCents } = lot.derivedLotState
  const pendingBids = filter(
    lot.eventHistory,
    event =>
      event.type === 'FirstPriceBidPlaced' &&
      event.amountCents >= floorAskingPriceCents &&
      event.bidder &&
      event.bidder.type === 'ArtsyBidder' &&
      !event.cancelled
  ).length
  return pendingBids || 0
}

numPendingBids.propType = PropTypes.number.isRequired
