import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonBase } from 'apps/operator/components/Utils/ButtonBase'
import { addCommaToInput, addCommaToNumber } from 'utils/inputBoxUtils'

export class CurrentAsk extends Component {
  static contextTypes = {
    currentAskInputValueChanged: PropTypes.func.isRequired,
    currentLot: PropTypes.object.isRequired,
    formatMoneyWithoutCents: PropTypes.func.isRequired,
    onOperatorChangeFloorAskingPrice: PropTypes.func.isRequired,
    setUpdateFloorAskingPriceOpen: PropTypes.func.isRequired,
    isUpdatingFloorAskingPrice: PropTypes.bool.isRequired,
  }

  numInput: any

  handleConfirmButtonClick = () => {
    const { onOperatorChangeFloorAskingPrice } = this.context

    const unparsed = this.numInput.value
    const parsed = unparsed.replace(/,/g, '')
    const amount = parseInt(parsed, 10)
    if (Number.isInteger(amount)) {
      const amountCent = amount * 100
      if (amountCent > 0) {
        onOperatorChangeFloorAskingPrice(amountCent)
      }
    }
  }

  inputKeyPressed = e => {
    const { currentAskInputValueChanged } = this.context
    addCommaToInput(e)
    const value = e.nativeEvent.target.value || e.target.value
    const askWithoutComma = value.replace(/\D/g, '')
    currentAskInputValueChanged(askWithoutComma)
  }

  async clickedOnAskBox() {
    const {
      currentLot,
      setUpdateFloorAskingPriceOpen,
      isUpdatingFloorAskingPrice,
    } = this.context
    const { floorAskingPriceCents } = currentLot.derivedLotState

    await setUpdateFloorAskingPriceOpen(true)

    if (!isUpdatingFloorAskingPrice) {
      this.numInput.value = addCommaToNumber(floorAskingPriceCents / 100)
    }
    this.numInput.focus()
  }

  render() {
    const {
      currentLot,
      formatMoneyWithoutCents,
      setUpdateFloorAskingPriceOpen,
      isUpdatingFloorAskingPrice,
    } = this.context
    const { floorAskingPriceCents } = currentLot.derivedLotState
    return (
      <Container>
        <AskBox onClick={() => this.clickedOnAskBox()}>
          <Label>Current Ask</Label>
          {isUpdatingFloorAskingPrice ? (
            <Input
              ref={x => {
                this.numInput = x
              }}
              onChange={e => this.inputKeyPressed(e)}
              onFocus={e => e.target.select()}
            />
          ) : (
            <Value>
              {formatMoneyWithoutCents(floorAskingPriceCents / 100)}
            </Value>
          )}
        </AskBox>

        {isUpdatingFloorAskingPrice && (
          <ActionButtons>
            <Button
              color="white100"
              onClick={() => setUpdateFloorAskingPriceOpen(false)}
            >
              Cancel
            </Button>
            <Button
              color="purple100"
              onClick={() => this.handleConfirmButtonClick()}
            >
              Ok
            </Button>
          </ActionButtons>
        )}
      </Container>
    )
  }
}

const ActionButtons = styled.div`
  ${p => p.theme.utils.flexEnd};
  width: 100%;
  &:button:nth-of-type(1) {
    margin-left: auto;
  }
}
`

const AskBox = styled.div`
  padding: ${p => p.theme.margins.medium};
  width: 100%;
`

export const Button = styled(ButtonBase)`
  background: ${p => p.theme.colors[p.color]};
  width: 96px;
  height: 38px;
  justify-content: center;
  position: relative;
  margin: 1px;
  top: 1px;

  ${({ color, theme }) => {
    const [borderColor, textColor] =
      color === 'white100'
        ? [theme.colors.black10, theme.colors.black80]
        : ['transparent', theme.colors.white100]

    return `
      border: ${theme.utils.border({ color: borderColor })};
      color: ${textColor};

      &:hover {
        border: ${theme.utils.border({ color: theme.colors.black100 })}
      }
    `
  }};
`

const Container = styled.div`
  ${({ theme }) => {
    return `
      border: ${theme.utils.border({ color: theme.colors.black100 })};
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.purple100};
      background: ${theme.colors.white100};
      ${theme.utils.stretch};
      flex-direction: column;
      width: 300px;
      height: 140px;
      padding: 2px;
      color: ${theme.colors.black100};
      &:hover {
        color: ${theme.colors.purple100};
        border-color: ${theme.colors.purple100};
      }

    `
  }};
`

const Input = styled.input`
  color: ${p => p.theme.colors.purple100};
  font-size: ${p => p.theme.fontSizes.large};
  text-align: right;
  position: relative;
  width: 100%;
  top: 23px;
  border: 0;
  padding: 0;
  margin: 0;
`

const Label = styled.div``

const Value = styled.div`
  font-size: ${p => p.theme.fontSizes.large};
  text-align: right;
  position: relative;
  width: 100%;
  top: 25px;
`
