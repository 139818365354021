/* eslint-disable max-len */

import errorMsg from './utils/errorMsg'
import { actions, Interaction } from 'actions/appActions'
import { browserHistory } from 'react-router'
import { combineReducers } from 'redux'
import { getStore } from 'utils/configureStore'
import { isClient } from 'utils/environment'

import {
  mySelectedLotState,
  myMaxBidButtonState,
} from 'selectors/bidderSelectors'

import {
  capitalize,
  clone,
  cloneDeep,
  compact,
  get,
  isEmpty,
  map,
  max,
  merge,
  snakeCase,
} from 'lodash'

/**
 * Initial application state
 */
export const initialState = {
  currentAskInputValue: 'NaN',
  bidderCurrentLotId: '',
  bidderId: '',
  bidError: '',
  causalityJWT: '',
  causalityRoles: [],
  currentLotId: '',
  debugExternalOperator: false,
  deviceMode: 'desktop',
  eigenModalOpen: false,
  errorModal: {},
  incrementPoliciesById: {},
  isAdmin: false,
  isPendingBid: false,
  isToggledAuctionInfoModal: false,
  isToggledChangeCurrentLotModal: false,
  isToggledLotsModal: false,
  isToggledSaleOnHold: false,
  isSaleClosed: false,
  saleOnHoldMessage: '',
  isUpcomingArtworkList: true,
  justOutbid: false,
  justWinning: false,
  lots: {},
  me: {},
  ongoingUserInteraction: Interaction.NONE,
  operatorChangeSelectedLotId: '',
  operatorUndoActionKey: '',
  outbidFlashing: false,
  monitorSelectedLotId: '',
  monitorMode: 'lots',
  paddleNumber: '',
  qualifiedForBidding: false,
  retryCountdown: 3,
  role: 'observer',
  sale: {},
  saleStatistics: {},
  sales: [],
  selectedLotId: {},
  selectedMaxBid: {},
  startingAskInputValue: NaN,
  viewMode: 'participant',
  winningFlashing: false,
}

function currentAskInputValue(
  state = initialState.currentAskInputValue,
  action
) {
  switch (action.type) {
    case actions.CURRENT_ASK_INPUT_VALUE_CHANGED:
      return action.value
    default:
      return state
  }
}

function startingAskInputValue(
  state = initialState.startingAskInputValue,
  action
) {
  switch (action.type) {
    case actions.STARTING_ASK_INPUT_CHANGED:
      return action.value
    default:
      return state
  }
}

function bidderCurrentLotId(state = initialState.bidderCurrentLotId, action) {
  switch (action.type) {
    case actions.SALE_LOT_CHANGE_BROADCAST_DELAY:
      return action.data.lotId
    default:
      return state
  }
}

function bidderId(state = initialState.bidderId) {
  return state
}

function bidError(state = initialState.bidError, action) {
  switch (action.type) {
    case actions.COMMAND_FAILED:
    case actions.POST_EVENT_RESPONSE: {
      if (action.data.wasAccepted) {
        return ''
      }

      const snaked = snakeCase(action.data.reason.type)
      const msg = capitalize(snaked.replace(/_/g, ' ').toLowerCase())
      return errorMsg(msg, action)
    }

    case actions.INVALID_MESSAGE_REPLY:
      return errorMsg(action.data.cause, action)

    case actions.LOT_UPDATE_BROADCAST:
      return ''

    case actions.CLOSE_BID_ERROR_MESSAGE:
      return ''

    default:
      return state
  }
}

function currentLotId(state = initialState.currentLotId, action) {
  switch (action.type) {
    case actions.SALE_LOT_CHANGE_BROADCAST:
      return action.data.lotId
    default:
      return state
  }
}

function deviceMode(state = initialState.deviceMode, action) {
  switch (action.type) {
    case actions.WINDOW_RESIZE: {
      if (action.windowWidth <= 500) {
        return 'mobile'
      } else if (action.windowWidth <= 1024) {
        return 'tablet'
      } else {
        return 'desktop'
      }
    }
    default:
      return state
  }
}

function eigenModalOpen(state = initialState.eigenModalOpen, action) {
  switch (action.type) {
    case actions.CLOSE_EIGEN_MODAL:
      return false
    default:
      return state
  }
}

function errorModal(state = initialState.errorModal, action) {
  switch (action.type) {
    case actions.ERROR_RUNTIME: {
      return {
        header: 'An error has occurred in the app.',
        subHeader: 'A notification has been sent to our engineering staff',
      }
    }

    case actions.DISCONNECT:
      return {
        header: 'Artsy has lost contact with the auction house.',
        subHeader: 'Attempting to reconnect now',
      }

    case actions.LOT_UPDATE_BROADCAST:
      return {}

    case actions.OFFLINE:
      return {
        header: 'Lost network connection',
        subHeader: 'Please check your internet connection and try to reconnect',
      }
    case actions.ONLINE:
      return {}

    case actions.OPERATOR_CONNECTED: {
      if (action.data.operatorConnected === false) {
        return {
          header: 'Artsy has lost contact with the auction house.',
          subHeader: '',
        }
      }

      return {}
    }

    default:
      return state
  }
}

function incrementPoliciesById(
  state = initialState.incrementPoliciesById,
  action
) {
  switch (action.type) {
    default:
      return state
  }
}

function isAdmin(state = initialState.isAdmin, action) {
  switch (action.type) {
    default:
      return state
  }
}

function isPendingBid(state = initialState.isPendingBid, action) {
  switch (action.type) {
    case actions.BID_SENT:
      return true
    case actions.LOT_UPDATE_BROADCAST:
      return false
    default:
      return state
  }
}

function isToggledAuctionInfoModal(
  state = initialState.isToggledAuctionInfoModal,
  action
) {
  switch (action.type) {
    case actions.TOGGLE_AUCTION_INFO_MODAL:
      return !state
    default:
      return state
  }
}

function isToggledChangeCurrentLotModal(
  state = initialState.isToggledChangeCurrentLotModal,
  action
) {
  switch (action.type) {
    case actions.SET_OPERATOR_CHANGE_CURRENT_LOT:
      return !state
    default:
      return state
  }
}

function isToggledLotsModal(state = initialState.isToggledLotsModal, action) {
  switch (action.type) {
    case actions.TOGGLE_LOTS_MODAL:
      return !state
    case actions.SELECT_LOT:
      return false
    default:
      return state
  }
}

function isToggledSaleOnHold(state = initialState.isToggledSaleOnHold, action) {
  switch (action.type) {
    case actions.SALE_ON_HOLD:
      return action.data.isOnHold
    default:
      return state
  }
}

function isSaleClosed(state = initialState.isSaleClosed, action) {
  switch (action.type) {
    case actions.SALE_CLOSED:
      return true
    default:
      return state
  }
}

function saleOnHoldMessage(state = initialState.saleOnHoldMessage, action) {
  switch (action.type) {
    case actions.SALE_ON_HOLD:
      return action.data.userMessage
    default:
      return state
  }
}

function isUpcomingArtworkList(
  state = initialState.isUpcomingArtworkList,
  action
) {
  switch (action.type) {
    case actions.TOGGLE_ARTWORK_LIST:
      return action.upcoming
    default:
      return state
  }
}

function justOutbid(state = initialState.justOutbid) {
  return state
}

function justWinning(state = initialState.justWinning) {
  return state
}

function lots(state = initialState.lots, action) {
  switch (action.type) {
    case actions.LOT_UPDATE_BROADCAST: {
      const newState = clone(state)
      const newLot = cloneDeep(state[action.data.lotId])

      // TODO: Avoid mutation
      merge(newLot.events, action.data.events)

      newLot.derivedLotState = action.data.derivedLotState
      newLot.eventHistory = compact(
        map(action.data.fullEventOrder, ord => {
          return newLot.events[ord]
        })
      )

      newState[newLot.id] = newLot
      return newState
    }

    default:
      return state
  }
}

function monitorMode(state = initialState.monitorMode, action) {
  switch (action.type) {
    case actions.MONITOR_SELECT_MODE:
      return action.data.mode
    default:
      return state
  }
}

function monitorSelectedLotId(
  state = initialState.monitorSelectedLotId,
  action
) {
  switch (action.type) {
    case actions.MONITOR_SELECT_LOT:
      return action.data.lotId
    default:
      return state
  }
}

function ongoingUserInteraction(
  state = initialState.ongoingUserInteraction,
  action
) {
  switch (action.type) {
    case actions.USER_INTERACTION_COMPLETED:
      return Interaction.NONE

    case actions.USER_INTERACTION_STARTED:
      return action.userInteraction

    default:
      return state
  }
}

function operatorChangeSelectedLotId(
  state = initialState.operatorChangeSelectedLotId,
  action
) {
  switch (action.type) {
    case actions.UPDATE_OPERATOR_CHANGE_SELECTED_LOT:
      return action.lotId
    default:
      return state
  }
}

function operatorUndoActionKey(
  state = initialState.operatorUndoActionKey,
  action
) {
  switch (action.type) {
    case actions.SET_OPERATOR_UNDONE_KEY:
      return action.key
    case actions.COMMAND_SUCCESSFUL: {
      const {
        data: { event },
      } = action

      if (event && event.type === 'LiveOperatorEventUndone') {
        return ''
      } else {
        return state
      }
    }
    default:
      return state
  }
}

function outbidFlashing(state = initialState.outbidFlashing, action) {
  switch (action.type) {
    case actions.LOT_UPDATE_BROADCAST_DELAY:
      return false
    case actions.LOT_UPDATE_BROADCAST:
      return true
    default:
      return state
  }
}

function paddleNumber(state = initialState.paddleNumber) {
  return state
}

function qualifiedForBidding(state = initialState.qualifiedForBidding) {
  return state
}

function retryCountdown(state = initialState.retryCountdown, action) {
  switch (action.type) {
    case actions.DISCONNECT:
      return 3

    case actions.RETRY_COUNTDOWN_TICK: {
      if (state === 1) {
        location.reload()
      } else {
        return state - 1
      }
    }

    case actions.OFFLINE:
    case actions.LOT_UPDATE_BROADCAST:
      return 0
    case actions.OPERATOR_CONNECTED:
      return action.data.operatorConnected === false ? 0 : 3
    default:
      return state
  }
}

function role(state = initialState.role) {
  return state
}

function rootReducer(state = initialState, action = {}) {
  let newState = clone(state)

  switch (action.type) {
    case actions.BID_SENT: {
      newState.justOutbid = false
      newState.justWinning = false
      break
    }

    case actions.LOT_UPDATE_BROADCAST_DELAY: {
      const selectedBidState = mySelectedLotState(state)
      const maxBidButtonState = myMaxBidButtonState(state)

      const isOutbid = [selectedBidState, maxBidButtonState].some(
        s => s === 'outbid'
      )

      if (isOutbid) {
        newState.justOutbid = true
      }

      if (maxBidButtonState === 'winning-flashing') {
        newState.justWinning = true
      }
      break
    }

    case actions.SALE_LOT_CHANGE_BROADCAST: {
      if (state.selectedLotId === state.currentLotId) {
        newState.selectedLotId = action.data.lotId
      }
      // causality sends broadcast only after sale is live (?!)
      if (state.sale && !state.sale.isLiveOpen) {
        newState.sale.isLiveOpen = true
      }
      break
    }

    default:
      break
  }
  return newState
}

function sale(state = initialState.sale, action) {
  switch (action.type) {
    default:
      return state
  }
}

function sales(state = [], action) {
  switch (action.type) {
    default:
      return state
  }
}

function saleStatistics(state = initialState.saleStatistics, action) {
  switch (action.type) {
    default:
      return state
  }
}

function selectedLotId(state = initialState.selectedLotId, action) {
  switch (action.type) {
    case actions.SELECT_LOT:
      return action.lotId

    case actions.LOT_UPDATE_BROADCAST: {
      const currentState = getStore().getState()
      const biddingStatus = get(
        currentState,
        `lots[${currentState.selectedLotId}].derivedLotState.biddingStatus`
      )
      const unlockLotProgression =
        !isEmpty(state) && biddingStatus === 'OnBlock'

      /**
       * If a lot is currently selected in the list, deselect it to allow for the
       * sale to progress forward. Also, clear the url deep-link state.
       */
      if (unlockLotProgression) {
        if (isClient) {
          setTimeout(() => {
            browserHistory.push('/' + currentState.sale.slug)
          })
        }
        return ''
      } else {
        return state
      }
    }
    default:
      return state
  }
}

function selectedMaxBid(state = initialState.selectedMaxBid, action) {
  const newState = clone(state)
  switch (action.type) {
    case actions.UPDATE_SELECTED_MAX_BID:
      newState[action.lotId] = action.newMaxBid
      return newState
    case actions.LOT_UPDATE_BROADCAST: {
      newState[action.data.lotId] = max([
        newState[action.data.lotId],
        action.data.derivedLotState.askingPriceCents,
      ])

      return newState
    }
    default:
      return state
  }
}

function debugExternalOperator(
  state = initialState.debugExternalOperator,
  action
) {
  switch (action.type) {
    case actions.SET_DEBUG_EXTERNAL_OPERATOR:
      return action.debugExternalOperator
    default:
      return state
  }
}

function userId(state = '') {
  return state
}

function viewMode(state = initialState.viewMode) {
  return state
}

function winningFlashing(state = initialState.winningFlashing, action) {
  switch (action.type) {
    case actions.LOT_UPDATE_BROADCAST_DELAY:
      return false
    case actions.LOT_UPDATE_BROADCAST:
      return true
    default:
      return state
  }
}

export const reducers = {
  currentAskInputValue,
  bidderCurrentLotId,
  bidderId,
  bidError,
  causalityJWT: (state = initialState.causalityJWT) => state,
  currentLotId,
  debugExternalOperator,
  deviceMode,
  eigenModalOpen,
  errorModal,
  incrementPoliciesById,
  isAdmin,
  isPendingBid,
  isToggledAuctionInfoModal,
  isToggledChangeCurrentLotModal,
  isToggledLotsModal,
  isToggledSaleOnHold,
  isSaleClosed,
  saleOnHoldMessage,
  isUpcomingArtworkList,
  justOutbid,
  justWinning,
  lots,
  me: (state = initialState.me) => state,
  monitorSelectedLotId,
  monitorMode,
  ongoingUserInteraction,
  operatorChangeSelectedLotId,
  operatorUndoActionKey,
  outbidFlashing,
  paddleNumber,
  qualifiedForBidding,
  retryCountdown,
  role,
  sale,
  sales,
  saleStatistics,
  selectedLotId,
  selectedMaxBid,
  startingAskInputValue,
  userId,
  viewMode,
  winningFlashing,
}

const combinedReducers = combineReducers(reducers)

export default (state = initialState, action) => {
  return combinedReducers(rootReducer(state, action), action)
}
