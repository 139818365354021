import PropTypes from 'prop-types'
import React from 'react'
import { isEmpty } from 'lodash'
import block from 'bem-cn-lite'
import './OnHoldNotification.styl'

interface OnHoldNotificationProps {
  message?: string
}

export default function OnHoldNotification(
  { message }: OnHoldNotificationProps,
  { deviceMode, saleOnHoldMessage }
) {
  // A default, then allow for customization via props, or context.
  let displayMessage =
    'The auction is currently on hold. You can still place max bids.'
  if (!isEmpty(message)) {
    displayMessage = message
  } else if (!isEmpty(saleOnHoldMessage)) {
    displayMessage = saleOnHoldMessage
  }

  const b = block('on-hold-notification')
  return (
    <div className={b({ 'device-mode': deviceMode })}>{displayMessage}</div>
  )
}

OnHoldNotification.propTypes = {
  message: PropTypes.string,
}

OnHoldNotification.contextTypes = {
  deviceMode: PropTypes.string.isRequired,
  saleOnHoldMessage: PropTypes.string,
}
