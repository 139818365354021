import ArtworkListItem from 'apps/bidder/components/ArtworkListItem'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import block from 'bem-cn-lite'
import './ArtworkList.styl'

export default class ArtworkList extends Component {
  static contextTypes = {
    saleArtworkLists: PropTypes.object.isRequired,
    onToggleArtworkList: PropTypes.func.isRequired,
    isUpcomingArtworkList: PropTypes.bool.isRequired,
  }

  state = {
    // Local state so that deep-link item will scroll into view on first load
    // but remain still during app runtime.
    prevTabScrollEnabled: true,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        prevTabScrollEnabled: false,
      })
    })
  }

  render() {
    const b = block('artwork-list')
    const listFilterClass = b.builder()('filter')

    const {
      isUpcomingArtworkList,
      onToggleArtworkList,
      saleArtworkLists,
    } = this.context

    return (
      <div className={b()}>
        <div className={listFilterClass.mix('horizontal-list-nav')()}>
          <div className={listFilterClass.mix('label').mix('hln-cell')()}>
            <span>Lots</span>
          </div>
          <div
            className={listFilterClass('buttons').mix([
              'hln-cell',
              'is-dominant',
            ])()}
          >
            <button
              onClick={() => onToggleArtworkList(true)}
              className={
                isUpcomingArtworkList
                  ? listFilterClass.mix(['hln-link', 'is-active'])()
                  : listFilterClass.mix('hln-link')()
              }
            >
              Upcoming
            </button>
            <button
              onClick={() => onToggleArtworkList(false)}
              className={
                isUpcomingArtworkList
                  ? listFilterClass.mix('hln-link')()
                  : listFilterClass.mix(['hln-link', 'is-active'])()
              }
            >
              Previous
            </button>
          </div>
        </div>

        {/*
          Show 'Upcoming' and 'Past' side-by-side to avoid flash and jump when
          re-rendering display list. If upcoming, toggle visibility off of past
          and vice-versa.

          Upcoming Lot list
        */}
        <div
          className={b('items')}
          style={{
            display: isUpcomingArtworkList ? 'block' : 'none',
          }}
        >
          {saleArtworkLists.upcoming.map(saleArtwork => (
            <ArtworkListItem key={saleArtwork.internalID} lot={saleArtwork} />
          ))}

          {isUpcomingArtworkList && (
            <div className={b.builder().mix('end-item')()}>
              <h5>AUCTION END</h5>
            </div>
          )}
        </div>

        {/*
          Past Lot list
        */}
        <div
          className={b('items')}
          style={{
            display: isUpcomingArtworkList ? 'none' : 'block',
          }}
        >
          {saleArtworkLists.past.map(saleArtwork => (
            <ArtworkListItem
              key={saleArtwork.internalID}
              lot={saleArtwork}
              prevTabScrollEnabled={this.state.prevTabScrollEnabled}
            />
          ))}
        </div>
      </div>
    )
  }
}
