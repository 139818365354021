import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import LotEstimate from 'components/LotEstimate'
import BuyersPremium from 'components/BuyersPremium'
import Icon from 'assets/icons'
import './LotInfo.styl'
import { LotArtworkTombstone } from 'components/LotArtworkTombstone'
import { Spacer } from '@artsy/palette'

export default function LotInfo(p, { selectedSaleArtwork, selectedLot }) {
  const b = block('lot-info')
  const { onlineBidCount } = selectedLot.derivedLotState

  return (
    <div className={b()}>
      <div className={b('lot-no')}>Lot {selectedSaleArtwork.lotLabel}</div>
      <LotArtworkTombstone />
      <Spacer m="3" />
      <div className={b('estimate')}>
        <LotEstimate saleArtwork={selectedSaleArtwork} />
      </div>

      <BuyersPremium />

      <div className={b('stats')}>
        <div key="paddle">
          <Icon className={b('icon')} name="paddle" />
          <span>Bid Count</span>
          <span>{onlineBidCount}</span>
        </div>
      </div>
    </div>
  )
}

LotInfo.contextTypes = {
  selectedSaleArtwork: PropTypes.object.isRequired,
  selectedLot: PropTypes.object.isRequired,
}
