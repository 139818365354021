import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

/**
 * Wrapper for input components to declaratively add autofocus behavior upon
 * mounting. The `onFocus` event handler can then be used to add additional
 * behavior.
 *
 * Example:
 * <Autofocus><input type="text" /></Autofocus>
 */
export class Autofocus extends React.Component<{
  nodeSelector?: (node) => void
}> {
  static propTypes = {
    /**
     * Custom logic for selecting the target node to scroll into view. The
     * function is passed the DOM element of the Autoscroll wrapper and is
     * responsible for returning the target DOM element.
     */
    nodeSelector: PropTypes.func,
    /** The input or styled input to control. */
    children: PropTypes.element.isRequired,
  }

  componentDidMount() {
    this.nodeSelector(ReactDOM.findDOMNode(this)).focus()
  }

  nodeSelector(rootNode) {
    return this.props.nodeSelector
      ? this.props.nodeSelector(rootNode)
      : rootNode
  }

  render() {
    return this.props.children
  }
}
