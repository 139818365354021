/**
 * TODO: Bring this and utils/socket into proper Redux flow
 */

import * as appActions from 'actions/appActions'
import keyMirror from 'utils/keyMirror'
import { isServer } from 'utils/environment'
import { snakeCase } from 'lodash'

export const events = keyMirror(
  'LotUpdateBroadcast',
  'SaleLotChangeBroadcast',
  'disconnect',
  'CommandSuccessful',
  'CommandFailed'
)

export default function initializeSocket(socket, dispatch) {
  if (isServer) {
    return
  }

  /**
   * On receiving websocket events from Causality, transform them directly into
   * redux actions in the form of { type: 'LOT_UPDATE_BROADCAST', data... }.
   */
  socket.on('*', data => {
    dispatch({
      // TODO: Should we lock this down a bit more rather than remaining generic?
      type: snakeCase(data.type).toUpperCase(),
      data,
    })
  })

  /**
   * Broadcast when changes to a lot are made
   */
  socket.on(events.LotUpdateBroadcast, () => {
    setTimeout(() => {
      dispatch(appActions.lotUpdateBroadcastDelay())
    }, 1000)
  })

  /**
   * Broadcast when a sale has been made, and the auction proceeds to the next lot.
   */
  socket.on(events.SaleLotChangeBroadcast, data => {
    setTimeout(() => {
      dispatch(appActions.saleLotChangeBroadcastDelay(data))
    }, 2000)
  })

  /**
   * Handle Causality disconnecting or someone's internet going out
   */
  socket.on(events.disconnect, () => {
    dispatch(appActions.disconnect())

    setInterval(() => {
      dispatch(appActions.retryCountdownTick())
    }, 1000)
  })
}
