import RavenClient from 'raven-js'
import minilog from 'minilog'
import { head } from 'lodash'
import { isClient, isServer, isTest } from 'utils/environment'

if (!isTest) {
  minilog.enable()
}

export default function createLogger(namespace = 'prediction') {
  const log = minilog(namespace + ' | ')

  return {
    log: (...messages) => {
      log.log(...messages)
    },
    warn: (...warnings) => {
      log.warn(...warnings)
    },
    error: (...errors) => {
      const error = head(errors.filter(e => e instanceof Error))

      if (error) {
        if (isServer && process.env.SENTRY_PRIVATE_DSN) {
          const Sentry = require('@sentry/node')
          Sentry.captureException(error)
        } else if (isClient && RavenClient.isSetup()) {
          RavenClient.captureException(error)
        }
      }

      log.error(...errors)
    },
  }
}
