import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  interestOrOutcome,
  isSold,
  lotHammerPrice,
  isSellingToArtsyBidder,
} from 'utils/lotHelper'
import { compactAmount } from 'utils/formatHelper'

export default function MonitorLotListItem(props, context) {
  const { saleArtwork } = props
  const {
    artsyMaxDisplay,
    artsyMinDisplay,
    askingPriceDisplay,
    currentSaleArtwork,
    lotById,
    lowEstimateDisplay,
    monitorSelectedLot,
    onMonitorSelectLot,
    sellingPriceDisplay,
  } = context
  const lot = lotById(saleArtwork.internalID)

  const isCurrent = lot.id === currentSaleArtwork.internalID
  const isPast = lot.derivedLotState.biddingStatus === 'Complete'
  const isSelected = monitorSelectedLot && lot.id === monitorSelectedLot.id
  return (
    <Container isCurrent={isCurrent} isPast={isPast} isSelected={isSelected}>
      <RowHeader
        onClick={() =>
          onMonitorSelectLot(
            monitorSelectedLot && lot.id === monitorSelectedLot.lotId
              ? null
              : lot.id
          )
        }
        isCurrent={isCurrent}
        isSelected={isSelected}
      >
        <ImageContainer>
          {saleArtwork.artwork.image ? (
            <Image src={saleArtwork.artwork.image.url} />
          ) : (
            <NoImage />
          )}
        </ImageContainer>
        <Metadata>
          <div>Lot {saleArtwork.lotLabel}</div>
          {saleArtwork.artwork.artist && saleArtwork.artwork.artistNames ? (
            <div>{saleArtwork.artwork.artistNames}</div>
          ) : null}
        </Metadata>
      </RowHeader>
      <Field>{sellingPriceDisplay(lot)}</Field>
      <Field>{askingPriceDisplay(lot)}</Field>
      <Field>{lowEstimateDisplay(lot)}</Field>
      <Field>{artsyMinDisplay(lot)}</Field>
      <Field>{artsyMaxDisplay(lot)}</Field>
      <Field artsyWinning={isSellingToArtsyBidder(lot)}>
        {interestOrOutcome(lot).abbrev}
        <InterestTooltip>{interestOrOutcome(lot).detail}</InterestTooltip>
      </Field>
      <Field artsyWinning={isSellingToArtsyBidder(lot)}>
        {isSold(lot) && compactAmount(lotHammerPrice(lot) / 100)}
      </Field>
    </Container>
  )
}

MonitorLotListItem.contextTypes = {
  askingPriceDisplay: PropTypes.func.isRequired,
  artsyMaxDisplay: PropTypes.func.isRequired,
  artsyMinDisplay: PropTypes.func.isRequired,
  cmsEditLinkPath: PropTypes.func.isRequired,
  currentSaleArtwork: PropTypes.object.isRequired,
  monitorSelectedLot: PropTypes.object,
  numberOfBidsDisplay: PropTypes.func.isRequired,
  highBidDisplay: PropTypes.func.isRequired,
  lowEstimateDisplay: PropTypes.func.isRequired,
  ohmEditLinkPath: PropTypes.func.isRequired,
  onMonitorSelectLot: PropTypes.func.isRequired,
  sellingPriceDisplay: PropTypes.func.isRequired,
  maxBidDisplay: PropTypes.func.isRequired,
  topBidEvent: PropTypes.func.isRequired,
  lotById: PropTypes.func.isRequired,
}

MonitorLotListItem.propTypes = {
  saleArtwork: PropTypes.object.isRequired,
  sale: PropTypes.object.isRequired,
  past: PropTypes.bool.isRequired,
}

const Container = styled.div<{
  isPast: boolean
  isCurrent: boolean
  isSelected: boolean
}>`
  display: flex;
  ${p => (p.isPast ? `color: ${p.theme.colors.grayaaa}` : null)};

  > *:nth-child(even) {
    background: ${p =>
      p.theme.colors[p.isCurrent || p.isSelected ? null : 'grayf3']};
  }
  ${p => (p.isCurrent ? `background: ${p.theme.colors.lightPurple}` : null)};
  ${p => (p.isSelected ? `background: ${p.theme.colors.yellow}` : null)};
`

// Out of alpha order because `Field` depends on it
const InterestTooltip = styled.div`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
`

const Field = styled.div<{ artsyWinning?: boolean }>`
  display: flex;
  position: relative;
  width: 60px;
  height: 75px;
  justify-content: center;
  align-items: center;
  ${p => p.artsyWinning && `color: ${p.theme.colors.purple};`}

  &:hover ${InterestTooltip} {
    visibility: visible;
  }
`

const Image = styled.img`
  width: 71px;
  height: 71px;
`

const ImageContainer = styled.div`
  padding: 2px;
`

const Metadata = styled.div`
  padding: 2px;
`

const NoImage = styled.div`
  width: 71px;
  height: 71px;
  background: #bbb;
`

const RowHeader = styled.div<{ isCurrent: boolean; isSelected: boolean }>`
  display: flex;
  width: 200px;
  height: 75px;
  align-items: center;
  cursor: pointer;
`
