import * as appActions from 'actions/appActions'
import LotInfoModal from 'apps/bidder/components/LotInfoModal'
import { BidderController } from 'apps/bidder/bidderController'
import { get } from 'lodash'
import { isClient } from 'utils/environment'

export default function makeBidderRoutes(store) {
  const bidderRoutes = {
    path: '/:slug',
    component: BidderController,
    onChange: handleTopLevelRouteChange,
    childRoutes: [
      {
        path: '(:lotId)',
        onEnter: selectLotOnEnter,
        childRoutes: [
          {
            path: ':info',
            components: {
              LotInfoModal,
            },
          },
        ],
      },
    ],
  }

  // TODO:
  // Update the below functions so that `isClient` checks are no longer necessary.

  function handleTopLevelRouteChange(prevState, nextState) {
    if (isClient) {
      const {
        params: { lotId },
      } = nextState
      const { dispatch } = store

      // If clicking the "Back to Live Lot" button, clear lot id and go to live lot
      if (!lotId) {
        dispatch(appActions.selectLot({ lotId: '' }))
      }
    }
  }

  function selectLotOnEnter(routeParams) {
    if (isClient) {
      const {
        params: { lotId },
      } = routeParams
      const { dispatch, getState } = store

      if (lotId) {
        const { lots } = getState()
        const lotIsPast =
          get(lots[lotId], 'derivedLotState.biddingStatus') === 'Complete'

        // First, link directly in to lot
        dispatch(appActions.selectLot({ lotId }))

        // Then toggle open the 'Previous' section in lot list if lot already past
        if (lotIsPast) {
          dispatch(appActions.toggleArtworkList(false))
        }
      }
    }
  }

  return bidderRoutes
}
