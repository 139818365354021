import PropTypes from 'prop-types'
import { currentLot } from 'selectors/lotSelectors'
import { roles } from 'typings/Roles'
import { isNull } from 'lodash'

export const isExternalOperator = state => {
  return state.role === roles.EXTERNAL_OPERATOR || state.debugExternalOperator
}
isExternalOperator.propType = PropTypes.bool.isRequired

export const hasCompetitiveArtsyBid = state => {
  const {
    onlineBidCount,
    sellingPriceCents,
    floorAskingPriceCents,
    winningBidder,
  } = currentLot(state).derivedLotState
  return (
    onlineBidCount > 0 &&
    sellingPriceCents >= floorAskingPriceCents &&
    !isNull(winningBidder)
  )
}
hasCompetitiveArtsyBid.propType = PropTypes.bool.isRequired

export const artsyCanBid = state => {
  const { canArtsyDoubleBid } = currentLot(state).derivedLotState

  // Normally, we only show the Artsy bid button when we have online
  // competition at the asking price. But we also enable the button when we're
  // off-foot for a max bid and need to place a bid at the exact max.

  return hasCompetitiveArtsyBid(state) || canArtsyDoubleBid
}
artsyCanBid.propType = PropTypes.bool.isRequired

export const hasCompetitiveArtsyBidsAtOpen = state => {
  const {
    onlineBidCount,
    sellingPriceCents,
    floorAskingPriceCents,
    winningBidder,
  } = currentLot(state).derivedLotState
  return (
    onlineBidCount > 1 &&
    sellingPriceCents >= floorAskingPriceCents &&
    winningBidder &&
    winningBidder.type === 'ArtsyBidder'
  )
}
hasCompetitiveArtsyBidsAtOpen.propType = PropTypes.bool.isRequired

export const isStepBiddable = state => (stepIndex, isExternalOperator) => {
  const { canArtsyDoubleBid } = currentLot(state).derivedLotState
  const { askingStep, onlineCompetitiveStep, onlineMaxStep } = currentLot(
    state
  ).derivedLotState.footingData
  const hasMin = onlineCompetitiveStep !== null
  const hasMax = onlineMaxStep !== null

  // 1. Can't bid steps that are below the ask.
  if (stepIndex < askingStep) {
    return false

    // 2. Highlight if we're below a min.
  } else if (stepIndex <= onlineCompetitiveStep) {
    return true

    // 3. Highlight on-foot increments below max, if not in third-party mode
    // unless we need to deliberately show max and double bid
  } else if (
    (!isExternalOperator || canArtsyDoubleBid) &&
    hasMax &&
    stepIndex <= onlineMaxStep
  ) {
    return (
      stepIndex % 2 === onlineMaxStep % 2 ||
      (canArtsyDoubleBid && stepIndex === onlineMaxStep - 1)
    )

    // (At this point, we're only dealing with steps above a revealed min and max)
    // 4. Don't highlight upcoming increments when a min is active, so that only
    // the min is highlighted.
    // 5. Don't highlight upcoming increments if we're showing the max
    // label in third-party mode.
  } else if (
    hasMin ||
    (!isExternalOperator && hasMax) ||
    (isExternalOperator && canArtsyDoubleBid)
  ) {
    return false

    // 6. Highlight all upcoming increments when not showing a min or
    // max label, to show that operator should click Floor, as needed
  } else {
    return true
  }
}
isStepBiddable.propType = PropTypes.func.isRequired
