import React from 'react'
import styled, { css } from 'styled-components'
import { Sans, Box, color } from '@artsy/palette'
import { formatMoneyWithoutCents } from 'utils/formatHelper'
import {
  SpaceProps,
  space,
  color as styledColor,
  ColorProps,
} from 'styled-system'

interface ButtonProps extends SpaceProps, ColorProps {
  height?: string
  width?: string
  borderWidth?: string
  borderColor?: string
  confirming?: boolean
}

export const Button = styled.button<ButtonProps>`
  ${space};
  ${styledColor};
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${({ width, height }) => `
    height: ${height};
    width: ${width}
  `};
  cursor: pointer;
  outline: 0;
  border-width: ${({ borderWidth }) => borderWidth};
  border-style: solid;
  border-color: ${({ borderColor, bg, theme }) =>
    theme.colors[borderColor || (bg as string)]};

  /* Override the hover style from base button to maintain thickness */
  &:hover {
    border-color: ${color('white100')};
  }
  &:active {
    border-color: ${color('white100')};
    background: ${color('black100')};
    color: ${color('black100')};
    * {
      color: ${color('black100')};
    }
  }
  &:disabled {
    background: ${color('black80')};
    color: ${color('black60')};
    border-color: ${color('black60')};
    pointer-events: none;
    * {
      color: ${color('black60')};
    }
  }
  ${({ confirming }) => confirming && confirmingStyle};
`

Button.defaultProps = {
  height: '50px',
  width: '140px',
  borderWidth: '2px',
  bg: 'black100',
  color: 'black60',
  borderColor: 'black60',
}

export const BigButton = props => (
  <Button height="90px" width="300px" borderWidth="4px" {...props} />
)

export const ArtsyBidButton = ({
  disabled,
  askingPriceCents,
  flash,
  ...props
}) => {
  return (
    <StyledArtsyButton
      flash={flash}
      bg="white100"
      borderColor="purple100"
      disabled={disabled}
      {...props}
    >
      <Text>Artsy</Text>
      <ArtsyPriceBox price={askingPriceCents} />
    </StyledArtsyButton>
  )
}

const TextWrapper: React.FunctionComponent<{
  color?: string
  children: string
}> = ({ color = 'white100', children, ...restProps }) => {
  return (
    <Sans color={color} {...restProps} size="5">
      {children}
    </Sans>
  )
}

export const Text = styled(TextWrapper)``

export const PriceText: React.FunctionComponent<{
  color?: string
  price: number
}> = ({ color = 'white100', price }) => {
  return (
    <Sans px={2} color={color} size="5">
      {formatMoneyWithoutCents(price / 100)}
    </Sans>
  )
}

const AskingPriceWrapper: React.FunctionComponent<{
  price: number
}> = props => {
  const { price, ...restProps } = props
  return (
    <Box px={2} py={1} bg="purple100" color="white100" {...restProps}>
      <Sans size="5">{formatMoneyWithoutCents(price / 100)}</Sans>
    </Box>
  )
}

export const ArtsyPriceBox = styled(AskingPriceWrapper)`
  border-radius: 2px;
`
const StyledArtsyButton = styled(BigButton)<{ flash: boolean }>`
  ${Text} {
    color: ${color('purple100')};
  }

  &:hover {
    background: ${color('purple100')};
    border-color: ${color('white100')};
    ${ArtsyPriceBox} {
      background: ${color('white100')};
      color: ${color('purple100')};
    }
    ${Text} {
      color: ${color('white100')};
    }
  }

  &:active {
    background: ${color('purple100')};
    border-color: ${color('white100')};
    ${ArtsyPriceBox} {
      background: ${color('purple100')};
      * {
        color: ${color('purple100')};
      }
    }
    ${Text} {
      color: ${color('purple100')};
    }
  }

  &:disabled {
    background: ${color('black80')};
    border: 4px solid ${color('black60')};
    color: ${color('black60')};
    ${ArtsyPriceBox} {
      visibility: hidden;
    }
    ${Text} {
      color: ${color('black60')};
    }
  }

  ${({ flash }) => flash && `animation: 125ms blink step-end infinite;`};
  @keyframes blink {
    from,
    to {
      background: #fff;
      color: #000 !important;
      border-color: #000 !important;
    }
    50% {
      background: #6e1fff;
      color: #fff !important;
      border-color: #fff !important;
    }
  }
`

const confirmingStyle = css`
  background: ${color('green100')};
  color: ${color('black100')};
  border-color: ${color('green100')};
  &:hover {
    border-color: ${color('white100')};
  }
`
