/**
 * Universal wrapper around bare browser WebSocket API that makes interacting
 * with Causality nicer.
 *
 * TODO: Bring this into Redux flow
 */

import * as appActions from 'actions/appActions'
import EventEmitter from 'events'
import createLogger from 'utils/logger'
import invariant from 'invariant'
import initializeSocketEvents, { events } from 'actions/socketEvents'
import uuid from 'uuid/v1'
import { assign } from 'lodash'
import { data as env } from 'sharify'

const logger = createLogger('utils/socket.js')

class WebsocketError extends Error {
  constructor(message: string) {
    super(message)

    this.name = this.constructor.name
    Object.setPrototypeOf(this, WebsocketError.prototype)

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
}

const Socket = (() => {
  let sharedSocket

  const connect = async props => {
    const socket = await connectToSocket(props)
    initializeSocketEvents(socket, props.dispatch)
    return socket
  }
  const sharedSocketOrConnect = async props => {
    if (!sharedSocket) {
      sharedSocket = await connect(props)
    }
    return sharedSocket
  }

  return {
    sharedSocketOrConnect,
    connect,
  }
})()

export default Socket

const defaultSocketCreator = url => {
  const websocket = new window.WebSocket(url)
  return websocket
}

/**
 * Websocket connection state constants
 */
const connectionState = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
}

/**
 * Interval time, in milliseconds, that we should send connection state to
 * websocket server.
 */
const PING_INTERVAL_TIME = 1000

/**
 * Create a new WebSocket connection and instantiate helper for communicating
 * between Prediction and Causality.
 *
 * @param  {Object} props - Connection props
 * @param  {String} props.causalityJWT - A JWT token for authenticating socket
 * @param  {Function} props.dispatch - A Redux dispatch function
 * @param  {String} props.role - The users role, either 'bidder' or 'observer'
 * @param  {String} props.saleId - The Mongoid of a sale
 * @return {Promise} - If connection is successful, resolve with the socket
 *                     instance, otherwise reject with the error.
 */
function connectToSocket({
  causalityJWT,
  dispatch,
  role,
  saleId,
  url,
  socketCreator,
}) {
  invariant(
    causalityJWT && dispatch && role && saleId,
    '(utils/socket.js) ' +
      'Error initializing socket: initialization params must contain a causalityJWT, ' +
      'dispatch, role, and saleId.'
  )

  const socket = new EventEmitter() as any
  const socketUrl = url || env.CAUSALITY_WEBSOCKET_URL
  const webSocketUrl = socketUrl + `?saleId=${saleId}`
  const ws = (socketCreator || defaultSocketCreator)(webSocketUrl)

  logger.log('Socket: Connecting to ', webSocketUrl)

  ws.onclose = () => {
    socket.emit('disconnect')
    logger.warn('Disconnected.')
  }

  /**
   * Ping
   *
   * TODO: Clear interval once connection has closed
   */
  const pingInterval = setInterval(() => {
    const isOpen = ws.readyState === connectionState.OPEN

    if (isOpen) {
      ws.send(2)
    } else if (
      ws.readyState === connectionState.CLOSING ||
      ws.readyState === connectionState.CLOSED
    ) {
      clearInterval(pingInterval)
    }
  }, PING_INTERVAL_TIME)

  /**
   * Convert any websocket messages into events (ignoring heartbeat)
   *
   * @param  {Object} event
   * @return {*}
   */
  ws.onmessage = event => {
    const ping = event.data === '3'

    if (ping) {
      return
    }

    const res = JSON.parse(event.data)

    if (!res.type) {
      return logger.warn('#onmessage: Unknown event', res)
    }

    logger.warn('#onmessage: Received over websocket', res)
    socket.emit(res.type, res)
    socket.emit('*', res)
  }

  /**
   * Add a `send` method for conveniently sending json with a unique key and
   * user agent data if sending an event.
   *
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  socket.send = data => {
    const payload = assign({ key: uuid() }, data)
    if (payload.event) {
      payload.event.clientMetadata = {
        'User-Agent': window.navigator.userAgent,
      }
    }
    ws.send(JSON.stringify(payload))

    // Defined via webpack
    // @ts-ignore
    if (__VERBOSE__) {
      logger.warn('#send ', payload)
    }
    return payload
  }

  /**
   * Add an `ask` method for conveniently sending json with a unique key and
   * user agent data if sending an event.
   *
   * @param  {[Object]} data [Data to send to Cauasality]
   * @return {[Promise]}     [Promise that resolves or rejects based on
   *                          Causality's response to the socket event]
   */
  socket.ask = data => {
    const payload = socket.send(data)

    return new Promise((resolve, reject) => {
      const commandSuccessListener = data => {
        if (payload.key === data.key) {
          socket.removeListener(
            events.CommandSuccessful,
            commandSuccessListener
          )
          socket.removeListener(events.CommandFailed, commandFailedListener)
          resolve(data)
        }
      }
      const commandFailedListener = data => {
        if (payload.key === data.key) {
          socket.removeListener(
            events.CommandSuccessful,
            commandSuccessListener
          )
          socket.removeListener(events.CommandFailed, commandFailedListener)
          reject(data)
        }
      }
      socket.on(events.CommandSuccessful, commandSuccessListener)
      socket.on(events.CommandFailed, commandFailedListener)
    })
  }

  /**
   * Resolve a promise if successfully authed, or reject if unauthed
   */
  return new Promise((resolve, reject) => {
    const token = causalityJWT
    /**
     * Handler for WebSocket open events
     */
    ws.onopen = () => {
      logger.log('Opening socket connection')

      if (!token) {
        return reject(
          new WebsocketError(
            'Error connecting to websocket: a JWT token is required for authentication.'
          )
        )
      }

      logger.log('Authorizing socket connection...')

      socket.send({
        type: 'Authorize',
        jwt: token,
      })

      socket.once('InitialFullSaleState', ({ operatorConnected }) => {
        if (!operatorConnected) {
          logger.error('Error connecting: OperatorDown')
          reject(new WebsocketError('OperatorDown'))
        } else {
          logger.log('Authorized connection.')
          resolve(socket)
        }
      })

      // ERROR
      socket.once('ConnectionUnauthorized', () => {
        logger.error('ConnectionUnauthorized')
        reject(new WebsocketError('WebSocket connection unauthorized.'))
      })
    }

    ws.onerror = error => {
      logger.error('Error ', error)
      if (error.message === 'OperatorDown') {
        dispatch(
          appActions.operatorConnected({
            data: {
              operatorConnected: false,
            },
          })
        )
      } else {
        /**
         * FIXME: We should eventually implement a full-screen overlay indicating
         * error and a RETRY button.
         */
        logger.error(
          'Error initializing socket.',
          new WebsocketError(error.message)
        )
      }

      reject(new WebsocketError(error.message))
    }
  })
}
