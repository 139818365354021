import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import './DisabledButton.styl'

export default function DisabledButton({ message }) {
  const b = block('disabled-button')

  return (
    <div className={b()}>
      <button
        disabled
        className={b.builder().mix('avant-garde-button-black')()}
      >
        {message}
      </button>
    </div>
  )
}

DisabledButton.propTypes = {
  message: PropTypes.string.isRequired,
}
