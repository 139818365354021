import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import styled from 'styled-components'
import Icon from 'assets/icons'

import './LiveLot.styl'
import { ArtworkThumbnail } from './ArtworkThumbnail'

export default function LiveLot(_, context) {
  const {
    currentLot,
    currentSaleArtwork,
    deviceMode,
    formatMoneyWithoutCents,
    onBackToLiveLot,
  } = context

  const b = block('live-lot')

  return (
    <div
      className={b({ 'device-mode': deviceMode })}
      onClick={() => onBackToLiveLot()}
    >
      <ArtworkThumbnailWithBorder
        m={0}
        width={34}
        height={34}
        src={currentSaleArtwork.artwork.image.url}
      />
      <div className={b('caption')}>
        <div className={b('lot-label')}>Live Lot</div>
        {currentSaleArtwork.artwork.artist &&
          currentSaleArtwork.artwork.artistNames && (
            <div className={b('lot-artist-label')}>
              {currentSaleArtwork.artwork.artistNames}
            </div>
          )}
      </div>
      <div className={b('gavel-info')}>
        <div className={b('lot-price')}>
          {formatMoneyWithoutCents(
            currentLot.derivedLotState.askingPriceCents / 100,
            currentSaleArtwork.symbol
          )}
        </div>
        <Icon name="gavel" className={b('gavel')} />
      </div>
    </div>
  )
}

// TODO: Replace with <BorderBox> once adding ability to change color using props
const ArtworkThumbnailWithBorder = styled(ArtworkThumbnail)`
  border: 1px solid #fff;
`

LiveLot.contextTypes = {
  currentLot: PropTypes.object.isRequired,
  currentSaleArtwork: PropTypes.object.isRequired,
  deviceMode: PropTypes.string.isRequired,
  formatMoneyWithoutCents: PropTypes.func.isRequired,
  onBackToLiveLot: PropTypes.func.isRequired,
}
