import reset from 'styled-reset'
import { createGlobalStyle } from 'styled-components'
import { themeProps } from '@artsy/palette'

export const theme = {
  ...themeProps,
  colors: {
    ...themeProps.colors,

    /** 70% black  */
    black70: '#777',
    /** half-transparent purple (light purple) */
    lightPurple: 'rgba(98,46,236,.5)',
    /** A little darker green that gives a higher contrast */
    green100: '#0EDA83',
    /** 80 yellow (light yellow), avoid future use */
    yellow80: '#F1CD39',
  },
  delays: {
    footingTextFade: '0.3s',
    footingIndicator: '0.15s',
  },
  fontFamily: {
    ...themeProps.fontFamily,
    unica: 'font-family: Helvetica, arial, sans-serif;',
  },
  fontSizes: {
    xsmall: '8px',
    small: '12px',
    medium: '14px',
    large: '22px',
  },
  margins: {
    xsmall: '5px',
    small: '10px',
    medium: '15px',
    large: '20px',
  },
  utils: {
    center: `
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    stretch: `
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    flexEnd: `
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `,
    zBelowModalBackground: `
      z-index: 1;
    `,
    zModalBackground: `
      z-index: 2;
    `,
    zAboveModalBackground: `
      z-index: 2;
    `,
    border: ({ size = 2, color = '#fff' } = {}) => `
      ${size}px solid ${color};
    `,
  },
}

export const OperatorGlobalStyles = createGlobalStyle`
  ${reset};

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: ${theme.colors.black100};
    color: ${theme.colors.black100};
    font-family: 'avant-garde', Helvetica, arial, sans-serif;
  }

  input,
  button {
    font-family: 'avant-garde', Helvetica, arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    text-transform: inherit;
  }

  input {
    background: #fff;
    border: 2px solid #000;
    font-size: 12px;
    outline: none;

    &:focus {
      border-color: #622eec;
    }
  }

  input[type='text'],
  input[type='password'] {
    box-sizing: border-box;
  }

  button {
    padding: 0;
    margin: 0;
    border: 0;
  }
`
