import * as appActions from 'actions/appActions'
import BidderLayout from 'apps/bidder/components/BidderLayout'
import Socket from 'utils/socket'
import { browserHistory } from 'react-router'
import { controller, getProps } from 'react-redux-controller'
import { data as env } from 'sharify'
import { isServer } from 'utils/environment'
import { selectorBundles } from 'selectors'
import {
  trackBackToLiveLot,
  trackHideLotInfo,
  trackRegisterToBid,
  trackShowLotInfo,
  trackUpdateSelectedMaxBid,
} from 'utils/analytics'

let socket

function* initialize() {
  if (isServer) {
    return
  }

  const { causalityJWT, dispatch, role, sale } = yield getProps

  socket = yield Socket.sharedSocketOrConnect({
    causalityJWT,
    dispatch,
    role,
    saleId: sale.internalID,
  })
}

function* onBackToLiveLot() {
  const { sale } = yield getProps
  const path = `/${sale.slug}`
  trackBackToLiveLot({ path })
  browserHistory.replace(path)
}

function* onSelectLot(saleArtwork) {
  const { dispatch } = yield getProps
  dispatch(appActions.selectLot({ lotId: saleArtwork.internalID }))
}

function* onRegisterToBid(saleId) {
  const { isMobile } = yield getProps

  let loginUrl = `${env.FORCE_BASE_URL}/auction-registration/${saleId}`

  // Mobile web currently requires an extra redirect_uri for post-registration
  if (isMobile) {
    const redirectUrl = `${env.PREDICTION_BASE_URL}/${saleId}/login`
    loginUrl = `${loginUrl}?redirectTo=${redirectUrl}`
  }
  trackRegisterToBid({ destinationPath: loginUrl })
  location.assign(loginUrl)
}

function* onPlaceBidOnLot(lotId, amountCents) {
  const { bidderId, paddleNumber, userId, dispatch } = yield getProps

  socket.send({
    type: 'PostEvent',
    event: {
      type: 'FirstPriceBidPlaced',
      lotId,
      amountCents,
      bidder: {
        type: 'ArtsyBidder',
        bidderId,
        userId,
        paddleNumber,
      },
    },
  })

  dispatch(appActions.bidSent())
}

function* onToggleArtworkList(isUpcoming) {
  const { dispatch } = yield getProps
  dispatch(appActions.toggleArtworkList(isUpcoming))
}

function* onCloseEigenModal() {
  const { dispatch } = yield getProps
  dispatch(appActions.closeEigenModal())
}

function* onToggleLotsModal() {
  const { dispatch } = yield getProps
  dispatch(appActions.toggleLotsModal())
}

function* showLotInfo() {
  const { sale, selectedLot } = yield getProps
  const destinationPath = `/${sale.slug}/${selectedLot.id}/info`
  trackShowLotInfo({ destinationPath })
  browserHistory.push(destinationPath)
}

function* hideLotInfo() {
  const { sale, selectedLot } = yield getProps
  const destinationPath = `/${sale.slug}/${selectedLot.id}`
  trackHideLotInfo({ destinationPath })
  browserHistory.push(destinationPath)
}

function* onToggleAuctionInfoModal() {
  const { dispatch } = yield getProps
  dispatch(appActions.toggleAuctionInfoModal())
}

function* onChangeSelectedMaxBid({ prevMaxBid, newMaxBid, lotId }) {
  const { dispatch } = yield getProps
  trackUpdateSelectedMaxBid({ prevMaxBid, newMaxBid })
  dispatch(appActions.updateSelectedMaxBid(newMaxBid, lotId))
}

function* onPlaceMaxBid(lotId, amountCents) {
  const { bidderId, paddleNumber, userId, dispatch } = yield getProps

  socket.send({
    type: 'PostEvent',
    event: {
      type: 'SecondPriceBidPlaced',
      lotId,
      amountCents,
      bidder: {
        type: 'ArtsyBidder',
        bidderId,
        userId,
        paddleNumber,
      },
    },
  })

  dispatch(appActions.bidSent())
}

const bidderMethods = {
  initialize,
  onBackToLiveLot,
  onChangeSelectedMaxBid,
  onCloseEigenModal,
  onPlaceBidOnLot,
  onPlaceMaxBid,
  onRegisterToBid,
  onSelectLot,
  onToggleArtworkList,
  onToggleAuctionInfoModal,
  onToggleLotsModal,
  showLotInfo,
  hideLotInfo,
}

export const BidderController = controller(
  BidderLayout,
  bidderMethods,
  selectorBundles
)
