import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import LotEventListItem from 'components/LotEventListItem'
import { pullAt } from 'lodash'
import './LotEventList.styl'

export default function LotEventList(_, context) {
  const {
    viewMode,
    currentLot,
    selectedLot,
    deviceMode,
    currentSaleArtwork,
    selectedSaleArtwork,
    filteredLotEvents,
  } = context
  const b = block('lot-event-list')
  const lot = viewMode === 'operator' ? currentLot : selectedLot
  const saleArtwork =
    viewMode === 'operator' ? currentSaleArtwork : selectedSaleArtwork
  const lotEvents = filteredLotEvents(lot)

  let highestBidElement = <span className={b('highest-bid-dash')}>– –</span>
  if (viewMode === 'operator' && lot.derivedLotState.winningBidEventId) {
    const topBid = pullAt(lotEvents, 0)[0]
    highestBidElement = (
      <LotEventListItem
        key={topBid.eventId}
        lot={lot}
        lotEvent={topBid}
        saleArtwork={saleArtwork}
      />
    )
  }

  return (
    <div className={b({ 'view-mode': viewMode, 'device-mode': deviceMode })}>
      {viewMode === 'operator' ? (
        <div className={b('highest-bid-wrap')}>
          <span className={b('highest-bid')}>Highest bid</span>
          {highestBidElement}
        </div>
      ) : null}
      {lotEvents.map(lotEvent => (
        <LotEventListItem
          key={lotEvent.eventId}
          lot={lot}
          lotEvent={lotEvent}
          saleArtwork={saleArtwork}
        />
      ))}
    </div>
  )
}

LotEventList.contextTypes = {
  viewMode: PropTypes.string.isRequired,
  currentLot: PropTypes.object.isRequired,
  currentSaleArtwork: PropTypes.object.isRequired,
  selectedLot: PropTypes.object.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
  deviceMode: PropTypes.string.isRequired,
  filteredLotEvents: PropTypes.func.isRequired,
}
