import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import './BidButton.styl'

export default function BidButton(_, context) {
  const {
    bidError,
    mySelectedLotState,
    onPlaceBidOnLot,
    selectedLotPrice,
    pendingBidPrice,
    selectedLot,
    selectedSaleArtwork,
    formatMoneyWithoutCents,
    isPendingBid,
    onRegisterToBid,
    sale,
  } = context
  const b = block('bid-button')

  const bidButtonModifier = { [mySelectedLotState]: true }
  const bidButtonMessage = {
    opened: `Bid ${formatMoneyWithoutCents(
      selectedLotPrice / 100,
      selectedSaleArtwork.symbol
    )}`,
    winning: 'You’re the highest bidder',
    notAcceptedYet: `Bid ${formatMoneyWithoutCents(
      pendingBidPrice / 100,
      selectedSaleArtwork.symbol
    )}`,
    outbid: 'Outbid',
    losing: `Bid ${formatMoneyWithoutCents(
      selectedLotPrice / 100,
      selectedSaleArtwork.symbol
    )}`,
    sold: 'Sold',
    passed: 'Lot Passed',
    closed: 'Waiting for auctioneer...',
  }[mySelectedLotState]

  return (
    <div className={b()}>
      <a
        onClick={() => {
          if (isPendingBid) {
            return
          }
          if (mySelectedLotState === 'observer') {
            onRegisterToBid(sale.slug)
          } else {
            onPlaceBidOnLot(selectedLot.id, selectedLotPrice)
          }
        }}
        className={b
          .builder()(bidButtonModifier)
          .mix('avant-garde-button-black')()}
      >
        {bidButtonMessage}
      </a>
      {bidError && <p className={b('error')}>{bidError}</p>}
    </div>
  )
}

BidButton.contextTypes = {
  sale: PropTypes.object.isRequired,
  bidError: PropTypes.string,
  mySelectedLotState: PropTypes.string.isRequired,
  onPlaceBidOnLot: PropTypes.func.isRequired,
  selectedLot: PropTypes.object.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
  selectedLotPrice: PropTypes.number.isRequired,
  pendingBidPrice: PropTypes.number.isRequired,
  formatMoneyWithoutCents: PropTypes.func.isRequired,
  isPendingBid: PropTypes.bool.isRequired,
  onRegisterToBid: PropTypes.func.isRequired,
}
