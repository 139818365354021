import PropTypes from 'prop-types'
import React from 'react'
import block from 'bem-cn-lite'
import { isEmpty } from 'lodash'
import './ErrorModal.styl'

export default function ErrorModal(_, context) {
  const { errorModal, retryCountdown } = context
  const b = block('error-modal')
  if (isEmpty(errorModal)) {
    return <div />
  }
  return (
    <div className={b()}>
      <div className={b('content')}>
        <h1 className={b('h1')}>{errorModal.header}</h1>
        <h2 className={b('h2')}>{errorModal.subHeader}</h2>
        <div>
          {errorModal.header.match('lost contact') ? (
            <div className={b('retry')}>
              {retryCountdown > 0 ? retryCountdown : <span>&nbsp;</span>}
              <div className={b('retry-ring')} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

ErrorModal.contextTypes = {
  errorModal: PropTypes.object.isRequired,
  retryCountdown: PropTypes.number.isRequired,
}
