import { currentLot } from 'selectors/lotSelectors'
import {
  hasCompetitiveArtsyBid,
  isExternalOperator,
} from 'selectors/operatorUISelectors'

export const selectMaxBidAmountCents: StateMapper<
  number | undefined
> = state => {
  const {
    derivedLotState: {
      floorAskingPriceCents,
      canArtsyDoubleBid,
      maxBidAmountCents,
    },
  } = currentLot(state) as any

  return maxBidAmountCents >= floorAskingPriceCents &&
    (!isExternalOperator(state) || canArtsyDoubleBid)
    ? maxBidAmountCents
    : undefined
}

export const selectMinBidAmountCents: StateMapper<
  number | undefined
> = state => {
  const {
    derivedLotState: { sellingPriceCents },
  } = currentLot(state) as any

  return hasCompetitiveArtsyBid(state) ? sellingPriceCents : undefined
}
