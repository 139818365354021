import React from 'react'
import PropTypes from 'prop-types'
import block from 'bem-cn-lite'
import BuyersPremium from 'components/BuyersPremium'
import './LotDetails.styl'
import { LotArtworkTombstone } from './LotArtworkTombstone'

export default function LotDetails(_, { selectedSaleArtwork }) {
  const b = block('lot-details')

  return (
    <div className={b()}>
      <LotArtworkTombstone />
      <BuyersPremium />
    </div>
  )
}

LotDetails.contextTypes = {
  selectedSaleArtwork: PropTypes.object.isRequired,
}
