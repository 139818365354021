import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { Modal } from './Modal'

export const StartSaleModal = (_, context) => {
  const { sale } = context
  const time = moment.tz(sale.liveStartAt, 'America/New_York')
  const formattedTime = time.format('h:mmA z')
  return (
    <Modal>
      <Container>
        <Content>
          {sale.name} will begin at:
          <br />
          {formattedTime}
          <Desc>
            You're connected. You may open the first lot <br /> when the sale
            begins.
          </Desc>
        </Content>
      </Container>
    </Modal>
  )
}

const Container = styled.div``

// FIXME: Start to use artsy style system. This modal styles are different from others
const Content = styled.div`
  ${({ theme }) => theme.fontFamily.unica};
  text-transform: none;
  padding: calc(${p => p.theme.margins.large} * 2);
  line-height: 26px;
  font-size: 18px;
  max-width: 450px;
`

const Desc = styled.div`
  ${({ theme }) => theme.fontFamily.unica};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.black60};
  margin-top: ${({ theme }) => theme.margins.small};
  line-height: 19px;
  text-transform: none;
`

StartSaleModal.contextTypes = {
  sale: PropTypes.object.isRequired,
}
