import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'assets/icons'
import block from 'bem-cn-lite'
import { Box } from '@artsy/palette'

import './AuctionInfo.styl'
import { ArtworkThumbnail } from 'apps/bidder/components/ArtworkThumbnail'

export default function AuctionInfo(_, context) {
  const {
    currentLot,
    currentSaleArtwork,
    formatMoneyWithoutCents,
    onBackToLiveLot,
    sale,
    selectedSaleArtwork,
  } = context

  const b = block('auction-info')

  return (
    <div className={b()}>
      <div className={b('header')}>
        <img className={b('partner-icon')} src={sale.partner_icon_url} />
        <h1>{sale.name}</h1>
      </div>
      <div
        className={
          'current-lot-info' +
          (currentSaleArtwork === selectedSaleArtwork ? ' is-current' : '')
        }
      >
        <h4>Current Lot</h4>
        <h5>{currentSaleArtwork.lotLabel}</h5>
        <div className="artwork-list-item artwork-list-item--info">
          {currentSaleArtwork.artwork.image ? (
            <ArtworkThumbnail
              mr={3}
              width={50}
              height={50}
              src={currentSaleArtwork.artwork.image.url}
            />
          ) : (
            <Box mr={3} width={50} height={50} />
          )}
          <div className="artwork-list-item__caption">
            {currentSaleArtwork.artwork.artist &&
            currentSaleArtwork.artwork.artistNames ? (
              <h3>{currentSaleArtwork.artwork.artistNames}</h3>
            ) : null}
            <h2>
              {formatMoneyWithoutCents(
                currentLot.derivedLotState.askingPriceCents / 100,
                selectedSaleArtwork.symbol
              )}
            </h2>
          </div>
          <Icon name="gavel" className="current-lot-info__gavel" />
        </div>
        <div className="current-lot-info-actions">
          <a
            onClick={() => onBackToLiveLot()}
            className="avant-garde-button-white auction-info__back-button"
          >
            Go back to live lot
          </a>
        </div>
      </div>
    </div>
  )
}

AuctionInfo.contextTypes = {
  sale: PropTypes.object.isRequired,
  currentLot: PropTypes.object.isRequired,
  currentSaleArtwork: PropTypes.object.isRequired,
  selectedSaleArtwork: PropTypes.object.isRequired,
  onBackToLiveLot: PropTypes.func.isRequired,
  formatMoneyWithoutCents: PropTypes.func.isRequired,
}
