import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

interface AutoscrollProps {
  enabled?: boolean
  scrollIntoViewOptions?: any
  focusIndex?: number
  nodeSelector?: (node, index) => void
  updateScroll?: string
  wrapperComponent?: (props) => JSX.Element
  errorRuntimeAction?: (props) => object
}

/**
 * Helper for applying auto-scrolling behavior to its contents.
 *
 * Example:
 * <Autoscroll focusIndex={selectedItem}>
 *   <ul>
 *     <li>Item 1</li>
 *     <li>Item 1</li>
 *     <li>Item 1</li>
 *   </ul>
 * </Autoscroll>
 */
export class Autoscroll extends React.Component<AutoscrollProps> {
  static ALWAYS = 'ALWAYS'
  static FOCUS_CHANGE = 'FOCUS_CHANGE'

  static propTypes = {
    /** The contents to scroll. */
    children: PropTypes.node,
    /** Set to false to prevent scrolling dynamically. */
    enabled: PropTypes.bool,
    /**
     * If rendering a list of elements under the Autoscroll wrapper, this
     * selects the element to scroll to. The assumption of `focusIndex` is that
     * the first child node is the scrolling container, and its children are
     * the indexed contents. Use `nodeSelector` to modify this assumption.
     */
    focusIndex: PropTypes.number,
    /**
     * Custom logic for selecting the target node to scroll into view. The
     * function is passed the DOM element of the Autoscroll wrapper and is
     * responsible for returning the target DOM element.
     */
    nodeSelector: PropTypes.func,
    /**
     * The scroll options.
     * See: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
     */
    scrollIntoViewOptions: PropTypes.shape({
      behavior: PropTypes.string,
      block: PropTypes.string,
      inline: PropTypes.string,
    }),
    /**
     * Dynamically updates the scroll position based on props changes. Options:
     *
     * Autoscroll.ALWAYS: update the scroll position on all re-renders.
     * Autoscroll.FOCUS_CHANGE: update the scroll position when `focusIndex`
     *   has changed.
     */
    updateScroll: PropTypes.string,
    /** A custom wrapper component or element name to use. Defaults to div. */
    wrapperComponent: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  }

  rerender = false

  autoscroll() {
    if (this.props.enabled !== false) {
      const node = this.nodeSelector(ReactDOM.findDOMNode(this))

      // Guard against null node in case lookup failed. Guard against missing
      // scrollIntoView because jsDom doesn't have this method, so tests fail.
      node &&
        node.scrollIntoView &&
        node.scrollIntoView(this.props.scrollIntoViewOptions)
    }
  }

  nodeSelector(parentNode) {
    if (this.props.focusIndex) {
      if (this.props.nodeSelector) {
        return this.props.nodeSelector(parentNode, this.props.focusIndex)
      } else {
        return parentNode.children[0].children[this.props.focusIndex]
      }
    } else {
      if (this.props.nodeSelector) {
        return this.props.nodeSelector(parentNode, this.props.focusIndex)
      } else {
        return parentNode.children[0]
      }
    }
  }

  componentDidMount() {
    this.autoscroll()
  }

  componentWillReceiveProps({ focusIndex, updateScroll }) {
    if (
      updateScroll === Autoscroll.FOCUS_CHANGE &&
      this.props.focusIndex !== focusIndex
    ) {
      this.rerender = true
    }
  }

  componentDidUpdate() {
    if (this.props.updateScroll === Autoscroll.ALWAYS || this.rerender) {
      this.rerender = false
      this.autoscroll()
    }
  }

  render() {
    const Wrapper = this.props.wrapperComponent || 'div'
    return <Wrapper>{this.props.children}</Wrapper>
  }
}
