import PropTypes from 'prop-types'
import { get } from 'lodash'

export const sales = state => {
  return state.sales.map(({ node }) => node)
}
sales.propType = PropTypes.array.isRequired

export const isAdmin = state => get(state, 'me.type', false) === 'Admin'
isAdmin.propType = PropTypes.bool.isRequired
