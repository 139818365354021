/**
 *
 * @param {string} number a number string to add comma to.
 * @param {string} prefix (optional)
 */
export const addCommaToNumber = (num, prefix?: string) => {
  const numberWithCommas = num
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return prefix ? prefix + numberWithCommas : numberWithCommas
}

/**
 *
 * @param {event} e React SynteticEvent or JS native event
 * @param {*} prefix (optional)
 */
export const addCommaToInput = (event, prefix?: string) => {
  // ignore arrow keys
  if (event.keyCode >= 37 && event.keyCode <= 40) return
  const beforAddingComma = event.target.value
  const cursorPos = event.target.selectionStart
  const commaInserted = addCommaToNumber(event.target.value, prefix)
  event.target.value = commaInserted
  const addedCommas = commaInserted.length - beforAddingComma.length
  // if new commas are added, move the cursor forward (right)
  const newPos = cursorPos + addedCommas
  event.target.selectionStart = newPos
  event.target.selectionEnd = newPos
}
