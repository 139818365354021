import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Avatar, Box, Flex } from '@artsy/palette'
import { Dispatch } from 'redux'

import { Button } from 'apps/operator/elements/Buttons'
import { presentEstimate } from 'apps/operator/presenters'
import { currentLot, currentSaleArtwork } from 'selectors/lotSelectors'
import { formatMoneyWithoutCents } from 'utils/formatHelper'
import { Text } from 'apps/operator/elements'
import {
  selectMaxBidAmountCents,
  selectMinBidAmountCents,
} from 'apps/operator/selectors/headerSelectors'
import { SaleState, saleState } from 'apps/operator/selectors/stateSelectors'

// @ts-ignore
import ArtsyLogo from 'assets/img/artsy-logo-square-white-transparent.svg'
import {
  undoLastLotOperationAction,
  toggleSaleOnHold,
} from 'apps/operator/operatorController'

interface Props {
  currentLot: Lot
  currentSaleArtwork: SaleArtwork
  dispatch?: Dispatch<any>
  isToggledSaleOnHold: boolean
  maxBidAmountCents?: number
  minBidAmountCents?: number
  sale: { internalID: string }
  saleState: SaleState
}

const formatPriceCent = amount =>
  amount ? formatMoneyWithoutCents(amount / 100) : '--'

const mapStateToProps = state => ({
  currentSaleArtwork: currentSaleArtwork(state),
  maxBidAmountCents: selectMaxBidAmountCents(state),
  minBidAmountCents: selectMinBidAmountCents(state),
  currentLot: currentLot(state),
  saleState: saleState(state),
  sale: state.sale,
  isToggledSaleOnHold: state.isToggledSaleOnHold,
})

export const _Header: React.FunctionComponent<Props> = ({
  currentSaleArtwork,
  minBidAmountCents,
  maxBidAmountCents,
  currentLot,
  dispatch,
  saleState,
  sale,
  isToggledSaleOnHold,
}) => {
  let pauseSaleButtonColor: string,
    undoButtonColor: string,
    buttonTextColor: string

  if (saleState === SaleState.OPENING_LOT) {
    pauseSaleButtonColor = 'yellow100'
    undoButtonColor = 'red100'
    buttonTextColor = 'black100'
  } else {
    pauseSaleButtonColor = 'black80'
    undoButtonColor = 'black80'
    buttonTextColor = 'white100'
  }

  const estimate = currentLot.derivedLotState.estimate || {}

  return (
    <Flex my="2" alignItems="center" position="relative">
      <ArtsyLogo width={30} height={30} />

      <Flex ml="1" flexGrow={1}>
        <Avatar size="xs" src={currentSaleArtwork.artwork.image?.url} />

        <Flex
          ml="1"
          flexDirection="column"
          justifyContent="space-evenly"
          maxWidth={240}
        >
          <Text>
            Lot {currentSaleArtwork.lotLabel} &middot;{' '}
            {presentEstimate(estimate)}
          </Text>

          {currentSaleArtwork.artwork &&
            currentSaleArtwork.artwork.artistNames && (
              <TextWithEllipsis>
                {currentSaleArtwork.artwork.artistNames}
              </TextWithEllipsis>
            )}
        </Flex>
      </Flex>

      <Box position="absolute" top="8px" left="340px">
        <Text>Min: {formatPriceCent(minBidAmountCents)}</Text>
        <Text>Max: {formatPriceCent(maxBidAmountCents)}</Text>
      </Box>

      <Flex alignItems="center">
        <HeaderButton
          mr="6px"
          bg={pauseSaleButtonColor}
          borderColor={pauseSaleButtonColor}
          onClick={() =>
            toggleSaleOnHold({
              isToggledSaleOnHold,
              sale,
            })
          }
        >
          <Text color={buttonTextColor}>
            {isToggledSaleOnHold ? 'Resume' : 'Pause'} Sale
          </Text>
        </HeaderButton>
        {saleState !== SaleState.OPENING_LOT && (
          <HeaderButton
            bg={undoButtonColor}
            borderColor={undoButtonColor}
            onClick={() => {
              undoLastLotOperationAction({
                currentLot,
                dispatch,
              })
            }}
          >
            <Text color={buttonTextColor}>Undo</Text>
          </HeaderButton>
        )}
      </Flex>
    </Flex>
  )
}

export const HeaderButton = styled(Button)`
  height: 36px;
  width: 108px;
  font-weight: 500;
`

const TextWithEllipsis = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Header = connect(mapStateToProps)(_Header)
