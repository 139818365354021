import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { GlobalStyles, theme } from './styles'

import MonitorAuctionInfo from './MonitorAuctionInfo'
import MonitorModePicker from './MonitorModePicker'
import MonitorDetail from './MonitorDetail'
import './MonitorLayout.styl'

export default function MonitorLayout() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Container>
          <Overview>
            <MonitorAuctionInfo />
            <MonitorModePicker />
          </Overview>
          <MonitorDetail />
        </Container>
      </ThemeProvider>
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  height: 100vh;
  overflow: hidden;
  padding: 20px;
`

const Overview = styled.aside`
  margin-bottom: 20px;
`
