import { actions } from 'actions/appActions'
import { get } from 'lodash'
import { track } from 'utils/tracking'

export const trackAction = (prevState, nextState, action) => {
  switch (action.type) {
    case actions.TOGGLE_ARTWORK_LIST: {
      trackToggleArtworkListAction({ prevState, nextState, action })
      break
    }
    // cases for a response to a causality command
    case actions.COMMAND_FAILED: {
      const { data } = action
      const causalityEventType = get(data, 'event.type', false)
      switch (causalityEventType) {
        case 'SecondPriceBidPlaced':
        case 'FirstPriceBidPlaced': {
          if (data.wasAccepted === false) {
            trackBidFailedAction({ action })
          }
          break
        }
      }
      break
    }
    case actions.COMMAND_SUCCESSFUL: {
      const { data } = action
      const causalityEventType = get(data, 'event.type', false)
      switch (causalityEventType) {
        case 'SecondPriceBidPlaced':
        case 'FirstPriceBidPlaced': {
          trackBidPlacedAction({ nextState, action })
          break
        }
      }
    }
  }
}

export const trackBackToLiveLot = ({ path }) => {
  track('click', {
    context_module: 'current lot',
    destination_path: path,
    flow: 'live auctions',
    label: 'go back to live lot',
    type: 'button',
  })
}
export const trackClickLink = ({ label, contextModule, destinationPath }) => {
  track('click', {
    type: 'link',
    label: label,
    flow: 'live auctions',
    context_module: contextModule,
    destination_path: destinationPath,
  })
}
export const trackHideLotInfo = ({ destinationPath }) => {
  track('click', {
    type: 'dismiss',
    label: 'lot information',
    flow: 'live auctions',
    context_module: 'lots',
    destination_path: destinationPath,
  })
}

export const trackRegisterToBid = ({ destinationPath }) => {
  track('click', {
    type: 'button',
    label: 'register to bid',
    flow: 'live auctions',
    context_module: 'bidding',
    destination_path: destinationPath,
  })
}

export const trackSelectLot = ({ destinationPath }) => {
  track('click', {
    type: 'list item',
    label: 'lot',
    flow: 'live auctions',
    context_module: 'lots',
    destination_path: destinationPath,
  })
}

export const trackShowLotInfo = ({ destinationPath }) => {
  track('click', {
    type: 'button',
    label: 'lot information',
    flow: 'live auctions',
    context_module: 'lots',
    destination_path: destinationPath,
  })
}

export const trackUpdateSelectedMaxBid = ({ prevMaxBid, newMaxBid }) => {
  track('click', {
    type: 'dropdown',
    label: [prevMaxBid, newMaxBid],
    flow: 'live auctions',
    context_module: 'Max Bid Select',
  })
}

const trackBidFailedAction = ({ action }) => {
  track('error', {
    action: 'bid',
    flow: 'live auctions',
    error_messages: [action.data.reason.type],
    context_module: 'bidding',
  })
}

const trackBidPlacedAction = ({ nextState, action }) => {
  const { data } = action
  const { sale } = nextState
  let causalityEvent
  let bidType
  const eventMatch = data.event.type.match(/^(.+)BidPlaced$/)
  ;[causalityEvent, bidType] = eventMatch

  track('bid', {
    sale_id: sale.internalID,
    sale_artwork_id: data.event.lotId,
    causality_event_id: data.event.eventId,
    causality_event_name: causalityEvent,
    bid_type: bidType,
    bidder_id: data.event.bidder.bidderId,
    user_id: data.event.bidder.userId,
  })
}

const trackToggleArtworkListAction = ({ nextState, prevState, action }) => {
  track('click', {
    type: 'toggle',
    context_module: 'lots',
    flow: 'live auctions',
    label: nextState.isUpcomingArtworkList ? 'upcoming' : 'previous',
  })
}
